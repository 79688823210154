import React from "react";
import TableLoader from "../../../../common/tableLoader";
import SectionRecordsFilter from "../../sections/common/section-records-filter";
import SectionPagination from "../../sections/common/section-pagination";
import GetProfileImage from "../../../../common/getProfileImage";
import { publicUser } from "../../../../../globals/route-names";
import { NavLink } from "react-router-dom";
import moment from "moment";

const EmpUsersList  = ({
    empUsersList,
    empUsersLoader,
    rowUsersPerPage,
    handleRecordsUsersPerPageChange,
    paginationUsersData,
    usersCurrentPage,
    setUsersCurrentPage,
    tableFilter,
    setTableFilter,
}) => {
  console.log(
    "paginationUsersData",
    paginationUsersData,empUsersList
  );
  const _filterConfig = {
    prefix: "Showing",
    type: "results",
    isSorting: false,
    total: paginationUsersData?.totalElements,
    showRange: false,
    showingUpto: "",
  };

  const StarRating = ({ rating }) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(
          <i
            key={i}
            className="feather feather-star"
            style={{ color: "#128c5e" }}
          />
        );
      } else {
        stars.push(
          <i
            key={i}
            className="feather feather-star"
            style={{ color: "lightgrey" }}
          />
        );
      }
    }
    return stars;
  };
  return (
    <>
      <SectionRecordsFilter
        _config={_filterConfig}
        rowPerPage={rowUsersPerPage}
        handleRecordsPerPageChange={handleRecordsUsersPerPageChange}
        setTableFilter={setTableFilter}
        tableFilter={tableFilter}
      />
      <div className="twm-candidates-list-wrap">
        <ul>
          {!empUsersLoader ? (
            empUsersList?.length > 0 ? (
                empUsersList?.map((item) => (
                <li>
                  <div className="twm-candidates-list-style1 mb-5">
                    <div className="twm-media">
                      <div className="twm-media-pic">
                        <GetProfileImage
                          id={item?.id}
                          type={"userAvatar"}
                        />
                      </div>
                      <div className="twm-candidates-tag">
                        <span>{item?.accountStatus || " -"}</span>
                      </div>
                    </div>
                    <div className="twm-mid-content">
                        <h4>{`${item?.institutionName}`}</h4>
                      <div className="row col-lg-12 col-sm-12">
                        <div className="col-lg-6 col-sm-6">
                          <p className="twm-candidate-address">
                            {item?.firstName} { item?.lastName}
                          </p>                       
                          <p className="twm-candidate-address">
                            {item?.email}
                          </p>                        
                        </div>
                        <div className="col-lg-6 col-sm-6">                       
                          <p className="twm-candidate-address">
                            <b>Role: </b>
                            {item?.roleEntities[0]?.roleType}
                          </p>  
                        </div>             
                      </div>
                      
                      {/* <div className="twm-fot-content"> */}
                      {/* <div className="twm-right-btn">
                            <span
                              // to={publicUser.candidate.DETAIL1}
                              className="twm-view-prifile site-text-primary"
                              onClick={() => navigateToApplicantDetails(item)}
                            >
                              View Profile
                            </span>
                          </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                </li>
              ))
            ) : (
              "No results found."
            )
          ) : (
            <TableLoader />
          )}
        </ul>
        <SectionPagination
          currentPage={usersCurrentPage}
          setCurrentPage={setUsersCurrentPage}
          totalPages={paginationUsersData?.totalPages}
        />
      </div>
    </>
  );
};

export default EmpUsersList;
