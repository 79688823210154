import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SectionApplicantsList from "../applicant-list";
import Toast from "../../../../../common/toast";
import apiCall from "../../../../../../apicall";
import SectionRecordsFilter from "../../../../public-user/sections/common/section-records-filter";
import TrustedWorkersTabs from "./trusted-workers-tab";
import TrustedWorkersList from "./trusted-workers-list";

const AddTrustedWorkerPopup = ({
  fetchTrustedWorkersList,
  addTrustedWorkerPopupStatus
}) => {
  const { role_type, TOKEN } = useSelector((state) => state.menu);
  const [tableFilter, setTableFilter] = useState({
    sortType: "",
    sortDirection: "desc",
    searchKey: "",
  });
  const [sessionExpired, setSessionExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [paginationData, setPaginationData] = useState({
    totalElements: 0,
    totalPages: 0,
  });
  const [hiredJobSeekerList, setHiredJobSeekerList] = useState([]);

  const handleRecordsPerPageChange = (event) => {
    setCurrentPage(1);
    setRowPerPage(event.target.value);
  };
  const _filterConfig = {
    prefix: "Showing",
    type: "results",
    isSorting: true,
    total: paginationData?.totalElements,
    showRange: false,
    showingUpto: "",
    showEmployeeNameFilter: true,
  };
  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setHiredJobSeekerList([]);
    setPaginationData({
      totalElements: 0,
      totalPages: 0,
    });
  };

  const handleSearchClick = (e) => {
    if (e.key === "Enter") {
      setTableFilter({
        ...tableFilter,
        searchKey: searchKey,
      });
      setCurrentPage(1);
    }
  };

  const clearSearch = () => {
    setSearchKey("");
    setCurrentPage(1);
    setTableFilter({
      ...tableFilter,
      searchKey: "",
    });
  };

  const handleSearchKeyChange = (e) => {
    if (e?.target?.value.trim() === "") {
      clearSearch();
    } else {
      setSearchKey(e.target.value);
    }
  };


  return (
    <div
      className="modal fade twm-saved-jobs-view"
      id="add_trusted_worker_popup"
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered" style={{ "maxWidth" : "800px" }}>
        <div className="modal-content">
          <div className="modal-header" style={{ "marginTop": "0px" }} >
            <h2 className="modal-title" id="sign_up_popupLabel2">
              Add Trusted Worker
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body" style={{ "padding" : "0px 20px" , "maxHeight": "500px", "overflowY": "scroll" }}>
            <TrustedWorkersTabs fetchTrustedWorkersList={fetchTrustedWorkersList} />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="site-button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </div>
  );
};

export default AddTrustedWorkerPopup;
