import SectionJobsSidebar1 from "../../../public-user/sections/jobs/sidebar/section-jobs-sidebar1";
import apiCall from "../../../../../apicall";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../globals/fomat-comapny-name";
import EmployeesPage from "./employees/employees-page";
import TrustedWorkersPage from "./trusted-workers/trusted-workers-page";

function ManageTeam() {

    return (
        <>
            <div>
                <Helmet>
                    <title>{`Manage Team | ${formatCompanyName()}`}</title>
                    <meta name="description" content="Welcome to the Manage Team Page! Here, The Manage Team page allows administrators to register new employees and add them in the existing list." />
                    <meta name="keywords" content="team management, team structure, team overview, manage team" />
                </Helmet>
            </div>
            <div className="section-full p-t120  p-b90 site-bg-white mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 rightSidebar">
                            {/* <SectionJobsSidebar1
                                setTableFilter={setTableFilter}
                                tableFilter={tableFilter}
                                setCurrentPage={setCurrentPage}
                            /> */}
                        </div>
                        <div className="col-lg-8 col-md-12">
                            <div class="panel-heading wt-panel-heading p-a20 mb-4">
                                <h3 class="wt-title">Manage Your Team</h3>
                            </div>
                            <div className="twm-sign-up-screen">
                                <div className="sign-up-container">
                                    <form>
                                        <div className="body">
                                            <div className="twm-tabs-style-2">
                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className="nav-link active"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#sign-employees"
                                                            type="button"
                                                        >
                                                            <i className="fas fa-user-tie" /> Employees
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className="nav-link"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#sign-trusted-workers"
                                                            type="button"
                                                        >
                                                            <i className="fa fa-shield-alt" /> Trusted Workers
                                                        </button>
                                                    </li>
                                                </ul>
                                                <div className="tab-content" id="myTabContent">
                                                    <div className="tab-pane fade show active" id="sign-employees">
                                                        <EmployeesPage />
                                                        {/* <div className="section-full site-bg-white">
                                                            <div className="container">
                                                                <div className="row">
                                                                    <div className="col-lg-12 col-md-12">
                                                                        <div class="panel-heading wt-panel-heading p-a20 mb-4 d-flex justify-content-between align-items-center">
                                                                            <h3 class="wt-title">Employees</h3>
                                                                            <a
                                                                                className="site-button"
                                                                                data-bs-toggle="modal"
                                                                                href={"#add_applicant_popup"}
                                                                                role="button"
                                                                            >
                                                                                Add
                                                                            </a>
                                                                        </div>
                                                                        <SectionRecordsFilter
                                                                            _config={_filterConfig}
                                                                            rowPerPage={rowPerPage}
                                                                            handleRecordsPerPageChange={handleRecordsPerPageChange}
                                                                            setTableFilter={setTableFilter}
                                                                            tableFilter={tableFilter}
                                                                            setCurrentPage={setCurrentPage}
                                                                        />
                                                                        <SectionApplicantsList
                                                                            isLoading={isLoading}
                                                                            applicantsListData={applicantsListData}
                                                                            currentPage={currentPage}
                                                                            setCurrentPage={setCurrentPage}
                                                                            totalPages={paginationData.totalPages}
                                                                        // fetchapplicantsList={fetchapplicantsList}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div className="tab-pane fade" id="sign-trusted-workers">
                                                        {/* <JobPosterRegister role_type="ROLE_INSTITUTION_ADMIN"/> */}
                                                        <TrustedWorkersPage />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default ManageTeam;
