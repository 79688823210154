import { useNavigate, useLocation } from "react-router-dom";
import SectionPagination from "../common/section-pagination";
import TableLoader from "../../../../common/tableLoader";
import GetProfileImage from "../../../../common/getProfileImage";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ConfirmationDeleteJobPopUp from "./confirmation-delete-job";
import { useState } from "react";
import JobReviewPopUp from "../../../../common/popups/popup-job-review";
import Toast from "../../../../common/toast";
import { jobDetailsData } from "../../../../../store/reducers/menu";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../globals/fomat-comapny-name";

function SectionJobsGrid({
  isLoading,
  jobListData,
  currentPage,
  setCurrentPage,
  fetchjobList,
  seekerTotalAmount,
  jobDetailId,
  totalPages,
}) {
  const { role_type } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const location = useLocation();
  const [jobId, setJobId] = useState();
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const navigate = useNavigate();
  function getTimeDifference(postTime) {
    const currentTime = new Date();
    const postDate = new Date(postTime);
    const timeDifference = currentTime.getTime() - postDate.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
      return `${seconds} sec ago`;
    } else if (minutes < 60) {
      return `${minutes} min ago`;
    } else if (hours < 24) {
      return `${hours} hour ago`;
    } else if (days < 30) {
      return `${days} day ago`;
    } else if (months < 12) {
      return `${months} month ago`;
    } else {
      return `${years} year ago`;
    }
  }
  const ConvertInto_Hrs = (hrs) => {
    var num = hrs
    var str = num.toString();
    var numarray = str.split('.');
    var a = new Array();
    a = numarray;
    let minuteVal = Number(a[1]) / 60
    let strFnl = a[0] + (minuteVal.toString()).substring(1)
    return Number(strFnl).toFixed(2);
  }

  function formatWithCommas(x) {
    const parts = x.toString().split(".");
    let integerPart = parts[0];
    const decimalPart = parts.length > 1 ? "." + parts[1] : "";
  
    if (integerPart.length > 3) {
      const lastThree = integerPart.slice(-3);
      const otherNumbers = integerPart.slice(0, -3);
      integerPart =
        otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree;
    }
  
    return integerPart + decimalPart;
  }


  const displayJobTime = (startTime, endTime, isMultiDayJob, zone) => {
    const formattedStartTime = moment(startTime, "YYYY-MM-DD HH:mm:ss").local().format(
      "MM/DD/YYYY"
    );
    const formattedEndTime = moment(endTime, "YYYY-MM-DD HH:mm:ss").local().format(
      "MM/DD/YYYY"
    );

    const displayTime = isMultiDayJob
      ? `${formattedStartTime} - ${formattedEndTime}`
      : `${formattedStartTime} ${moment
        .utc(startTime, "YYYY-MM-DD HH:mm:ss")
        .local()
        .format("HH:MM A")} - ${moment
          .utc(endTime, "YYYY-MM-DD HH:mm:ss")
          .local()
          .format("HH:MM A")}`;

    return <div>{displayTime}  <b>{zone}</b></div>;
  };
  const getJobStatus = (status) => {
    if (typeof status !== "string") {
      return ""; // Return an empty string or handle it as needed
    }
    return status.startsWith("e") ? status.substring(1) : status;
  };

  // const StarRating = ({ rating }) => {
  //   const stars = [];
  //   for (let i = 0; i < 5; i++) {
  //     if (i < rating) {
  //       stars.push(
  //         <i
  //           key={i}
  //           className="feather feather-star"
  //           style={{ color: "#128c5e" }}
  //         />
  //       );
  //     } else {
  //       stars.push(
  //         <i
  //           key={i}
  //           className="feather feather-star"
  //           style={{ color: "lightgrey" }}
  //         />
  //       );
  //     }
  //   }
  //   return stars;
  // };
  const StarRating = ({ rating }) => {
    const stars = [];

    // Iterate over 5 stars
    for (let i = 0; i < 5; i++) {
      if (rating >= i + 1) {
        // Full star if rating is greater than or equal to current index + 1
        stars.push(
          <i
            key={i}
            className="fas fa-star"
            style={{ color: '#128c5e' }}
          />
        );
      } else if (rating >= i + 0.5) {
        // Half star if rating is greater than or equal to current index + 0.5
        stars.push(
          <i
            key={i + 0.5}
            className="fas fa-star-half-alt"
            style={{ color: '#128c5e' }}
          />
        );
      } else {
        // Empty star otherwise
        stars.push(
          <i
            key={i}
            className="fas fa-star"
            style={{ color: 'lightgrey' }}
          />
        );
      }
    }

    return <span>{stars}</span>;
  };
  const openDeletionConfirmation = (id) => {
    console.log("openDeletionConfirmation---", id);
    setJobId(id);
  };
  const handleSelectedJobid = (id) => {
    setJobId(id);
  };

  const navigateToJobDetails = (item) => {
    console.log("item-----")
    // dispatch(jobDetailsData(item))
    navigate(`/job-detail/${item.id}`, { state: item });
  };
  const formatAddress = (address, city, state, zipCode) => {
    const parts = [address, city, state, zipCode].filter(Boolean);
    return parts.join(', ');
  };

  const getWorkedHours = (item) => {
    if (
      (((item?.jobStatus === "eCancelled" && item?.totalWorkedHours) ||
        item?.jobStatus === "eInProgress") &&
        role_type.includes("ROLE_USER")) ||
      (item?.jobStatus === "eInProgress" &&
        role_type.includes("ROLE_INSTITUTION_ADMIN"))
    ) {
    return `${item.hasOwnProperty("totalWorkedHours") ? (item.totalWorkedHours) : 0
    } of ${item?.totalHours ? (item?.multipleDayJob ? item?.totalHours : ConvertInto_Hrs(item?.totalHours)) : 0} hours`;
}
  };

  const getJobValue = (status, item) => {
    // console.log("statusssss", status, role_type);
    if (status === "eApplied" && role_type.includes("ROLE_USER")) {
      return <h5 style={{ color: "orange" }}>PENDING</h5>; // Return an empty string or handle it as needed
    } else if (status === "eAccepted" && role_type.includes("ROLE_USER")) {
      return <h5 style={{ color: "green" }}>HIRED</h5>;
    } else if (
      ((status === "eCancelled" || status === "eInProgress") &&
        role_type.includes("ROLE_USER")) ||
      ((status === "eInProgress" || status === "eAccepted" || status === "eCancelled") && role_type.includes("ROLE_INSTITUTION_ADMIN"))
    ) {
      return (
        <h5
          style={{
            fontWeight: "600",
            color:
              item?.clockInStatus == "TIME PASSED"
                ? "black"
                : item?.clockInStatus === "Upcoming"
                  ? "orange"
                  : item?.clockInStatus === "Clocked Out"
                    ? "red"
                    : item?.clockInStatus === "Clocked In"
                      ? "green"
                      : "black",
          }}
        >
          {item?.clockInStatus?.toUpperCase()}
        </h5>
      );
    } else {
      return <h5 style={{ fontWeight: "600" }}>{status.startsWith("e") ? status.substring(1) : status}</h5>;
    }
  };

  {console.log("jobListData[0]?.jobStatus",jobListData[0]?.jobStatus)}

  return (
    <>
    <div>
        <Helmet>
          <title>{`Job Grid | Job Listings | List of Jobs | Find Your Next Opportunity | ${formatCompanyName()}`}</title>
          <meta name="description" content="Explore diverse job openings to find the perfect opportunity that matches your skills and advances your career!" />
          <meta name="keywords" content="job search, job vacancies, job board, apply now, career opportunities, job openings" />
        </Helmet>
      </div>
      <div className="row">
        {/*Block one*/}
        {jobListData[0]?.jobStatus == "eCompleted" && (location?.pathname?.includes("/history-job") || location?.pathname?.includes("/history-job/"))  && (
            <div className="twm-media text-center mb-2">
              <h4>
                <span>
                  Total Amount : $
                  {seekerTotalAmount > 0
                    ? (seekerTotalAmount / 100)?.toFixed(2)
                    : "0"}
                </span>
              </h4>
            </div>
          )}
        {!isLoading ? (
          jobListData?.length > 0 ? (
            jobListData?.map((item) => (
              <div
                className="col-lg-6 col-md-12 m-b30"
                key={item.id}
                style={{ cursor: "pointer" }}
              // onClick={() => navigateToJobDetails(item)}
              >
                <div className="twm-jobs-grid-style1">
                  <div className="twm-media">
                    {/* <JobZImage src="images/jobs-company/pic1.jpg" alt="#" /> */}
                    {/* <GetProfileImage
                      id={item?.institutionEntityId}
                      type={"jobList"}
                    /> */}
                    {
                      (item?.jobStatus === "eCompleted" || item?.jobStatus === "eClosed" || item?.jobStatus === "eInProgress" || item?.jobStatus === "eAccepted" || (item?.hireApplicantId && item?.clockInStatus === "TIME PASSED")) && role_type.includes('ROLE_INSTITUTION_ADMIN') ?
                        <GetProfileImage
                          id={item?.hireApplicantId}
                          type={"applicant"}
                          logoType={"avatar"}
                          jobId={item?.id}
                        />
                        :
                        <GetProfileImage
                          id={item?.institutionEntityId}
                          type={"jobList"}
                        />
                    }
                  </div>
                  <span className="twm-job-post-duration">
                    {getTimeDifference(item?.createdDate)}
                  </span>
                  <div className="twm-job-address mt-4">
                    {getJobValue(item?.jobStatus, item)}
                  </div>
                  {!role_type.includes("ROLE_OPENSTAFF_ADMIN") &&
                    !role_type.includes("ROLE_INSTITUTION_ADMIN") ? (
                    <div className="twm-jobs-category green">
                      <span className="twm-bg-green">
                        {item?.distance?.toFixed(2)} Miles
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="twm-mid-content"
                    style={{ cursor: "pointer", paddingTop: "30px" }}
                    onClick={() => navigateToJobDetails(item)}
                  >
                    <span className="twm-job-title">
                      <h4>{item?.jobTitle}</h4>
                    </span>
                    <p className="twm-job-address">{item?.jobPosition}</p>
                    {(!role_type.includes("ROLE_USER") && item?.jobStatlus === "eOpen") && (<p className="twm-job-address">
                          <b>{item?.numberOfApplicants ? item?.numberOfApplicants : "0"} applicants</b>
                        </p>
                    )}
                    {/* <span className="twm-job-address">
                      {getJobValue(item?.jobStatus, item)}
                    </span> */}
                    <span className="twm-job-address">
                      <b>{getWorkedHours(item)}</b>
                    </span>
                    {/* <p className="twm-job-address">
                      <b>{getJobStatus(item?.jobStatus)}</b>
                    </p> */}
                    {/* <a
                      href="https://themeforest.net/user/thewebmax/portfolio"
                      className="twm-job-websites site-text-primary"
                    >
                      https://thewebmax.com
                    </a> */}
                    <div className="twm-job-address">
                      {item?.institutionName}
                    </div>
                    {(item?.jobStatus === "eCompleted" || item?.jobStatus === "eClosed" || item.jobStatus === "eInProgress" || item.jobStatus === "eAccepted") && role_type.includes("ROLE_INSTITUTION_ADMIN") && (
                      <span className="twm-job-address">
                        {item?.hireFirstName + " " + item?.hireLastName}
                      </span>
                    )}
                    <div>
                      {/* {!(role_type === "ROLE_INSTITUTION_ADMIN" && item?.jobStatus === "eOpen") && (<> <StarRating rating={role_type === "ROLE_USER" ? item?.instituteRating : (item?.jobStatus === "eCompleted" ? item?.ratings : item?.applicantAverageNumStars)} />
                      <p style={{ fontSize: 12 }}>
                        {role_type === "ROLE_USER"
                          ? item?.instituteReview
                          : item?.applicantNumberOfReview}{" "}
                        Reviews
                      </p> </> )} */}
                      {role_type.includes( "ROLE_USER") ? (
                        <>
                          <StarRating
                            rating={
                              item?.jobStatus === "eInProgress"
                                ? (item?.instituteRating ? item?.instituteRating : item?.instituteAvgRating)
                                : item?.instituteAvgRating
                              // item?.instituteAvgRating
                              // item?.jobStatus === "eOpen"
                              //   ? item?.instituteRating
                              //   : item?.instituteAvgRating
                            }
                          />
                          {/* <StarRating rating={item?.jobStatus === "eAccepted" || item?.jobStatus === "eApplied" || item?.jobStatus === "eInProgress" || item?.jobStatus === "eCompleted" ? item?.instituteAvgRating : null} /> */}
                          <span style={{ fontSize: 12 }}>
                            {item?.jobStatus === "eOpen"
                              ? " " + (item?.instituteReview ?? 0) + " "
                              : " " +
                              (item?.instituteNumberOfReview ?? 0) +
                              " "}
                            Reviews
                          </span>
                        </>
                      ) : role_type.includes("ROLE_INSTITUTION_ADMIN") ? (
                        <>
                          {(item?.jobStatus === "eCompleted" || item?.jobStatus === "eClosed") ? (
                            <StarRating rating={item?.applicantAverageNumStars} />
                          ) : (item?.jobStatus === "eAccepted" || item?.jobStatus === "eInProgress") ? (
                            <StarRating
                              rating={item?.applicantAverageNumStars}
                            />
                          ) : null}
                          {item?.jobStatus != "eOpen" ? (
                            <span style={{ fontSize: 12 }}>
                              {" " + (item?.applicantNumberOfReview ?? 0) + " "}{" "}
                              Reviews
                            </span>
                          ) : null}
                        </>
                      ) : role_type.includes("ROLE_OPENSTAFF_ADMIN") ? (
                        <>
                          <StarRating rating={item?.instituteAvgRating} />
                          <span style={{ fontSize: 12 }}>
                            {" " + (item?.instituteReview ?? 0) + " "}
                            Reviews
                          </span>
                        </>
                      ) : (
                        <>
                          <StarRating rating={item?.instituteAvgRating} />
                          <span style={{ fontSize: 12 }}>
                            {" " + (item?.instituteReview ?? 0) + " "}
                            Reviews
                          </span>
                        </>
                      )}
                    </div>
                    {role_type.includes("ROLE_INSTITUTION_ADMIN") && (
                      <p className="twm-job-address">{item?.locationName}</p>
                    )}
                    {!role_type.includes("ROLE_INSTITUTION_ADMIN") && (
                      <p className="twm-job-address">{formatAddress(item?.address, item?.city, item?.state, item?.zipCode)}</p>
                    )}
                    <span className="twm-job-address">
                      {displayJobTime(
                        item?.startTime,
                        item?.endTime,
                        item?.multipleDayJob,
                        item?.timeZoneId
                      )}
                    </span>
                    {/* {role_type) === "ROLE_INSTITUTION_ADMIN" &&
                    item?.numberOfApplicants >= 1 ? (
                      <span className="twm-job-address">
                        {item?.numberOfApplicants} Applicants waiting
                      </span>
                    ) : role_type) === "ROLE_INSTITUTION_ADMIN" ? (
                      <span className="twm-job-address">
                        Waiting for Applicants
                      </span>
                    ) : (
                      ""
                    )} */}
                    {role_type.includes("ROLE_INSTITUTION_ADMIN") && (item?.jobStatus === "eCompleted" || item?.jobStatus === "eClosed") && (
                      <div className="twm-jobs-amount">
                        <b>{item?.totalWorkedHours ? ConvertInto_Hrs(item?.totalWorkedHours) : "0"} of {item?.totalHours ? (item?.multipleDayJob ? item?.totalHours : ConvertInto_Hrs(item?.totalHours)) : "0"} Hours</b>
                        {/* <b>{item?.totalWorkedHours ? ConvertInto_Hrs(item?.totalWorkedHours) : "0"} of {item?.totalHours ? ConvertInto_Hrs(item?.totalHours) : "0"} Hours</b> */}
                      </div>
                    )}
                  </div>
                  
                  {(item.jobStatus === "eCompleted" || item?.jobStatus === "eClosed") && (
                          <div className="mt-4">
                            {role_type.includes("ROLE_USER") ? (
                              <>
                                {item?.instituteRating === 0 ? (
                                  ""
                                ) : (
                                  <StarRating rating={item?.instituteRating} />
                                )}

                                <p style={{ fontSize: 12, marginBottom: 0 }}>
                                  {item?.instituteReview || ""}
                                </p>
                              </>
                            ) : role_type.includes("ROLE_INSTITUTION_ADMIN") ? (
                              <>
                                {/* <h5 className="mb-0">
                                  {item?.hireFirstName} {item?.hireLastName}
                                </h5> */}
                                <StarRating rating={item?.ratings} />
                                <p style={{ fontSize: 12, marginBottom: 0 }}>
                                  {item?.review || ""}
                                </p>
                              </>
                            ) : (
                              <>
                                <StarRating rating={item?.instituteRating} />
                                <p style={{ fontSize: 12, marginBottom: 0 }}>
                                  {item?.instituteNumberOfReview || ""}
                                </p>
                              </>
                            )}
                          </div>
                        )}

                  <div className="twm-right-content">
                    {/* <div className="twm-jobs-amount">
                      ${(item?.salary / 100)?.toFixed(2)} <span>/ hr</span>
                    </div> */}
                    {
                      !(item?.isPrivateJob && item?.isEmployeeOnly && !item?.isTrustedWorkerOnly) &&
                     ( <div className="twm-jobs-amount">
                      {(role_type.includes("ROLE_INSTITUTION_ADMIN") ||
                        role_type.includes("ROLE_OPENSTAFF_ADMIN")) &&
                        (item?.jobStatus === "eCompleted" || item?.jobStatus === "eClosed")  ? (
                        <>
                          ${formatWithCommas((item?.totalCharges / 100)?.toFixed(2))} (Authorized) / 
                          ${formatWithCommas((item?.actualTotalCharges / 100)?.toFixed(2))} (Charged)
                              {/* <span>/ hr</span> */}
                          {/* ${(item?.totalCharges / 100)?.toFixed(2)} */}
                          {/* <span>/ hr</span> */}
                        </>
                      ) : (role_type.includes("ROLE_USER") ||
                        role_type.includes("ROLE_OPENSTAFF_ADMIN")) &&
                        (item?.jobStatus === "eCompleted" ||  item?.jobStatus === "eClosed")  ? (
                        <>
                          ${(item?.totalAmount / 100)?.toFixed(2)}
                          {/* <span>/ hr</span> */}
                        </>
                      ) 
                      // : item?.paymentMethod == "other" ? (
                      //   <>
                      //     {(role_type === "ROLE_INSTITUTION_ADMIN" || role_type === "ROLE_USER") && (
                      //       <>
                      //         ${(item?.totalCharges ? item?.totalCharges / 100 : item?.salary / 100)?.toFixed(2)}
                      //       </>
                      //     )}
                      //   </>
                      // ) 
                      : (
                          <>
                            ${(item?.salary / 100)?.toFixed(2)}
                            <span>/ hr</span>
                          </>
                      )}
                    </div>)
                    }
                   
                    <span
                      className="twm-jobs-browse site-text-primary job-details"
                      onClick={() => navigateToJobDetails(item)}
                    >
                      View Details
                    </span>
                    {item?.jobStatus == "eCompleted" &&
                      role_type.includes("ROLE_USER") &&
                      !item?.instituteReview && (
                        <>
                          <span
                            className="twm-jobs-browse site-text-primary job-details"
                          //onClick={() => navigateToJobDetails(item)}
                          >
                            <a
                              className="nav-link"
                              data-bs-toggle="modal"
                              href="#job-review-popup"
                              role="button"
                              onClick={() => handleSelectedJobid(item?.id)}
                            >
                              <i className="fas fa-edit" />
                            </a>
                          </span>
                          <JobReviewPopUp
                            jobDetailsInfo={jobId}
                            jobDetailId={jobDetailId ? jobDetailId : ""}
                            jobRating={item?.instituteRating}
                            currentPage={"jobList"}
                            fetchjobList={fetchjobList}
                            setCurrentPage={setCurrentPage}
                          // jobReview={
                          //   role_type == "ROLE_USER"
                          //     ? item?.instituteNumberOfReview
                          //     : ""
                          // }
                          />
                        </>
                      )}
                    {item?.jobStatus == "eCompleted" &&
                      role_type.includes("ROLE_INSTITUTION_ADMIN") &&
                      !item?.review && (
                        <>
                          <span
                            className="twm-jobs-browse site-text-primary job-details"
                          //onClick={() => navigateToJobDetails(item)}
                          >
                            <a
                              className="nav-link"
                              data-bs-toggle="modal"
                              href="#job-review-popup"
                              role="button"
                              onClick={() => handleSelectedJobid(item?.id)}
                            >
                              <i className="fas fa-edit" />
                            </a>
                          </span>
                          <JobReviewPopUp
                            jobDetailsInfo={jobId}
                            jobDetailId={jobDetailId ? jobDetailId : ""}
                            jobRating={item?.ratings}
                            currentPage={"jobList"}
                            fetchjobList={fetchjobList}
                            setCurrentPage={setCurrentPage}

                          // jobReview={
                          //   role_type == "ROLE_USER"
                          //     ? item?.applicantNumberOfReview
                          //     : ""
                          // }
                          />
                        </>
                      )}
                    {role_type.includes("ROLE_INSTITUTION_ADMIN") &&
                      item?.jobStatus == "eOpen" && (
                        <div>
                          {/* <span
                              className="twm-jobs-browse site-text-primary job-details"
                              onClick={() => handleDeleteJob(item.id)}
                            >
                              <i class="fas fa-trash-alt"></i>
                            </span> */}
                          <a
                            className="twm-jobs-browse site-text-primary job-details"
                            data-bs-toggle="modal"
                            href="#delete_job_confirmation_popup"
                            role="button"
                            onClick={() => openDeletionConfirmation(item?.id)}
                          >
                            <i className="fas fa-trash-alt" style={{"color": "red"}}></i>
                          </a>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            "No results found."
          )
        ) : (
          <TableLoader />
        )}
        {/* <div className="col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic2.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">15 days ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-brown">Intership</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4>Senior Rolling Stock Technician</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$7 <span>/ Hour</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic3.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">6 Month ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-purple">Fulltime</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4 className="twm-job-title">IT Department Manager</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$2500 <span>/ Month</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic4.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">2 days ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-sky">Freelancer</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4 className="twm-job-title">Art Production Specialist</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$1800 <span>/ Month</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                <div className="masonry-item col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic5.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">1 days ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-golden">Temporary</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4 className="twm-job-title">Recreation &amp; Fitness Worker</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$1000 <span>/ Month</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                <div className="masonry-item col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic1.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">1 days ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-green">New</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4>Senior Web Designer , Developer</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$19 <span>/ Hour</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic1.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">1 days ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-green">New</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4>Senior Web Designer , Developer</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$2500 <span>/ Month</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic2.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">15 days ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-brown">Intership</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4>Senior Rolling Stock Technician</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$7 <span>/ Hour</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic3.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">6 Month ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-purple">Fulltime</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4 className="twm-job-title">IT Department Manager</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$2500 <span>/ Month</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b30">
                    <div className="twm-jobs-grid-style1">
                        <div className="twm-media">
                            <JobZImage src="images/jobs-company/pic4.jpg" alt="#" />
                        </div>
                        <span className="twm-job-post-duration">2 days ago</span>
                        <div className="twm-jobs-category green"><span className="twm-bg-sky">Freelancer</span></div>
                        <div className="twm-mid-content">
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                <h4 className="twm-job-title">Art Production Specialist</h4>
                            </NavLink>
                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                        </div>
                        <div className="twm-right-content">
                            <div className="twm-jobs-amount">$1800 <span>/ Month</span></div>
                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                        </div>
                    </div>
                </div> */}
      </div>
      <SectionPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
      <ConfirmationDeleteJobPopUp jobId={jobId} fetchjobList={fetchjobList} setErrSuccessMsg={setErrSuccessMsg} />
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default SectionJobsGrid;
