import React, { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Toast from "../../../../../common/toast";
import Spinner from "../../../../../common/spinner";
import apiCall from "../../../../../../apicall";
import GooglePlacesAutocomplete from "react-google-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { EmailVerification, deleteFirebaseUser, insertFirebaseUser } from "../../../../../common/register/firebase-email-verification";
import { navigateVisitedPage, roleType } from "../../../../../../store/reducers/menu";
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { GoogleMapsProvider } from "@ubilabs/google-maps-react-hooks";

const PrivateApplicantRegister = ({ role_type, fetchapplicantsList }) => {
  const { CONFIGKEY, TOKEN, userInfo } = useSelector((state) => state.menu)

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPositionList, setIsLoadingPositionList] = useState(false);
  const [positionDropdownList, setPositionDropdownList] = useState([]);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [selectedFields, setSelectedFields] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedProfessionList, setSelectedProfessionList] = useState([]);
  const [selectedProfession, setSelectedProfession] = useState("");
  const dropdownRef = useRef(null);
  const modalRef = useRef(null);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    // phoneNumber: "",
    email: "",
    address: "",
  });
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const [formErrors, setFormErrors] = useState({});

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, ""); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNumber; // Return original value if not 10 digits
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  const handleInputChange = (e, fieldName, position) => {
    let name, value;

    if (typeof e === "string") {
      // If e is a string, it means this is a ReactQuill change event
      name = fieldName;
      value = e;
    } else {
      // Otherwise, it's a regular input change event
      ({ name, value } = e.target);
    }

    console.log("name, value ", name, value);
    let formattedValue = value;

    if (name === "firstName" || name === "lastName") {
      formattedValue = capitalizeFirstLetter(value);
    } else if (name === "phoneNumber") {
      formattedValue = formatPhoneNumber(value);
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: formattedValue,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handlePlaceSelect = (place) => {
    // Update form values with selected place details
    if (place?.geometry) {
      const addressComponents = place.address_components;
      const formattedAddress = place.formatted_address;
      const locationName =
        addressComponents.find(
          (component) =>
            component.types.includes("point_of_interest") ||
            component.types.includes("sublocality") ||
            component.types.includes("neighborhood")
        )?.long_name || formattedAddress.split(",")[0];
      setFormValues((prevValues) => ({
        ...prevValues,
        address: formattedAddress,
        locationName,
        city:
          addressComponents.find((component) =>
            component.types.includes("locality")
          )?.long_name || "",
        state:
          addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.short_name || "",
        country:
          addressComponents.find((component) =>
            component.types.includes("country")
          )?.long_name || "",
        zipCode:
          addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.short_name || "",
      }));
    }
  };
  console.log("Selected Types:", selectedType);

  const validateForm = () => {
    const errors = {};

    // Validate fields common to both roles
    if (!formValues.firstName || formValues.firstName.trim() === "") {
      errors.firstName = "First Name is required.";
    }

    if (!formValues.lastName || formValues.lastName.trim() === "") {
      errors.lastName = "Last Name is required.";
    }

    if (!formValues.email || formValues.email.trim() === "") {
      errors.email = "Email is required.";
    }

    if (!formValues.address || formValues.address.trim() === "") {
      errors.address = "Address is required.";
    }

    // Add validation for profession
    if (selectedType.length === 0) {
      errors.profession = "At least one profession must be selected.";
    }

    // Log errors for debugging
    console.log("Validation Errors:", errors);

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors, false if there are errors
  };


  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
  };

  const displaySuccessMsg = () => {
    setIsLoading(false);
    setErrSuccessMsg({
      // message: "User registered successfully!",
      message: formValues.firstName + " " + formValues.lastName + " has been added.",
      status: true,
    });
    // alert("afterConfirm")
    setSelectedType("");
    setSelectedFields([]);
    setFormValues({
      firstName: "",
      lastName: "",
      email: "",
      address: "",
    });
  };

  const handlePrivateApplicantSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const firebaseUID = await EmailVerification(formValues.email, userInfo);

    if (validateForm()) {
      console.log("firebaseUID:poster::::::::::::::", firebaseUID);

      if (firebaseUID?.uid) {
        const finalProfessionData = selectedType.map((profession) => ({ profession }));

        // Exclude keys for professions like "Carpentar": {} before sending the payload
        const cleanedFormValues = { ...formValues };
        selectedType.forEach((profession) => {
          if (Object.keys(cleanedFormValues[profession] || {}).length === 0) {
            delete cleanedFormValues[profession];
          }
        });

        const formattedData = {
          ...cleanedFormValues,
          address: cleanedFormValues?.address?.split(",")[0],
          professions: finalProfessionData,
        };

        try {
          const registerResponse = await apiCall("institutions/newEmployee", "POST", formattedData, TOKEN);
          console.log("registerResponse", registerResponse);

          if (registerResponse?.status === 200) {
            displaySuccessMsg();

            // Save job seeker data in Firebase
            formattedData["role_type"] = "ROLE_USER";
            await insertFirebaseUser(formattedData, firebaseUID?.uid);

            const ConfirmationBackDropSeeker = document.getElementById("modalBackdropSeekerRegister");
            const ConfirmationPopupModalSeeker = document.getElementById("confirmationRegistrationSeekerPopup");

            if (ConfirmationPopupModalSeeker) {
              ConfirmationBackDropSeeker.style.zIndex = "1";
              ConfirmationBackDropSeeker.classList.add("show");
              ConfirmationPopupModalSeeker.classList.add("show");
              ConfirmationPopupModalSeeker.style.display = "block";
              ConfirmationBackDropSeeker.style.display = "block";
            }
            setTimeout(() => {
              if (modalRef.current) {
                modalRef.current.click();
              }
            }, 1000);
            fetchapplicantsList();
          } else if (registerResponse?.response?.status === 400) {
            displayErrMsg(registerResponse?.response?.data?.errorMessage);
            setIsLoading(false);
            await deleteFirebaseUser(firebaseUID?.uid);
          } else {
            displayErrMsg(registerResponse?.response?.data?.message);
            await deleteFirebaseUser(firebaseUID?.uid);
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
          displayErrMsg("Something went wrong!");
          await deleteFirebaseUser(firebaseUID?.uid);
        }
      } else {
        setIsLoading(false);
        displayErrMsg(firebaseUID?.message);
      }
    } else {
      setIsLoading(false);
      displayErrMsg("Check the form validation!");
    }
  };


  const getPositionDropdownList = async () => {
    try {
      setIsLoadingPositionList(true);
      const positionResponse = await apiCall("config/allJobTypes", "GET", null);
      console.log("JobSeekerRegister", positionResponse)
      if (
        positionResponse?.status === 200 &&
        positionResponse?.data?.length > 0
      ) {
        setIsLoadingPositionList(false);
        setPositionDropdownList(positionResponse?.data);
      } else if (
        positionResponse?.status === 200 &&
        positionResponse?.data?.length === 0
      ) {
        setIsLoadingPositionList(false);
      }
    } catch (err) {
      setIsLoadingPositionList(false);
      displayErrMsg("Somthing went wrong!");
    }
  };

  useEffect(() => {
    getPositionDropdownList();
    dispatch(navigateVisitedPage(null));
  }, []);
  const dropdownIconBoxStyle = {
    position: "relative",
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getCheckboxAttributesDisabled = (position) => {
    const isChecked = selectedType.includes(position.typeName);
    const isOnlySelected = selectedType.length === 1 && isChecked;

    return isOnlySelected ? { disabled: true } : "";
  };
  const getCheckboxAttributes = (checked) => {
    // setSelectedProfession(profession);
    console.log("profession-----------", checked, selectedType);
    return !checked
      ? {
        "data-bs-toggle": "modal",
        href: "#remove_profession_confirmation",
      }
      : {};
  };
  const handlePositionChange = (e, profession) => {
    const { value, checked } = e.target;
    // setSelectedProfession(profession);
    setSelectedType((prevSelectedTypes) => {
      const updatedSelectedTypes = checked
        ? [...prevSelectedTypes, value]
        : prevSelectedTypes.filter((type) => type !== value);
      // const selectedPositions = updatedSelectedTypes
      //   .map((type) =>
      //     positionDropdownList.find((pos) => pos.typeName === type)
      //   )
      //   .filter(Boolean); // Ensure only valid positions are processed

      // const newFields = {};
      // selectedPositions.forEach((pos) => {
      //   newFields[pos.typeName] = JSON.parse(pos.relatedFields).fields;
      // });
      // setSelectedFields(newFields);

      const newFormValues = { ...formValues };
      updatedSelectedTypes.forEach((typeName) => {
        if (!newFormValues[typeName]) {
          newFormValues[typeName] = {};
          // newFields[typeName]?.forEach((field) => {
          //   newFormValues[typeName][field.mapField] = "";
          // });
        }
      });
      setFormValues(newFormValues);
      if (updatedSelectedTypes.length > 0) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          profession: "", // Clear the profession error
        }));
      }

      console.log("updatedSelectedTypes---", updatedSelectedTypes);

      return updatedSelectedTypes;
    });
  };

  const handleError = (error) => {
    console.error("Google Places API error:", error);
    setError("Failed to load autocomplete suggestions. Please try again.");
  };
  useEffect(() => {
    const loadScript = () => {
      if (document.querySelector('script[src="https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API}&libraries=places"]')) {
        setIsScriptLoaded(true);
        return;
      }

      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API}&libraries=places`;
      script.onload = () => setIsScriptLoaded(true);
      document.body.appendChild(script);
    };

    loadScript();
  }, []);
  if (!isScriptLoaded) {
    return <div>Loading...</div>;
  }
  const handleClearValues = () => {
    setFormErrors({
      firstName: "",
      lastName: "",
      email: "",
      address: "",
    })
    setFormValues({
      firstName: "",
      lastName: "",
      email: "",
      address: "",
    })
  }

  return (
    <>
      <div
        className="modal fade twm-sign-up"
        id="add_applicant_popup"
        aria-hidden="true"
        aria-labelledby="sign_up_popupLabel2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="sign_up_popupLabel2">
                Add Employee
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={modalRef}
                onClick={handleClearValues}
              />
            </div>
            <div className="modal-body">
              <form id="jobseeker_form">
                <div className="panel panel-default">
                  <div className="panel-body wt-panel-body p-a20 m-b30 ">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="form-group">
                          <label>First Name</label>
                          <div className="ls-inputicon-box">
                            <input
                              className="form-control"
                              name="firstName"
                              maxLength={20}
                              type="text"
                              placeholder="First Name"
                              value={formValues.firstName}
                              onChange={(e) => handleInputChange(e, "firstName")}
                            />
                            {/* <i className="fs-input-icon fa fa-user " /> */}
                          </div>
                          {formErrors.firstName && (
                            <span className="error-message">
                              {formErrors.firstName}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="form-group">
                          <label>Last Name</label>
                          <div className="ls-inputicon-box">
                            <input
                              className="form-control"
                              name="lastName"
                              maxLength={20}
                              type="text"
                              placeholder="Last Name"
                              value={formValues.lastName}
                              onChange={(e) => handleInputChange(e, "lastName")}
                            />
                            {/* <i className="fs-input-icon fa fa-user " /> */}
                          </div>
                          {formErrors.lastName && (
                            <span className="error-message">{formErrors.lastName}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>Email Address</label>
                          <div className="ls-inputicon-box">
                            <input
                              className="form-control"
                              name="email"
                              type="email"
                              placeholder="Email"
                              value={formValues.email}
                              onChange={(e) => handleInputChange(e, "email")}
                            />
                            {/* <i className="fs-input-icon fas fa-at" /> */}
                          </div>
                          {formErrors.email && (
                            <span className="error-message">{formErrors.email}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        {/* <div className="form-group city-outer-bx has-feedback"> */}
                        <div className="form-group ">
                          <label>Address</label>
                          <div className="ls-inputicon-box">
                            <GoogleMapsProvider>
                              <GooglePlacesAutocomplete
                                //ref={googlePlacesRef}
                                name='address'
                                value={formValues.address}
                                onChange={(e) => handleInputChange(e, "address")}
                                onPlaceSelected={handlePlaceSelect}
                                className="form-control"
                                placeholder="Select for a location"
                                apiKey={process.env.REACT_APP_GOOGLE_API}
                                onError={handleError}
                                // apiKey={GOOGLE_API}
                                types={["geocode"]}
                                options={{
                                  types: ["address"],
                                  bounds: {
                                    north: parseFloat(process.env.REACT_APP_LOC_NORTH_BOUND),
                                    south: parseFloat(process.env.REACT_APP_LOC_SOUTH_BOUND),
                                    east: parseFloat(process.env.REACT_APP_LOC_EAST_BOUND),
                                    west: parseFloat(process.env.REACT_APP_LOC_WEST_BOUND),
                                  },
                                  componentRestrictions: { country: "us" },
                                }}
                              />
                            </GoogleMapsProvider>
                          </div>
                          {formErrors.address && (
                            <span className="error-message">{formErrors.address}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="form-group ">
                          <label>Professions</label>
                          <div className="custom-dropdown">
                            <button
                              type="button"
                              className="btn dropdown-toggle form-control"
                              onClick={toggleDropdown}
                              style={{ color: "black", textAlign: "left", height: "auto", textWrap: "wrap" }}
                            >
                              {/* {Object.keys(selectedFields).join(', ')} */}
                              {selectedType.length > 0 ? selectedType.join(', ') : 'Select Job Type'}
                            </button>
                            <div
                              ref={dropdownRef}
                              className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}
                            >
                              {positionDropdownList?.map((position) => (
                                <div key={position.typeName} className="dropdown-item">
                                  <input
                                    type="checkbox"
                                    style={{ marginRight: 10 }}
                                    value={position.typeName}
                                    checked={selectedType.includes(position.typeName)}
                                    onChange={(e) =>
                                      handlePositionChange(e, position.typeName)
                                    }
                                    {...getCheckboxAttributes(
                                      !selectedProfessionList.includes(position.typeName)
                                    )}
                                    {...getCheckboxAttributesDisabled(position)}
                                  />
                                  <label>{position.typeName}</label>
                                </div>
                              ))}
                            </div>
                          </div>
                          {formErrors.profession && (
                            <span className="error-message">
                              {formErrors.profession}
                            </span>
                          )}

                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="text-center">
                          <button
                            type="submit"
                            className="site-button"
                            onClick={handlePrivateApplicantSubmit}
                          >
                            {isLoading ? <Spinner /> : "Submit"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* )} */}
      {errSuccessMesg?.message !== "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
};

export default PrivateApplicantRegister;
