import { useEffect, useState } from "react";
import JobZImage from "../../../common/jobz-img";
import JobViewPopup from "../../../common/popups/popup-job-view";
import { loadScript } from "../../../../globals/constants";
import { useDispatch, useSelector } from "react-redux";
import apiCall from "../../../../apicall";
import SectionRecordsFilter from "../../public-user/sections/common/section-records-filter";
import TableLoader from "../../../common/tableLoader";
import moment from "moment";
import SectionPagination from "../../public-user/sections/common/section-pagination";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../../common/spinner";
import Toast from "../../../common/toast";
import { jobDetailsData, employerDetails } from "../../../../store/reducers/menu";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../globals/fomat-comapny-name";
import { logEvent_Analytics } from "../../../common/firebase/firebase-analytics-service";

function CanPaymentDetailsPage({ customerDetail, isPoster }) {
  const { role_type, TOKEN, userInfo } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentUrl = location.pathname;
  const [paymentCurrentPage, setPaymentCurrentPage] = useState(1);
  const [rowPaymentPerPage, setPaymentRowPerPage] = useState(10);
  let customerId = "";
  const [paginationPaymentData, setPaginationPaymentData] = useState({
    totalElements: 0,
    totalPages: 0,
  });
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [loadingButton, setLoadingButton] = useState(null);

  const [tableFilter, setTableFilter] = useState({
    sortType: "createdDate",
    sortDirection: "desc",
    status: "",
    searchKey: "",
  });
  const [empPaymentInfo, setEmpPaymentInfo] = useState({});

  const _filterConfig = {
    prefix: "Showing",
    type: "results",
    isSorting: true,
    total: paginationPaymentData?.totalElements,
    showRange: false,
    showingUpto: "",
    paymentFilter: true,
  };

  const displayEmpPaymentErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    // console.log("error pagination");
    setPaginationPaymentData({
      totalElements: 0,
      totalPages: 0,
    });
    setPaymentLoader(false);
  };

  const getPaymentData = async (endpoint, customerId) => {
    try {
      setPaymentLoader(true);
      const api = `${endpoint}?offset=0&page=${paymentCurrentPage - 1}&size=${rowPaymentPerPage}&searchKey=${tableFilter?.searchKey}&sortBy=${tableFilter?.sortType}&sortDirection=${tableFilter?.sortDirection}`;
      const empPaymentResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("empPaymentResponse---", empPaymentResponse);
  
      if (empPaymentResponse?.data.content?.length > 0) {
        setSessionExpired(false);
        setEmpPaymentInfo(empPaymentResponse.data.content);
        setPaginationPaymentData({
          totalElements: empPaymentResponse.data.totalElements,
          totalPages: empPaymentResponse.data.totalPages,
        });
        setPaymentLoader(false);
      } else if (empPaymentResponse?.data.content?.length === 0) {
        setEmpPaymentInfo({});
        setPaginationPaymentData({
          totalElements: 0,
          totalPages: 0,
        });
        setPaymentLoader(false);
      } else if (empPaymentResponse?.response?.status === 401) {
        setSessionExpired(true);
        displayEmpPaymentErrMsg("Your session has expired!!");
      } else {
        setSessionExpired(false);
        // displayEmpPaymentErrMsg("Something went wrong!");
        displayEmpPaymentErrMsg(empPaymentResponse?.response?.data?.message)
      }
    } catch (err) {
      console.log("Error payment details", err);
      setSessionExpired(false);
      displayEmpPaymentErrMsg("Something went wrong!");
    }
  };

  const transferAmountApi = async (jobid) => {
    try {
      setLoadingButton(jobid);
      const api = `openstaff/reports/${jobid}/transferPayment`;
      const transferAmountResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("transferAmountResponse---", transferAmountResponse);
  
      if (transferAmountResponse?.status === 200) {
        setSessionExpired(false);
        setLoadingButton(null); 
        setErrSuccessMsg({
          message: "Payment transfer successfully!",
          status: true,
        });
        getEmpPaymentList();
      }else if (transferAmountResponse?.response?.status === 401) {
        setSessionExpired(true);
        setLoadingButton(null); 
        setErrSuccessMsg({
          message: "Your session has expired!!",
          status: false,
        });
      } else {
        setSessionExpired(false);
        setLoadingButton(null); 
        setErrSuccessMsg({
          message: transferAmountResponse?.response?.data?.message,
          status: false,
        });
      }
    } catch (err) {
      console.log("Error payment transfer error", err);
      setSessionExpired(false);
      setLoadingButton(null); 
      setErrSuccessMsg({
        message: 'Network Error',
        status: false,
      });
    }
  };

 const getEmpPaymentList = () => {
  if (role_type.includes("ROLE_INSTITUTION_ADMIN")) {
    getPaymentData(`institutions/${userInfo?.institutionId}/paymentDetails?status=All&customerId=${userInfo?.institutionId}`, userInfo?.institutionId);
  } else if ((role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN"))  && currentUrl?.includes('/payment-history')) {
    getPaymentData('openstaff/institutions/institutionsPaymentDetails');
  } else if (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")) {
    getPaymentData(`institutions/${customerDetail?.id}/paymentDetails?status=All&customerId=${customerDetail?.id}`, customerDetail?.id);
  }
};

  useEffect(() => {
    getEmpPaymentList();
  }, [paymentCurrentPage,rowPaymentPerPage,tableFilter]);

  useEffect(() =>{
    logEvent_Analytics('payment_details_screen_web');
  }, [])

  const handleRecordsPaymentPerPageChange = (event) => {
    setPaymentCurrentPage(1);
    setPaymentRowPerPage(event.target.value);
  };

  const handleSearchClick = (e) =>{
    if (e.key === "Enter") {
        setPaymentCurrentPage(1);
         // getEmpPaymentList()
        setTableFilter({
            ...tableFilter,
            searchKey: searchKey
          })
    }
  }

  const handleSearchKeyFunction = () =>{
    setPaymentCurrentPage(1);
    setTableFilter({
        ...tableFilter,
        searchKey: searchKey
      })
    // getEmpPaymentList()
  }

  useEffect(() => {
    loadScript("js/custom.js");
  });

  const navigateToJobDetail = (item) => {
    // dispatch(jobDetailsData({id : item}));
    navigate(`/job-detail/${item}`, { state: {id: item} });
  };

  const navigateToEmployee = (item) => {
    dispatch(employerDetails({ id: item }))
    navigate(`/emp-detail`, { state: {id: item} });
  };

  const getJobValue = (status, item) => {
    if (status === "eCompleted") {
      return <h5 style={{ fontWeight: "600", color: "green" }}>Completed</h5>; // Return an empty string or handle it as needed
    } else if (status === "eInProgress") {
      return <h5 style={{ fontWeight: "600", color: "orange" }}>InProgress</h5>;
    } else {
      return <h5 style={{ fontWeight: "600" }}>{status.startsWith("e") ? status.substring(1) : status}</h5>;
    }
  };

  return (
    <>
    {currentUrl?.includes('/payment-history') && (<div>
        <Helmet>
          <title>{`Payment Information, Manage Payment Details, Payment Methods, Payment Overview | ${formatCompanyName()}`}</title>
          <meta name="description" content="Welcome to the Payment Details Page! Here, you can manage and update your payment information securely. This section allows you to add or modify your payment methods, view your transaction history, and manage billing preferences." />
          <meta name="keywords" content="payment details, payment methods " />
        </Helmet>
      </div>
    )}
    <div className="mt-5">
      <SectionRecordsFilter
        _config={_filterConfig}
        rowPerPage={rowPaymentPerPage}
        handleRecordsPerPageChange={handleRecordsPaymentPerPageChange}
        setTableFilter={setTableFilter}
        tableFilter={tableFilter}
        setCurrentPage={setPaymentCurrentPage}
        handleSearchClick={handleSearchClick}
        handleSearchKeyFunction={handleSearchKeyFunction}
        setPaymentCurrentPage={setPaymentCurrentPage}
        setSearchKey={setSearchKey}
        searchKey={searchKey}
      />
      <div className="twm-right-section-panel candidate-save-job ">
        <div className="twm-D_table table-responsive">
          <table
            id="payment-details-table"
            className="table table-bordered twm-candidate-save-job-list-wrap"
          >
            <thead>
              <tr>
                <th>Job Title</th>
                {
                    (role_type.includes('ROLE_OPENSTAFF_ADMIN') || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN"))&& 
                    <th>Company</th>
                }
                <th>Total</th>
                <th>Actual Total</th>
                <th>Booking Fee</th>
                <th>Salary</th>
                <th>Service Fee</th>
                {/* <th>Paid Out</th> */}
                <th>Created Date</th>
                <th>Payment ID</th>
                <th>Payment Intent ID</th>
                <th>Transfer ID</th>
                <th>Total Hours</th>
                <th>Worked Hours</th>
                <th>Stripe Status</th>
                <th>Job Status</th>
                <th>Payment Method</th>
                {
                    (role_type.includes('ROLE_OPENSTAFF_ADMIN') || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")) && 
                    <th>Action</th>
                }
              </tr>
            </thead>
            <tbody>
              {/*1*/}
              {!paymentLoader ? (
                empPaymentInfo?.length > 0 ? (
                  empPaymentInfo?.map((item) => (
                    <tr>
                      <td>
                        <div className="twm-candidate-save-job-list">
                          <div className="twm-mid-content">
                              {/* <h4>{item.jobTitle}</h4> */}
                            <div style={{ cursor : "pointer" }} onClick={()=>navigateToJobDetail(item?.jobId)} className="twm-job-title">
                              <h4>{item.jobTitle}</h4>
                            </div>
                          </div>
                        </div>
                      </td>
                      {
                        (role_type.includes('ROLE_OPENSTAFF_ADMIN') || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")) && currentUrl?.includes('/payment-history') && 
                        <td>
                            <NavLink onClick={()=>navigateToEmployee(item?.instituteId)}>{item?.name}</NavLink>
                        </td>
                        }
                      {
                        (role_type.includes('ROLE_OPENSTAFF_ADMIN') || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN"))&&  currentUrl?.includes('/emp-detail')   && 
                        <td>
                            <NavLink>{customerDetail?.name}</NavLink>
                        </td>
                        }
                      <td>
                        <div> ${item?.amount ? (item?.amount / 100).toFixed(2) : 0.00}</div>
                      </td>
                      <td>
                        <div> ${item?.totalWorkedAmount ? (item?.totalWorkedAmount / 100).toFixed(2) : 0.00}</div>
                      </td>
                      <td>
                        <div> ${item?.bookingAndProcessingFee ? (item?.bookingAndProcessingFee / 100).toFixed(2) : 0.00}</div>
                      </td>
                      <td>
                        <div> ${item?.salary ? ((item?.salary / 100) * Number(item?.totalHours)).toFixed(2)  : 0.00}</div>
                      </td>
                      <td>
                        <div> ${item?.serviceFee ? (item?.serviceFee / 100).toFixed(2) : 0.00}</div>
                      </td>
                      {/* <td>
                        <div>{item?.paid ? "Yes" : "No"}</div>
                      </td> */}
                      <td>
                        <div>
                          {item?.modifiedDate
                            ? moment(item?.modifiedDate).format("MM/DD/YYYY")
                            : "-"}
                        </div>
                      </td>
                      <td>
                        <div>{item?.paymentId ? item?.paymentId : "-"}</div>
                      </td>
                      <td>
                        <div>{item?.paymentIntentId ? item?.paymentIntentId : "-"}</div>
                      </td>
                      <td>
                        <div>{item?.transferId ? item?.transferId : "-"}</div>
                      </td>
                      <td>
                        <div>{item?.totalHours}</div>
                      </td>
                      <td>
                        <div>{item?.totalWorkedHours}</div>
                      </td>
                      <td>
                        <div>{item?.captured ? item.status :  "uncaptured"}</div>
                      </td>
                      <td>
                        <div>{getJobValue(item?.jobStatus, item)}</div>
                      </td>
                      <td>
                        <div>{item?.paymentMethod?.replace(/^"(.*)"$/, '$1') || item?.paymentMethod}</div>
                      </td>
                      {
                         (role_type.includes('ROLE_OPENSTAFF_ADMIN') || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")) && item?.jobStatus === 'eCompleted' && item?.paymentMethod === 'stripe' ?
                         <td>
                           <button
                               className="site-button"
                              disabled={loadingButton}
                              onClick={() => transferAmountApi(item.jobId)}
                            >
                              {loadingButton === item.jobId ? (
                                <Spinner size="sm" />
                              ) : (
                                "Transfer"
                              )}
                            </button>
                          </td>
                          : 
                          role_type.includes('ROLE_OPENSTAFF_ADMIN') &&
                          <td></td>
                      }
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={17} style={{ textAlign: "center" }}>
                      No results found.
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan={17} style={{ textAlign: "center" }}>
                    <TableLoader />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <SectionPagination
          currentPage={paymentCurrentPage}
          setCurrentPage={setPaymentCurrentPage}
          totalPages={paginationPaymentData?.totalPages}
        />
      </div>

      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}

      <JobViewPopup />
    </div>
    </>
  );
}

export default CanPaymentDetailsPage;
