import { NavLink, useLocation } from "react-router-dom";
import { publicUser } from "../../../../../../globals/route-names";
import SectionSideAdvert from "./section-side-advert";
import { useEffect, useState } from "react";
import { loadScript } from "../../../../../../globals/constants";
import apiCall from "../../../../../../apicall";
import { useSelector, useDispatch } from "react-redux";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import GooglePlacesAutocomplete from "react-google-autocomplete";
// import { GOOGLE_API } from "../../../../../../config";
import TableLoader from "../../../../../common/tableLoader";
import {
  addressSelectedStatus,
  cityListDetails,
} from "../../../../../../store/reducers/menu";

function SectionJobsSidebar1({
  setTableFilter,
  tableFilter,
  setCurrentPage,
  applicantList,
  customerList,
  dispErrMsg
}) {
  console.log("table filter-----section top", tableFilter);
  const { TOKEN, role_type , CONFIGKEY, isLoggedIn, cityListData, userInfo } = useSelector(
    (state) => state.menu
  );

  console.log("cityListData----", cityListData,userInfo);
  const location = useLocation();
  const dispatch = useDispatch();
  const currentUrl = location.pathname;
  const [selectedLocationName, setSelectedLocationName] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [jobTypes, setJobTypes] = useState([]);
  const [cityList, setCityList] = useState(cityListData);
  const [isJobTypeLoading, setIsJobTypeLoading] = useState(false);
  const [searchedKey, setSearchedKey] = useState("");
  const [updatedLocationName, setUpdatedLocationName] = useState(
    tableFilter?.locationName
  );
  // const [applicantList, setApplicantList] = useState([]);
  // const [applicantLoader, setApplicantLoader] = useState(false);
  // const [customerList, setCustomerList] = useState([]);
  // const [customerLoader, setCustomerLoader] = useState(false);
  const [fromDateSelected, setFromDateSelected] = useState("");
  const [fromDate, setFromDate] = useState(null); // Store date as Date object
  const [toDate, setToDate] = useState(null);

  console.log("table filter-----", tableFilter);

  useEffect(() => {
    if (
      currentUrl?.includes("/job-list") ||
      currentUrl?.includes("/job-grid") ||
      currentUrl?.includes("/search-job") ||
      currentUrl?.includes("/applied-job") ||
      currentUrl?.includes("/ongoing-job") ||
      currentUrl?.includes("/schedule-job") ||
      currentUrl?.includes("/history-job")
    ) {
      const fetchData = async () => {
        setIsJobTypeLoading(true);
        const positionResponse = await apiCall(
          "config/allJobTypes",
          "GET",
          null,
          TOKEN
        );
        if (positionResponse?.data?.length > 0) {
          const jobTypesData = positionResponse.data.map(
            (item) => item.typeName
          );
          setJobTypes(jobTypesData);
          setIsJobTypeLoading(false);
        }
        else if (positionResponse?.data?.length === 0){
          setIsJobTypeLoading(false);
        }
      };

      fetchData();
    }
  }, []);
  useEffect(() => {
    if (cityListData?.length === 0 && !isLoggedIn) {
      fetchCityList();
    }
  }, []);

  // const handleDateChange = (date, fieldName) => {
  //   if (fieldName === "fromDate") {
  //     setFromDateSelected( date ? moment(date).format("YYYY-MM-DDTHH:mm:ss[Z]") : "");
  //   } else if (fieldName === "toDate") {
  //     setTableFilter({
  //       ...tableFilter,
  //       fromDate: fromDateSelected,
  //       toDate: date ? moment(date).format("YYYY-MM-DDTHH:mm:ss[Z]") : "",
  //     });
  //     setCurrentPage(1);
  //   }
  // };

  // const handleDateChange = (date, fieldName) => {
  //   if (fieldName === "fromDate") {
  //     const formattedFromDate = date ? moment(date).startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]") : "";
  //     setFromDateSelected(formattedFromDate);
  //     // setTableFilter({
  //     //   ...tableFilter,
  //     //   fromDate: formattedFromDate,
  //     // });
  //   } else if (fieldName === "toDate") {
  //     const formattedToDate = date ? moment(date).startOf('day').add(23, 'hours').add(59, 'minutes').format("YYYY-MM-DDTHH:mm:ss[Z]") : "";
  
  //     // Validate if the end date is after or equal to the start date
  //     if (moment(formattedToDate).isBefore(moment(fromDateSelected))) {
  //       dispErrMsg("End Date cannot be before Start Date.")
  //       return;
  //     }
  
  //     setTableFilter({
  //       ...tableFilter,
  //       fromDate: fromDateSelected,
  //       toDate: formattedToDate,
  //     });
  //     setCurrentPage(1);
  //   }
  // };

  const handleDateChange = (date, fieldName) => {
    if (fieldName === "fromDate") {
      setFromDate(date);

      // Format date for API (with timezone information)
      const formattedFromDate = date
        ? moment(date).startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
        : "";
      setFromDateSelected(formattedFromDate);
    } else if (fieldName === "toDate") {
      if (date && fromDate && moment(date).isBefore(moment(fromDate))) {
        dispErrMsg("End Date cannot be before Start Date.");
        return;
      }

      setToDate(date);

      // Format date for API (with timezone information)
      const formattedToDate = date
        ? moment(date).endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
        : "";

      setTableFilter({
        ...tableFilter,
        fromDate: fromDateSelected,
        toDate: formattedToDate,
      });
      setCurrentPage(1);
    }
  };

  const formatDateForPicker = (date) => {
    return date ? moment(date).toDate() : null;
  };

  const handleApplicantChange = (event) => {
    setTableFilter({
      ...tableFilter,
      applicant: event.target.value,
    });
    setCurrentPage(1);
  };

  const handleCustomerChange = (event) => {
    setTableFilter({
      ...tableFilter,
      customer: event.target.value,
    });
    setCurrentPage(1);
  };

  const handleJobTypeChange = (event) => {
    console.log("handleJobTypeChange", event);
    setTableFilter({
      ...tableFilter,
      categoryType: event.target.value,
    });
    setCurrentPage(1);
  };

  // const handleSearchKeyChange = (e) => {
  //   setSearchedKey(e.target.value);
  // };
  const handleSearchKeyChange = (e) => {
    const newSearchKey = e.target.value;
    setSearchedKey(newSearchKey);

    if (newSearchKey.trim() === "") {
      setTableFilter({
        ...tableFilter,
        searchKey: newSearchKey,
      });
      setCurrentPage(1);
    }
  };

  const handleSearchClick = () => {
    setTableFilter({
      ...tableFilter,
      searchKey: searchedKey,
    });
    setCurrentPage(1);
  };

  const handleStatusChange = (e) => {
    console.log("table filter-----sectionstatus---", tableFilter);
    setTableFilter({
      ...tableFilter,
      jobStatus: e.target.value,
    });
    setCurrentPage(1);
  };

  const handleRoleChange = (e) => {
    console.log("table filter Role-----sectionstatus---", tableFilter);
    setTableFilter({
      ...tableFilter,
      roleType: e.target.value,
    });
    setCurrentPage(1);
  };

  const handleApplicationStatusChange = (e) => {
    console.log("table filter Application-----sectionstatus---", tableFilter);
    setTableFilter({
      ...tableFilter,
      applicationStatus: e.target.value,
    });
    setCurrentPage(1);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };

  const handleInputChange = (e) => {
    setUpdatedLocationName(e.target.value);
    console.log("e.target.value", e.target.value === "");
    if (e.target.value === "") {
      setTableFilter({
        ...tableFilter,
        lat: "",
        lng: "",
        locationName: "",
      });
      dispatch(
        addressSelectedStatus({
          latlng: { lat: "", lng: "" },
          jobType: tableFilter?.jobType,
          locationName: "",
        })
      );
    }
  };

  const handlePlaceSelect = (place) => {
    console.log("table filter-----section", tableFilter);

    if (place?.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      //to store address in redux to dislay on job list
      dispatch(
        addressSelectedStatus({
          latlng: { lat, lng },
          jobType: tableFilter?.jobType,
          locationName: place?.formatted_address,
        })
      );
      setUpdatedLocationName(place?.formatted_address);
      setCurrentPage(1);
      setTableFilter((prevValues) => ({
        ...prevValues,
        lat,
        lng,
        locationName: place?.formatted_address,
      }));
    }
  };
  const fetchCityList = async () => {
    const cityListResponse = await apiCall(
      "config/locationList",
      "GET",
      null,
      TOKEN
    );
    console.log("cityListResponse", cityListResponse);
    if (cityListResponse?.data?.length > 0) {
      // const cityListData = cityListResponse.data.map((item) => item.city);
      setCityList(cityListResponse?.data);
      dispatch(cityListDetails(cityListResponse?.data));
    }
  };

  const handleCitySelect = (selectedJobType) => {
    const selectedCity = cityList.filter((val) => val.city === selectedJobType);
    setCurrentPage(1);
    if (selectedCity?.length > 0) {
      setTableFilter((prevValues) => ({
        ...prevValues,
        lat: selectedCity[0].lat,
        lng: selectedCity[0].lng,
        locationName: selectedCity[0].city,
      }));
    } else {
      setTableFilter((prevValues) => ({
        ...prevValues,
        lat: "",
        lng: "",
        locationName: "",
      }));
    }
  };

  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      <div className="side-bar">
        <div className="sidebar-elements search-bx">
          <form>
            {isLoggedIn && (!currentUrl.includes("/users-list") && !currentUrl.includes("/users-grid") && !currentUrl.includes("/can-grid") && !currentUrl.includes("/can-list") && !currentUrl.includes("/manage-team")) && (
              <div className="form-group mb-4 joblist-sidebar">
                <h4 className="section-head-small mb-4">Location</h4>
                <div className="input-group">
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_API}
                    onPlaceSelected={handlePlaceSelect}
                    onChange={handleInputChange}
                    placeholder="Search..."
                    className="form-control"
                    types={["geocode"]}
                    options={{
                      types: ["address"],
                      bounds: {
                        north:parseFloat(process.env.REACT_APP_LOC_NORTH_BOUND),  
                        south:parseFloat(process.env.REACT_APP_LOC_SOUTH_BOUND),  
                        east: parseFloat(process.env.REACT_APP_LOC_EAST_BOUND), 
                        west: parseFloat(process.env.REACT_APP_LOC_WEST_BOUND),  
                      },
                      componentRestrictions: { country: "us" },
                    }}
                    value={updatedLocationName}
                  />
                  <button className="btn" type="button">
                    <i className="feather-map-pin" />
                  </button>
                </div>
              </div>
            )}

            {cityList?.length > 0 && !isLoggedIn && (
              <div className="form-group mb-4 joblist-sidebar">
                <h4 className="section-head-small mb-4">Location</h4>
                <select
                  className="wt-select-bar-large selectpicker"
                  data-live-search="true"
                  data-bv-field="size"
                  defaultValue={tableFilter?.locationName}
                  onChange={(e) => handleCitySelect(e.target.value)}
                >
                  <option value="">Select Location</option>
                  {cityList?.map((type, index) => (
                    <option key={index} value={type.city}>
                      {type.city} ({type.adminName})
                    </option>
                  ))}
                </select>
              </div>
            )}
            {(currentUrl?.includes("/job-list") ||
              currentUrl?.includes("/job-grid") ||
              currentUrl?.includes("/job-calender-view")) &&
              (role_type?.length === 0 || role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN")) && (
                <div className="form-group mb-4">
                  <h4 className="section-head-small mb-4">Status</h4>
                  <select
                    className="wt-select-bar-large selectpicker"
                    data-live-search="true"
                    data-bv-field="size"
                    value={tableFilter?.jobStatus}
                    onChange={handleStatusChange}
                  >
                    {(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN")) && (
                      <option value="All">All</option>
                    )}
                    <option value="eOpen">Open Jobs</option>

                    {(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN")) && (
                      // || (role_type.includes() === "ROLE_USER"
                      <>
                        <option value="eAccepted">Accepted</option>
                        <option value="eApplied">Applied</option>
                      </>
                    )}

                    {role_type.includes("ROLE_USER") && (
                      <>
                        <option value="myJobs">Applied</option>
                        <option value="onGoing">Ongoing</option>
                      </>
                    )}

                    {role_type.includes("ROLE_INSTITUTION_ADMIN") && (
                      <option value="eScheduled">Scheduled</option>
                    )}

                    {(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN")) && (
                      <>
                        <option value="eCancelled">Cancelled</option>
                        <option value="eClosed">Closed</option>
                        <option value="eCompleted">Completed</option>
                        <option value="eCreated">Created</option>
                        <option value="eDeleted">Deleted</option>
                        <option value="eInProgress">InProgress</option>
                        <option value="eUnAvailable">UnAvailable</option>
                        <option value="eUnfulfilled">Unfulfilled</option>
                      </>
                    )}
                    {(role_type.includes("ROLE_USER") ||
                      role_type.includes("ROLE_INSTITUTION_ADMIN")) && (
                      <option value="eHistory">History</option>
                    )}
                  </select>
                </div>
              )}

            {(currentUrl?.includes("/users-list") ||
              currentUrl?.includes("/users-grid")) &&
              role_type.includes("ROLE_OPENSTAFF_ADMIN") && (
                <div className="form-group mb-4">
                  <h4 className="section-head-small mb-4">Status</h4>
                  <select
                    className="wt-select-bar-large selectpicker"
                    data-live-search="true"
                    data-bv-field="size"
                    value={tableFilter?.userListStatus || ""}
                    onChange={handleStatusChange}
                  >
                    <option value="All">All</option>
                    <option value="INACTIVE">Inactive</option>
                    <option value="UNVERIFIED">Unverified</option>
                    <option value="PENDING_APPROVAL">Pending Approval</option>
                    <option value="ACTIVE">Active</option>
                    <option value="LOCKED">Locked</option>
                    <option value="SUSPENDED">Suspended</option>
                    <option value="DELETED">Deleted</option>
                    <option value="EXPIRED">Expired</option>
                  </select>
                </div>
              )}
            {(currentUrl?.includes("/users-list") ||
              currentUrl?.includes("/users-grid")) &&
              role_type.includes("ROLE_OPENSTAFF_ADMIN") && (
                <div className="form-group mb-4">
                  <h4 className="section-head-small mb-4">Role</h4>
                  <select
                    className="wt-select-bar-large selectpicker"
                    data-live-search="true"
                    data-bv-field="size"
                    value={tableFilter?.userListRoleType || ""}
                    onChange={handleRoleChange}
                  >
                    <option value="All">All</option>
                    <option value="ROLE_INSTITUTION_ADMIN">Role Institution Admin</option>
                    <option value="ROLE_USER">Role User</option>
                  </select>
                </div>
              )}
            {(currentUrl?.includes("/can-list") ||
              currentUrl?.includes("/can-grid")) &&
              (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_SEEKER_USER_ADMIN")) && (
                <div className="form-group mb-4">
                  <h4 className="section-head-small mb-4">Status</h4>
                  <select
                    className="wt-select-bar-large selectpicker"
                    data-live-search="true"
                    data-bv-field="size"
                    value={tableFilter?.userListRoleType || ""}
                    onChange={handleApplicationStatusChange}
                  >
                    <option value="All">All</option>
                    <option value="verified">Verified</option>
                    <option value="unverified">Unverified</option>
                    <option value="pending">Pending</option>
                    <option value="submitted">Submitted</option>
                    <option value="restricted">Restricted</option>
                    <option value="verification failed">Verification Failed</option>
                    <option value="verification in progress">Verification In Progress</option>
                    <option value="suspended">Suspended</option>
                  </select>
                </div>
              )}

            {(currentUrl?.includes("/job-list") ||
              currentUrl?.includes("/job-grid") ||
              currentUrl?.includes("/search-job") ||
              currentUrl?.includes("/applied-job") ||
              currentUrl?.includes("/ongoing-job") ||
              currentUrl?.includes("/schedule-job") ||
              currentUrl?.includes("/history-job") ||
              currentUrl?.includes("/job-calender-view")) &&
            // role_type.includes() !== "ROLE_USER" &&
            !isJobTypeLoading ? (
              <div className="form-group mb-4">
                <h4 className="section-head-small mb-4">Category</h4>
                <select
                  className="wt-select-bar-large selectpicker"
                  data-live-search="true"
                  data-bv-field="size"
                  value={tableFilter?.categoryType || ""}
                  onChange={handleJobTypeChange}
                >
                  <option value="All">Select Job Type</option>
                  {jobTypes?.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
            ) : // role_type.includes() !== "ROLE_USER" &&
            currentUrl?.includes("/job-list") ||
              currentUrl?.includes("/job-grid") ||
              currentUrl?.includes("/search-job") ||
              currentUrl?.includes("/applied-job") ||
              currentUrl?.includes("/ongoing-job") ||
              currentUrl?.includes("/schedule-job") ||
              currentUrl?.includes("/history-job") ? (
              <TableLoader />
            ) : (
              ""
            )}

            <div className="form-group mb-4">
              <h4 className="section-head-small mb-4">Keyword</h4>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder={
                    currentUrl?.includes("/users-list") || currentUrl?.includes("/users-grid")
                      ? "Search By Name" : (currentUrl?.includes("/manage-team-employee") || currentUrl?.includes("/manage-team-trusted-worker") ) ? "Name"
                      : "Job Title/Position"
                  }
                  value={searchedKey}
                  onChange={handleSearchKeyChange}
                  onKeyPress={handleKeyPress}
                />
                <button className="btn" type="button">
                  <i className="feather-search" onClick={handleSearchClick} />
                </button>
              </div>
            </div>

            {(currentUrl?.includes("/job-list") ||
              currentUrl?.includes("/job-grid") ||
              currentUrl?.includes("/job-calender-view")) &&
            applicantList?.length > 0 ? (
              <div className="form-group mb-4">
                <h4 className="section-head-small mb-4">Applicants</h4>
                <select
                  className="wt-select-bar-large selectpicker"
                  data-live-search="true"
                  data-bv-field="size"
                  value={tableFilter?.applicants || ""}
                  onChange={handleApplicantChange}
                >
                  <option value="">Select Applicants</option>
                  {applicantList?.map((val, index) => (
                    <option key={index} value={val?.id}>
                      {`${val.firstName} ${val.lastName}`}
                    </option>
                  ))}
                </select>
              </div>
            ) : (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN")) &&
              (currentUrl?.includes("/job-list") ||
                currentUrl?.includes("/job-grid")) ? (
                  applicantList?.length === 0 ? "" :
                  <TableLoader />
            ) : (
              ""
            )}

            {(currentUrl?.includes("/job-list") ||
              currentUrl?.includes("/job-grid") ||
              currentUrl?.includes("/job-calender-view")) &&
            customerList?.length > 0 ? (
              <div className="form-group mb-4">
                <h4 className="section-head-small mb-4">Customers</h4>
                <select
                  className="wt-select-bar-large selectpicker"
                  data-live-search="true"
                  data-bv-field="size"
                  value={tableFilter?.customer || ""}
                  onChange={handleCustomerChange}
                  style={{ display: "" }}
                >
                  <option value="">Select Customers</option>
                  {customerList?.map((val, index) => (
                    <option key={index} value={val?.id}>
                      {`${val?.name}`}
                    </option>
                  ))}
                </select>
              </div>
            ) : role_type.includes( "ROLE_OPENSTAFF_ADMIN") &&
              (currentUrl?.includes("/job-list") ||
                currentUrl?.includes("/job-grid")) ? (
              <TableLoader />
            ) : (
              ""
            )}

            {(currentUrl?.includes("/job-list") ||
              currentUrl?.includes("/job-grid") ||
              currentUrl?.includes("/job-calender-view")) &&
              (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN")) && (
                <>
                  <div className="form-group mb-4">
                    <h4 className="section-head-small mb-4">Start Date</h4>
                    <div className="input-group">
                      <ReactDatePicker
                        className="form-control"
                        selected={formatDateForPicker(fromDate)}
                        onChange={(date) => handleDateChange(date, "fromDate")}
                        dateFormat="MM-dd-yyyy"
                        placeholderText="MM/DD/YYYY"
                      />
                    </div>
                  </div>

                  <div className="form-group mb-4">
                    <h4 className="section-head-small mb-4">End Date</h4>
                    <div className="input-group">
                      <ReactDatePicker
                        className="form-control"
                        selected={formatDateForPicker(toDate)}
                        onChange={(date) => handleDateChange(date, "toDate")}
                        dateFormat="MM-dd-yyyy"
                        placeholderText="MM/DD/YYYY"
                        disabled={!fromDate}
                      />
                    </div>
                  </div>
                </>
              )}
            {/* <div className="twm-sidebar-ele-filter">
              <h4 className="section-head-small mb-4">Job Type</h4>
              <ul>
                <li>
                  <div className=" form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      Freelance
                    </label>
                  </div>
                  <span className="twm-job-type-count">09</span>
                </li>
                <li>
                  <div className=" form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck2"
                    />
                    <label className="form-check-label" htmlFor="exampleCheck2">
                      Full Time
                    </label>
                  </div>
                  <span className="twm-job-type-count">07</span>
                </li>
                <li>
                  <div className=" form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck3"
                    />
                    <label className="form-check-label" htmlFor="exampleCheck3">
                      Internship
                    </label>
                  </div>
                  <span className="twm-job-type-count">15</span>
                </li>
                <li>
                  <div className=" form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck4"
                    />
                    <label className="form-check-label" htmlFor="exampleCheck4">
                      Part Time
                    </label>
                  </div>
                  <span className="twm-job-type-count">20</span>
                </li>
                <li>
                  <div className=" form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck5"
                    />
                    <label className="form-check-label" htmlFor="exampleCheck5">
                      Temporary
                    </label>
                  </div>
                  <span className="twm-job-type-count">22</span>
                </li>
                <li>
                  <div className=" form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck6"
                    />
                    <label className="form-check-label" htmlFor="exampleCheck6">
                      Volunteer
                    </label>
                  </div>
                  <span className="twm-job-type-count">25</span>
                </li>
              </ul>
            </div> */}
            {/* <div className="twm-sidebar-ele-filter">
              <h4 className="section-head-small mb-4">Date Posts</h4>
              <ul>
                <li>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="exampleradio1"
                    />
                    <label className="form-check-label" htmlFor="exampleradio1">
                      Last hour
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="exampleradio2"
                    />
                    <label className="form-check-label" htmlFor="exampleradio2">
                      Last 24 hours
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="exampleradio3"
                    />
                    <label className="form-check-label" htmlFor="exampleradio3">
                      Last 7 days
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="exampleradio4"
                    />
                    <label className="form-check-label" htmlFor="exampleradio4">
                      Last 14 days
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="exampleradio5"
                    />
                    <label className="form-check-label" htmlFor="exampleradio5">
                      Last 30 days
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="exampleradio6"
                    />
                    <label className="form-check-label" htmlFor="exampleradio6">
                      All
                    </label>
                  </div>
                </li>
              </ul>
            </div> */}
            {/* <div className="twm-sidebar-ele-filter">
              <h4 className="section-head-small mb-4">Type of employment</h4>
              <ul>
                <li>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="Freelance1"
                    />
                    <label className="form-check-label" htmlFor="Freelance1">
                      Freelance
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="FullTime1"
                    />
                    <label className="form-check-label" htmlFor="FullTime1">
                      Full Time
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="Intership1"
                    />
                    <label className="form-check-label" htmlFor="Intership1">
                      Intership
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="Part-Time1"
                    />
                    <label className="form-check-label" htmlFor="Part-Time1">
                      Part Time
                    </label>
                  </div>
                </li>
              </ul>
            </div> */}
          </form>
        </div>
        {/* <div className="widget tw-sidebar-tags-wrap">
          <h4 className="section-head-small mb-4">Tags</h4>
          <div className="tagcloud">
            <NavLink to={publicUser.jobs.LIST}>General</NavLink>
            <NavLink to={publicUser.jobs.LIST}>Jobs </NavLink>
            <NavLink to={publicUser.jobs.LIST}>Payment</NavLink>
            <NavLink to={publicUser.jobs.LIST}>Application </NavLink>
            <NavLink to={publicUser.jobs.LIST}>Work</NavLink>
            <NavLink to={publicUser.jobs.LIST}>Recruiting</NavLink>
            <NavLink to={publicUser.jobs.LIST}>Employer</NavLink>
            <NavLink to={publicUser.jobs.LIST}>Income</NavLink>
            <NavLink to={publicUser.jobs.LIST}>Tips</NavLink>
          </div>
        </div> */}
      </div>
      {/* {!isLoggedIn && <SectionSideAdvert />} */}
    </>
  );
}

export default SectionJobsSidebar1;
