import React, { useState, useEffect } from "react";
import GetProfileImage from "../../../../common/getProfileImage";
import BASE_URL from "../../../../../config";
import { useSelector } from "react-redux";
import Toast from "../../../../common/toast";
import Spinner from "../../../../common/spinner";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../globals/fomat-comapny-name";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

const CanCompanyLogo = () => {
  const { TOKEN, role_type, userInfo } = useSelector((state) => state.menu);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUploadButtonClick = () => {
    document.getElementById("fileInput-company-logo").click();
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        setIsLoading(true);
        const url = `${BASE_URL}logo`;

        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("fileType", "ePng");
        const config = {
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ${TOKEN}`,
          },
        };

        const response = await axios.post(url, formData, config);
        console.log("Upload successful:", response);

        if (response?.status == 200) {
          setIsLoading(false);
          setErrSuccessMsg({
            message: "Company logo updated successfully!",
            status: true,
          });
        } else {
          // displayErrMsg("Something went wrong!");
          displayErrMsg(response?.response?.data?.message)
        }
      } catch (error) {
        displayErrMsg("Something went wrong!");
        console.error("Upload failed:", error);
        // Handle failure response if needed
      }
    } else {
      console.log("No file selected.");
    }
  };

  useEffect(() =>{
    logEvent_Analytics('edit_company_pic_web');
  }, [])
  // const StarRating = ({ rating }) => {
  //   const stars = [];

  //   // Iterate over 5 stars
  //   for (let i = 0; i < 5; i++) {
  //     if (rating >= i + 1) {
  //       // Full star if rating is greater than or equal to current index + 1
  //       stars.push(
  //         <i
  //           key={i}
  //           className="fas fa-star"
  //           style={{ color: '#128c5e' }}
  //         />
  //       );
  //     } else if (rating >= i + 0.5) {
  //       // Half star if rating is greater than or equal to current index + 0.5
  //       stars.push(
  //         <i
  //           key={i + 0.5}
  //           className="fas fa-star-half-alt"
  //           style={{ color: '#128c5e' }}
  //         />
  //       );
  //     } else {
  //       // Empty star otherwise
  //       stars.push(
  //         <i
  //           key={i}
  //           className="fas fa-star"
  //           style={{ color: 'lightgrey' }}
  //         />
  //       );
  //     }
  //   }

  //   return stars;
  // };
  const StarRating = ({rating}) => {
    const stars = [];
    const roundedRating = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;

    for (let i = 0; i < 5; i++) {
      if (i < roundedRating) {
        stars.push(
          <i
            key={i}
            className="fas fa-star"
            style={{ color: "#128c5e", position: "relative", top: 0 }}
          />
        );
      } else if (i === roundedRating && hasHalfStar) {
        stars.push(
          <i
            key={i}
            className="fas fa-star-half-alt"
            style={{ color: "#128c5e", position: "relative", top: 0 }}
          />
        );
      } else {
        stars.push(
          <i
            key={i}
            className="fas fa-star"
            style={{ color: "lightgrey", position: "relative", top: 0 }}
          />
        );
      }
    }
    return stars;
  };
  
  return (
    <>
      <div>
        <Helmet>
          <title>{`Company Details | ${formatCompanyName()}`}</title>
          <meta name="description" content="Welcome to the Company Details Page! Here, you can upload and manage your company’s logo to enhance your brand visibility across our platform. A well-designed logo helps create a professional image and establishes your company’s identity." />
          <meta name="keywords" content="company details" />
        </Helmet>
      </div>
      <div className="section-full p-t120  site-bg-white bg-cover twm-ac-fresher-wrap">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-12 col-md-12">
              <div className="twm-right-section-panel candidate-save-job">
                <div className="panel-heading wt-panel-heading p-a20 mb-4">
                  <h3 className="panel-tittle m-a0">Company Details</h3>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 company_logo_details">
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="twm-candidate-profile-pic" style={{ height: "150px" }}>
                      {selectedFile ? (
                        <img src={URL.createObjectURL(selectedFile)} alt="Selected File" />
                      ) : (
                        <GetProfileImage
                          id={null}
                          type={"applicant"}
                          logoType={"company-logo"}
                        />
                      )}
                      {/* <GetProfileImage  id={null} type={'applicant'}/>     */}
                      <div className="upload-btn-wrapper">
                        {/* Hidden file input element */}
                        <div id="upload-image-grid" />
                        <input
                          type="file"
                          id="fileInput-company-logo"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                        {/* Button to trigger file input click */}
                        {/* <button
            className="site-button button-sm"
            onClick={handleUploadButtonClick}
            style={{ top: "0px" }}
          >
            Upload Image
          </button> */}
                      </div>

                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 text-center">
                      <div>
                        <button
                          className="site-button button-sm"
                          onClick={handleUploadButtonClick}
                          style={{ top: "0px" }}
                        >
                          Upload Image
                        </button></div>
                      <div className="mt-1">
                        <a
                          className="site-button button-sm"
                          // style={{ left: "35%" }}
                          onClick={handleUpload}
                          disabled={!selectedFile}
                        >
                          {isLoading ? <Spinner /> : "Save"}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-5">
                    {/* <div className="twm-mid-content text-center">
                    <h4 className="panel-tittle m-a0">Payment Method: <span style={{fontWeight: 400}}>{userInfo?.paymentMethod ? userInfo?.paymentMethod?.replace(/^"(.*)"$/, '$1') : 'stripe'}</span></h4>
              {!(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN") || role_type.includes("ROLE_SEEKER_USER_ADMIN")) && (
                <>
                  <a id="review-list-styling" className="m-b10"
                    data-bs-toggle="modal"
                    href="#review_list_popup"
                    role="button">
                    <StarRating
                      rating={
                        role_type.includes("ROLE_USER")
                          ? userInfo?.applicantAverageRating
                          : userInfo?.instituteAverageRating
                      }
                    />
                    <span>
                      {
                        role_type.includes("ROLE_USER")
                          ? `${userInfo?.applicantNumberOfReviews} Reviews`
                          : `${userInfo?.instituteNumberOfReviews} Reviews`
                      }
                    </span>
                  </a>
                </>
              )}
              <h4>{`${userInfo?.firstName} ${userInfo?.lastName}`} </h4>
              <p></p>
            </div> */}
                    <div className="twm-s-info2">
                      <ul className="twm-job-hilites2">
                        {/*Login Candidate*/}

                        {/*Login Employer*/}
                        {
                          !role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") &&
                          <>
                            <li>
                              <div className="twm-s-info-inner">
                                <i className="fa fa-building" />
                                <span className="twm-title">Company Name</span>
                                <div className="twm-s-info-discription">
                                  {userInfo?.institutionName ? userInfo?.institutionName : '-'}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="twm-s-info-inner">
                                <i className="fs-input-icon fa fa-credit-card" />
                                <span className="twm-title">Payment Method</span>
                                <div className="twm-s-info-discription">
                                  {userInfo?.paymentMethod ? userInfo?.paymentMethod?.replace(/^"(.*)"$/, '$1') : 'stripe'}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="twm-s-info-inner">
                                <i class="fas fa-regular fa-star"></i>
                                <span className="twm-title">Rating & Reviews</span>
                                <div className="twm-s-info-discription">
                                  {/* {StarRating(
                            jobDetailsInfo?.hirePerson
                              ? jobDetailsInfo?.hirePerson?.averageNumStars?.toFixed(
                                1
                              )
                              : jobDetailsInfo?.averageNumStars?.toFixed(1)
                          )}{" "}
                          <span style={{ fontSize: "12px" }}>
                            {jobDetailsInfo?.hirePerson
                              ? `${jobDetailsInfo?.hirePerson?.numberOfReview} Reviews`
                              : `${jobDetailsInfo?.numberOfReview
                                ? jobDetailsInfo?.numberOfReview
                                : 0
                              } Reviews`}
                          </span> */}
                                  {!(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN") || role_type.includes("ROLE_SEEKER_USER_ADMIN")) && (
                                    <>
                                      <a id="review-list-styling" className="m-b10"
                                        data-bs-toggle="modal"
                                        href="#review_list_popup"
                                        role="button">
                                        <StarRating
                                          rating={
                                            role_type.includes("ROLE_USER")
                                              ? userInfo?.applicantAverageRating
                                              : userInfo?.instituteAverageRating
                                          }
                                        />
                                        <span>
                                          {
                                            role_type.includes("ROLE_USER")
                                              ? " " + `${userInfo?.applicantNumberOfReviews} Reviews`
                                              : " " + `${userInfo?.instituteNumberOfReviews} Reviews`
                                          }
                                        </span>
                                      </a>
                                    </>
                                  )}
                                </div>
                              </div>
                            </li>
                          </>
                        }
                      </ul>
                    </div>
                  </div>
                </div>
                {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CanCompanyLogo;
