import { NavLink } from "react-router-dom";
import JobZImage from "../../../../../common/jobz-img";
import { useEffect, useState } from "react";
import Toast from "../../../../../common/toast";
import SessionExpired from "../../../../../common/sessionExpired";
import { useSelector } from "react-redux";
import apiCall from "../../../../../../apicall";
import AwaitDoc from "../../../../../common/openstaff-icon/iconDocYellow.png";
import ApprovedDoc from "../../../../../common/openstaff-icon/iconDocGreen.png";
import RejectDoc from "../../../../../common/openstaff-icon/iconDocRed.png";
import TableLoader from "../../../../../common/tableLoader";
import ViewDocumentPopup from "./viewdocumentpopup";
import { loadScript } from "../../../../../../globals/constants";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../../globals/fomat-comapny-name";
import { logEvent_Analytics } from "../../../../../common/firebase/firebase-analytics-service";

function JobSeekerDocuments() {
  const { TOKEN, userInfo } = useSelector((state) => state.menu);
  const isEnabledBackgroundForm = process.env.REACT_APP_ENABLED_BACKGROUNDFORM === "true";

  const [hoursCount, setHoursCount] = useState(0);
  const [viewDocId, setViewDocId] = useState("");
  const [documentType, setDocumentType] = useState("document");
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [backgroundScreenFormData, setBackgroundScreenFormData] = useState([]);
  const [certificateList, setCertificateList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCommonDocListLoading, setIsCommonDocListLoading] = useState(false);
  const [isProfessionDocLoading, setIsProfessionDocLoading] = useState(false);
  const [professionDocList, setProfessionDocList] = useState([]);
  // const [professionList, setProfessionList] = useState([]);
  const [commonDocList, setCommonDocList] = useState([]);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [sessionExpired, setSessionExpired] = useState(false);

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };

  const renderAvatar = (item) => {
    if (item.documentStatus === "approved") {
      return (
        <img style={{ height: "120px" }} src={ApprovedDoc} alt="approved" />
      );
    } else if (item.documentStatus === "waitingForReview") {
      return <img style={{ height: "120px" }} src={AwaitDoc} alt="awating" />;
    } else if (item.documentStatus === "rejected") {
      return <img style={{ height: "120px" }} src={RejectDoc} alt="rejected" />;
    } else {
      return <img style={{ height: "120px" }} src={RejectDoc} alt="rejected" />;
    }
  };

  const openDocumentCertificateViewPopup = (item) => {
    console.log("openDocumentCertificateViewPopup",item?.documentType)
    if (
      item?.documentType !== "TrainingCertificates" && item?.documentType !== "BackgroundCheckForm"
    ) {
      setDocumentType("document");
    } else if (item?.documentType === "TrainingCertificates") {
      setDocumentType("certificate");
    } else if (item?.documentType === "BackgroundCheckForm") {
      setDocumentType("background");
    }
    setViewDocId(item);
  };

  const displayBackgroundFromData = (data) => {
    console.log("data---------",data)
    if (data?.id) {
      setDocumentTypeList((prevList) => [
        ...prevList,
        {
          id: data?.id,
          applicationStatus: data?.applicationDocumentStatus,
          createdDate: data?.createdDate,
          documentStatus: data?.applicationDocumentStatus,
          documentType: "BackgroundCheckForm",
        },
      ]);
    } else {
      setDocumentTypeList((prevList) => [
        ...prevList,
        {
          id: "7bc8d735-b108-4d29-9cb2-dcadc93d4b44",
          applicationStatus: "rejected",
          createdDate: "2024-04-31T03:54:47.000+0000",
          documentStatus: "rejected",
          documentType: "BackgroundCheckForm",
        },
      ]);
    }
  };

  const displayCertificateData = (data) => {
    console.log("data---------",data)

    if (data?.length > 0) {
      setDocumentTypeList((prevList) => [
        ...prevList,
        {
          id: data[0]?.id,
          applicationStatus: data[0]?.applicationStatus,
          createdDate: data[0]?.createdDate,
          documentStatus: data[0]?.documentStatus,
          documentType: "TrainingCertificates",
        },
      ]);
    } else {
      setDocumentTypeList((prevList) => [
        ...prevList,
        {
          id: "7bc8d735-b108-4d29-9cb2-dcadc93d4b22",
          applicationStatus: "rejected",
          createdDate: "2024-04-31T03:54:47.000+0000",
          documentStatus: "rejected",
          documentType: "TrainingCertificates",
        },
      ]);
    }
  };

  const getDocuments = async () => {
    try {
      setIsLoading(true);
      const documentResponse = await apiCall("documents", "GET", null, TOKEN);

      console.log("documentResponse----", documentResponse);
      if (documentResponse?.status === 200) {
        setIsLoading(false);
        setDocumentTypeList((prevList) => [
          ...prevList,
          ...documentResponse?.data?.content,
        ]);
      } else if (documentResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!");
      } else {
        // displayErrMsg("Something went wrong!");
        // displayErrMsg(documentResponse?.response?.data?.message)
      }
    } catch (err) {
      console.log("setSessionExpired--", err);
      // displayErrMsg("Something went wrong!");
    }
  };

  const getCertificates = async () => {
    try {
      setIsLoading(true);
      const certificateResponse = await apiCall(
        "certificates",
        "GET",
        null,
        TOKEN
      );

      console.log("certificateResponse----", certificateResponse);
      if (certificateResponse?.status === 200) {
        setIsLoading(false);
        setCertificateList(certificateResponse?.data?.content);
        displayCertificateData(certificateResponse?.data?.content);
        const totalHours = certificateResponse?.data?.content.reduce(
          (total, item) => total + (item.numberOfHours || 0),
          0
        );
        console.log("totalHours-----", totalHours);
        setHoursCount(totalHours);
      } else if (certificateResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!");
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(certificateResponse?.response?.data?.message)
      }
    } catch (err) {
      console.log("setSessionExpired--", err);
      displayErrMsg("Something went wrong!");
    }
  };

  const getBackgroundForms = async () => {
    try {
      setIsLoading(true);
      const backFormResponse = await apiCall(
        "applicants/background",
        "GET",
        null,
        TOKEN
      );

      console.log("backFormResponse----", backFormResponse);
      if (backFormResponse?.status === 200) {
        setIsLoading(false);
        setBackgroundScreenFormData(backFormResponse?.data);
        displayBackgroundFromData(backFormResponse?.data);
      } else if (backFormResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!");
      } else {
        // displayErrMsg("Something went wrong!");
        setIsLoading(false);
        displayBackgroundFromData({
          documentType: "BackgroundCheckForm",
        });
      }
    } catch (err) {
      console.log("setSessionExpired--", err);
      displayErrMsg("Something went wrong!");
      displayBackgroundFromData({
        documentType: "BackgroundCheckForm",
      });
    }
  };

  const getDocumentListAPi = async () => {
    setIsCommonDocListLoading(true)
    try {
    const getApiKeysResponse = await apiCall(
      `openstaff/configKey/commonDocs`,
      "GET",
      null,
      TOKEN
    );
    console.log("getApiKeysResponse", getApiKeysResponse?.data?.data);
    if (getApiKeysResponse?.status === 200) {
      setCommonDocList((prevList) => [
        ...prevList,
        ...getApiKeysResponse?.data?.data,
      ])
      const res = getApiKeysResponse?.data?.data?.map((val)=>{
        return {...val, documentType: val?.document }
      })
      setDocumentTypeList((prevList) => [
        ...prevList,
        ...res,
      ]);
    } else if (getApiKeysResponse?.response?.status == 401) {
      setSessionExpired(true);
      displayErrMsg("Your session has been expired!");
    } else {
      displayErrMsg(getApiKeysResponse?.response?.data?.message || "Network Error!")
    }
  } catch (err) {
    console.log("setSessionExpired--", err);
    displayErrMsg("Network Error!");
  }finally{
    setIsCommonDocListLoading(false);
  }
  };

  const getDocumentAccToProfession = async () => {
    setIsLoading(true);
    setIsProfessionDocLoading(true)
    try {
    const professionDocResponse = await apiCall(
      `documents/jobs/relatedJobDocuments`,
      "GET",
      null,
      TOKEN
    );
    console.log("professionDocResponse", professionDocResponse?.data);
    if (professionDocResponse?.status === 200) {
      setProfessionDocList(professionDocResponse?.data);
      setDocumentTypeList((prevList) => [
        ...prevList,
        ...professionDocResponse?.data,
      ]);
      const res = professionDocResponse?.data?.map((val)=>{
        return {...val, document: val?.documentType, title: val?.documentName }
      })
      setCommonDocList((prevList) => [
        ...prevList,
        ...res,
      ]);

    } else if (professionDocResponse?.data?.length === 0) {
      setProfessionDocList([])
    }  else if (professionDocResponse?.response?.status == 401) {
      setSessionExpired(true);
      displayErrMsg("Your session has been expired!");
    } else {
      displayErrMsg(professionDocResponse?.response?.data?.message)
    }
  } catch (err) {
    console.log("setSessionExpired--", err);
    displayErrMsg("Network Error!");
  }finally{
    setIsProfessionDocLoading(false);
  }
  };


  const getDocumentsList = async () => {
    await getDocumentAccToProfession(); 
    await getDocumentListAPi();
    await getDocuments();
    await getCertificates();
    // if(isEnabledBackgroundForm){
      await getBackgroundForms();
    // }
    // await getAllDocAccToProfession();
  };


  const getDocumentTitle = (documentType) => {
    const document = commonDocList.find((doc) => {
      console.log(" sdfsdlkfjklsd",documentType,doc.document)
      return doc.document === documentType
  });
  console.log("getDocumentTitle-----",document,commonDocList)
    return document ? document.title : document?.documentName ? document?.documentName  : "Unknown Document"; // Fallback if documentType is not found
  };

  useEffect(() => {
    loadScript("js/custom.js");
  });



  useEffect(() => {
    getDocumentsList();
    logEvent_Analytics('document_web');
  }, []);

  return (
    <>
    <div>
        <Helmet>
          <title>{`Document Management | Manage Your Files | Upload Documents | ${formatCompanyName()}`}</title>
          <meta name="description" content="Welcome to the Documents Page! This section allows you to manage all your important files and documents in one convenient location. Here, you can easily upload, view, and organize your documents related to your account or applications." />
          <meta name="keywords" content="documents" />
        </Helmet>
      </div>
      <div className="section-full p-t120  p-b90 site-bg-white">
        <div className="twm-right-section-panel candidate-save-job container">
          {/*Filter Short By*/}
          {/* <div className="product-filter-wrap d-flex justify-content-between align-items-center"> */}
            {/* <span className="woocommerce-result-count-left">Documents</span> */}
          {/* </div> */}
          <div class="panel-heading wt-panel-heading p-a20 mb-4">
            <h3 class="wt-title">Documents</h3>
          </div>
          <div className="twm-cv-manager-list-wrap">
            {console.log("documentTypeList=--=-=-=-", documentTypeList)}
            <ul>
              {/* {!isLoading ? (
              documentTypeList?.length > 0 ? (
                Array.from(
                  new Set(documentTypeList.map((item) => item?.documentType))
                )
                  .map((documentType) =>
                    documentTypeList.find(
                      (item) => item.documentType === documentType
                    )
                  )
                  ?.map((item, index) => ( */}
              {!isLoading ? (
                documentTypeList?.length > 0 ? (
                  Array.from(
                    new Map(
                      documentTypeList.map((item) => [item.documentType, item])
                    ).values()
                  )?.map((item, index) => (
                    <li key={item?.id}>
                      {console.log("item=======",item)}
                      <div className="twm-cv-manager-list-style1">
                        <div className="twm-media">
                          <div className="twm-media-pic">
                            {renderAvatar(item, index)}
                          </div>
                        </div>
                        <div className="twm-mid-content">
                          {/* <NavLink to="" className="twm-job-title">
                            {item?.documentType === "DriversLicense" && (
                              <h4>Drivers License</h4>
                            )}

                            {item?.documentType === "CPRCertificate" && (
                              <h4>CPR & First Aid</h4>
                            )}

                            {item?.documentType === "Training Certificates" && (
                              <>
                                <h4>Training Certificates</h4>
                                {item?.documentStatus === "approved" && (
                                  <p className="list-item-subtitle">
                                    Total Hours = {hoursCount}
                                  </p>
                                )}
                              </>
                            )}

                            {item?.documentType === "HighSchoolDiploma" && (
                              <h4>High School Diploma</h4>
                            )}

                            {item?.documentType === "Background Check Form" && (
                              <h4>Background Check Form</h4>
                            )}
                          </NavLink> */}
                          {console.log("item--------------",item)}
                          <NavLink to="" className="twm-job-title">
                            <h4>{getDocumentTitle(item.documentType)}</h4>

                          {item?.documentType === "TrainingCertificates" && (
                            <>
                              {item?.documentStatus === "approved" && (
                                <p className="list-item-subtitle">
                                  Total Hours = {hoursCount}
                                </p>
                              )}
                            </>
                          )}
                        </NavLink>


                          <div className="twm-fot-content">
                            <div className="twm-view-button">
                              <a
                                className="twm-jobs-browse site-text-primary job-details custom-toltip"
                                data-bs-toggle="modal"
                                href={`${(documentType === "document" || documentType === "background")
                                    ? "#view_app_doc"
                                    : documentType === "certificate"
                                      ? "#view_app_certificate"
                                      : ""
                                  }`}
                                role="button"
                                onClick={() =>
                                  openDocumentCertificateViewPopup(item)
                                }
                              >
                                <span
                                  style={{ fontSize: "20px" }}
                                  className="fas fa-eye"
                                ></span>
                                <span className="custom-toltip-block">
                                  View/Upload Doc
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  "No results found."
                )
              ) : (
                <TableLoader />
              )}
            </ul>
          </div>
        </div>
      </div>
      <ViewDocumentPopup
        viewDocId={viewDocId}
        documentType={documentType}
        certificateList={certificateList}
        backgroundScreenFormData={backgroundScreenFormData}
        getCertificates={getCertificates}
        getDocuments={getDocuments}
        getBackgroundForms={getBackgroundForms}
      />
      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default JobSeekerDocuments;
