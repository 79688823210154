import apiCall from "../../apicall";
import { setUserLogo } from "../../store/reducers/menu";

export const fetchuserLogo = async (dispatch, token) => {
    try {
      const response = await apiCall(`avatar`, "GET", null, token, false, true);
      if (response.status === 200) {
        const blobResponse = await response.data;
        const reader = new FileReader();
        reader.readAsDataURL(blobResponse);
        reader.onloadend = () => {
          const imageResponse = reader.result;
          if (imageResponse === 'data:text/xml;base64,') {
            return null;
          } else {
            dispatch(setUserLogo(imageResponse))
            return null;
          }
        };
      } else {
        console.error("Error fetching image:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  };