import moment from "moment";
import { useSelector } from "react-redux";

function ExportCSV({ jobListData }) {
    const { role_type } =
        useSelector((state) => state.menu);

    const ConvertInto_Hrs = (hrs) => {
        var num = hrs
        var str = num.toString();
        var numarray = str.split('.');
        var a = new Array();
        a = numarray;
        let minuteVal = Number(a[1]) / 60
        let strFnl = a[0] + (minuteVal.toString()).substring(1)
        return Number(strFnl).toFixed(2);
    }

    const downloadCSV = () => {
        if (!jobListData || jobListData.length === 0) {
            alert("No data to export!");
            return;
        }

        // Specify the columns to include in the CSV
        const filteredData = jobListData.map((item) => {
            // Format start and end dates separately
            const formattedStartDate = moment(item?.startTime, "YYYY-MM-DD HH:mm:ss")
                .local()
                .format("MM/DD/YYYY hh:mm A");
            const formattedEndDate = moment(item?.endTime, "YYYY-MM-DD HH:mm:ss")
                .local()
                .format("MM/DD/YYYY hh:mm A");

            // Handle multi-day job formatting
            const displayStartDate = item?.multipleDayJob
                ? `${moment(item?.startTime, "YYYY-MM-DD HH:mm:ss")
                    .local()
                    .format("MM/DD/YYYY")} ${item?.timeZoneId}`
                : `${formattedStartDate} ${item?.timeZoneId}`;

            const displayEndDate = item?.multipleDayJob
                ? `${moment(item?.endTime, "YYYY-MM-DD HH:mm:ss")
                    .local()
                    .format("MM/DD/YYYY")} ${item?.timeZoneId}`
                : `${formattedEndDate} ${item?.timeZoneId}`;

            return {
                "Job Title": item?.jobTitle,
                "Job Position": item?.jobPosition,
                "Start Date": displayStartDate,
                "End Date": displayEndDate,
                "Location": item?.locationName,
                "Customer Name": item?.contactPerson,
                "Worker Name": `${item?.hireFirstName} ${item?.hireLastName}`,
                "Logged Hours": item?.totalWorkedHours ? ConvertInto_Hrs(item?.totalWorkedHours) : "0",
                "Total Hours": item?.totalHours ? (item?.multipleDayJob ? item?.totalHours : ConvertInto_Hrs(item?.totalHours)) : "0",
            };
        });

        // Construct CSV header
        const csvHeaders = Object.keys(filteredData[0]).join(",");

        // Construct CSV rows
        const csvRows = filteredData.map((row) =>
            Object.values(row)
                .map((value) => `"${value}"`) // Quote each value
                .join(",")
        );

        // Combine headers and rows
        const csvContent = [csvHeaders, ...csvRows].join("\n");

        // Get current date in MM-DD-YYYY format
        const currentDate = moment().format("MM-DD-YYYY");

        // Set file name
        const fileName = `job_history_${currentDate}.csv`;

        // Download as CSV file
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            {(jobListData[0]?.jobStatus == "eCompleted" && role_type.includes("ROLE_INSTITUTION_ADMIN")) && (
                <div className="col-lg-4 col-md-4 col-sm-4" style={{ "display": "flex", "justifyContent": "flex-end" }}>
                    <button
                        className="site-button"
                        role="button"
                        onClick={downloadCSV}
                    >
                        <i class="fas fa-download"></i>
                        Export Job History
                    </button>
                </div>
            )}
        </>
    );
}

export default ExportCSV;