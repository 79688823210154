import { useState, useEffect } from "react";
import SectionJobsSidebar1 from "../../sections/jobs/sidebar/section-jobs-sidebar1";
import SectionEmpsGrid from "../../sections/employers/section-emps-grid";
import SectionRecordsFilter from "../../sections/common/section-records-filter";
import { loadScript } from "../../../../../globals/constants";
import apiCall from "../../../../../apicall";
import { useSelector } from "react-redux";
import SessionExpired from "../../../../common/sessionExpired";
import Toast from "../../../../common/toast";
import { useLocation } from "react-router-dom";
import JobZImage from "../../../../common/jobz-img";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import JobViewTabs from "../jobs/job-view-tabs";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

function EmployersGridPage() {
    const { role_type, TOKEN } = useSelector((state) => state.menu);
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [empListData, setEmpListData] = useState([]);
    const [searchLocationLatLng, setSearchLocationLatLng] = useState({
        latitude: location?.state?.lat,
        longitude: location?.state?.lng,
    });
    const [tableFilter, setTableFilter] = useState({
        sortType: "recent",
        sortDirection: "desc",
        searchKey: "",
    })
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [paginationData, setPaginationData] = useState({
        totalElements: 0,
        totalPages: 0,
    });
    const [sessionExpired, setSessionExpired] = useState(false);
    const [errSuccessMesg, setErrSuccessMsg] = useState({
        message: "",
        status: "error",
    });
    const _filterConfig = {
        prefix: "Showing",
        type: "results",
        isSorting: true,
        total: paginationData?.totalElements,
        showRange: false,
        showingUpto: "",
    };
    const handleRecordsPerPageChange = (event) => {
        setCurrentPage(1);
        setRowPerPage(event.target.value);
    };
    const displayErrMsg = (errMsg) => {
        setErrSuccessMsg({
            message: errMsg,
            status: false,
        });
        setIsLoading(false);
        setEmpListData([]);
        setPaginationData({
            totalElements: 0,
            totalPages: 0,
        });
    };
    const fetchempList = async () => {
        let data = null;
        console.log("fetchempList-----", role_type, tableFilter?.searchKey);
        const api = `${(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN"))
            ? `openstaff/institutions`
            : ``
            }?sortBy=${tableFilter?.sortType}&sortDirection=${tableFilter?.sortDirection
            }&offset=0&page=${currentPage - 1}&size=${rowPerPage}&searchkey=${tableFilter?.searchKey
            }`;
        try {
            setIsLoading(true);
            const result = await apiCall(api, "GET", data, TOKEN);
            if (result?.data?.content?.length > 0) {
                setIsLoading(false);
                setSessionExpired(false);
                setEmpListData(result.data.content);
                setPaginationData({
                    totalElements: result.data.totalElements,
                    totalPages: result.data.totalPages,
                });
            } else if (result?.data?.content?.length === 0 && result?.status == 200) {
                setSessionExpired(false);
                // displayErrMsg("No results found.!");
                setIsLoading(false);
                setEmpListData([]);
                setPaginationData({
                    totalElements: 0,
                    totalPages: 0,
                });
            } else if (result?.response?.status == 401) {
                setSessionExpired(true);
                displayErrMsg("Your session has been expired!");
            } else {
                setSessionExpired(false);
                // displayErrMsg("Something went wrong!");
                displayErrMsg(result?.response?.data?.message)
            }
            console.log("user list result", result);
        } catch (error) {
            setSessionExpired(false);
            displayErrMsg("Something went wrong!");
            console.error("Error fetching user data:", error);
        }
    };

    useEffect(() => {
        loadScript("js/custom.js")
    })
    useEffect(() => {
        fetchempList();
    }, [currentPage, tableFilter, rowPerPage]);

    useEffect(() => {
        logEvent_Analytics('employers_grid_web');
    }, [])

    return (
        <>
            <div className="section-full p-t120  p-b90 site-bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 rightSidebar">
                            <SectionJobsSidebar1
                                setTableFilter={setTableFilter}
                                tableFilter={tableFilter}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                        <div className="col-lg-8 col-md-12">
                            {/*Filter Short By*/}
                            <div class="panel-heading wt-panel-heading p-a20 mb-4">
                                <h3 class="wt-title mt-4">Companies</h3>
                            </div>
                            <JobViewTabs />
                            <SectionRecordsFilter
                                _config={_filterConfig}
                                rowPerPage={rowPerPage}
                                handleRecordsPerPageChange={handleRecordsPerPageChange}
                                setTableFilter={setTableFilter}
                                tableFilter={tableFilter}
                                setCurrentPage={setCurrentPage}
                            />
                            <SectionEmpsGrid
                                isLoading={isLoading}
                                empListData={empListData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={paginationData.totalPages}
                            />

                            {/* <div className="twm-employer-list-wrap">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-employer-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <JobZImage src="images/jobs-company/pic1.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                                    <h4>Herbal Ltd</h4>
                                                </NavLink>
                                                <p className="twm-job-address">1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">Accountancy</NavLink>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-vacancies"><span>25</span>Vacancies</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-employer-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <JobZImage src="images/jobs-company/pic2.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                                    <h4>Artistre Studio PVT Ltd</h4>
                                                </NavLink>
                                                <p className="twm-job-address">1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">IT Contractor</NavLink>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-vacancies"><span>30</span>Vacancies</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-employer-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <JobZImage src="images/jobs-company/pic3.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                                    <h4 className="twm-job-title">Wins Developers</h4>
                                                </NavLink>
                                                <p className="twm-job-address">1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">Banking</NavLink>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-vacancies"><span>32</span>Vacancies</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-employer-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <JobZImage src="images/jobs-company/pic4.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                                    <h4 className="twm-job-title">Thewebmax PVT Ltd</h4>
                                                </NavLink>
                                                <p className="twm-job-address">1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">Digital &amp; Creative</NavLink>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-vacancies"><span>38</span>Vacancies</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-employer-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <JobZImage src="images/jobs-company/pic5.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                                    <h4 className="twm-job-title">Robo Tech</h4>
                                                </NavLink>
                                                <p className="twm-job-address">1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">Sales &amp; Marketing</NavLink>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-vacancies"><span>40</span>Vacancies</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-employer-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <JobZImage src="images/jobs-company/pic1.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                                    <h4>Galaxy IT Solution</h4>
                                                </NavLink>
                                                <p className="twm-job-address">1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">Leisure &amp; Tourismm</NavLink>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-vacancies"><span>38</span>Vacancies</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-employer-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <JobZImage src="images/jobs-company/pic2.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                                    <h4>Coderbotics solutions</h4>
                                                </NavLink>
                                                <p className="twm-job-address">1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">Consultancy</NavLink>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-vacancies"><span>35</span>Vacancies</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-employer-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <JobZImage src="images/jobs-company/pic3.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                                    <h4 className="twm-job-title">Microsoft solution</h4>
                                                </NavLink>
                                                <p className="twm-job-address">1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">Technologies</NavLink>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-vacancies"><span>65</span>Vacancies</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-employer-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <JobZImage src="images/jobs-company/pic4.jpg" alt="#" />
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                                    <h4 className="twm-job-title">Dot Circle PVT Ltd</h4>
                                                </NavLink>
                                                <p className="twm-job-address">1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                                <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">Sales &amp; Marketing</NavLink>
                                            </div>
                                            <div className="twm-right-content">
                                                <div className="twm-jobs-vacancies"><span>50</span>Vacancies</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <SectionPagination /> */}
                        </div>
                    </div>
                </div>
            </div>
            {sessionExpired && <SessionExpired />}
            {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
        </>
    )
}

export default EmployersGridPage;