import { useState, useEffect, useRef } from "react";
import SectionJobsSidebar1 from "../../sections/jobs/sidebar/section-jobs-sidebar1";
import SectionJobsList from "../../sections/jobs/section-jobs-list";
import SectionRecordsFilter from "../../sections/common/section-records-filter";
import { loadScript } from "../../../../../globals/constants";
import apiCall from "../../../../../apicall";
import { useDispatch, useSelector } from "react-redux";
import SessionExpired from "../../../../common/sessionExpired";
import Toast from "../../../../common/toast";
import { NavLink, useLocation } from "react-router-dom";
import JobViewTabs from "./job-view-tabs";
import { navigateVisitedPage } from "../../../../../store/reducers/menu";
import moment from "moment";
import ExportCSV from "./export-csv";

function JobsListPageApp() {
  const { role_type , TOKEN, isAddressSelected, selectedCategoryType } =
    useSelector((state) => state.menu);
    console.log("skdfjsadklfjlsdakjf",isAddressSelected,role_type)
  const location = useLocation();
  const prevUrlRef = useRef();
  const dispatch = useDispatch()
  const currentUrl = location.pathname;
  const [isLoading, setIsLoading] = useState(false);
  const [jobListData, setJobListData] = useState([]);
  const [sliderValue, setSliderValue] = useState(() => {
    const savedValue = localStorage.getItem("sliderValue");
    return savedValue !== null ? JSON.parse(savedValue) : 100;
  });
  const [seekerTotalAmount, setSeekerTotalAmount] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [locationFilterValue, setLocationFilterValue] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    status: "All",
    applicant: "",
    customer: "",
    fromDate: "",
    toDate: "",
    sortType: "recentJobs",
    sortDirection: "desc",
    searchKey: "",
    jobType: "",
    applicant: "",
    customer: "",
    locationName: isAddressSelected?.locationName
      ? isAddressSelected?.locationName
      : "",
    lat: isAddressSelected?.latlng ? isAddressSelected?.latlng?.lat : "",
    lng: isAddressSelected?.latlng ? isAddressSelected?.latlng?.lng : "",
    jobStatus:
    (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN"))
        ? "All"
        : role_type.includes("ROLE_USER") || role_type.includes("ROLE_INSTITUTION_ADMIN")
        ? "eOpen"
        : "All",
    categoryType: selectedCategoryType ? selectedCategoryType : "All",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [paginationData, setPaginationData] = useState({
    totalElements: 0,
    totalPages: 0,
  });
  const [sessionExpired, setSessionExpired] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const _filterConfig = {
    prefix: "Showing",
    type: "results",
    isSorting: true,
    total: paginationData?.totalElements,
    showRange: false,
    showingUpto: "",
  };

  const handleRecordsPerPageChange = (event) => {
    setCurrentPage(1);
    setRowPerPage(event.target.value);
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setJobListData([]);
    setPaginationData({
      totalElements: 0,
      totalPages: 0,
    });
  };

  const fetchjobList = async () => {
    let data = null;
    const apiBase = `${
      (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN"))
        ? `openstaff/jobs/AllJobs?status=${tableFilter?.jobStatus}&applicant=${tableFilter?.applicant}&customer=${tableFilter?.customer}&fromDate=${tableFilter?.fromDate}&toDate=${tableFilter?.toDate}`
        : role_type.includes("ROLE_USER")
        ? currentUrl.includes("/search-job")
          ? `jobs/open?jobStatus=eOpen`
          : currentUrl.includes("/ongoing-job")
          ? `jobs/applied2?jobEntity.jobStatus=eInProgress&jobEntity.jobStatus=eCancelled&hired=true`
          : currentUrl.includes("/history-job")
          ? `jobs/applied?jobStatus=eCompleted&jobStatus=eClosed`
          : tableFilter?.jobStatus === "myJobs"
          ? null // We will handle this case separately
          : ""
        : role_type.includes("ROLE_INSTITUTION_ADMIN")
        ? currentUrl.includes("/search-job")
          ? `institutions/jobs?jobStatus=eOpen`
          : currentUrl.includes("/schedule-job")
          ? `institutions/jobs/scheduled`
          : currentUrl.includes("/history-job")
          ? `institutions/jobs?jobStatus=eCompleted&jobStatus=eClosed`
          : ""
        : `config/jobs/open`
    }`;

    const commonParams =
      ((currentUrl.includes("/ongoing-job") ||
        currentUrl.includes("/applied-job")) &&
        role_type.includes("ROLE_USER")) ||
        (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN"))
        ? `&latitude=${tableFilter?.lat}&longitude=${
            tableFilter?.lng
          }&category=${tableFilter?.categoryType}&sortBy=${
            tableFilter?.sortType
          }&sortDirection=${tableFilter?.sortDirection}&offset=0&page=${
            currentPage - 1
          }&size=${rowPerPage}&searchKey=${tableFilter?.searchKey}`
        : `${(role_type?.length === 0 || (currentUrl.includes("/schedule-job") && role_type.includes("ROLE_INSTITUTION_ADMIN"))) ? "?" : "&"}latitude=${
            tableFilter?.lat
          }&longitude=${tableFilter?.lng}&category=${
            tableFilter?.categoryType
          }&sortBy=${tableFilter?.sortType}&sortDirection=${
            tableFilter?.sortDirection
          }&offset=0&page=${currentPage - 1}&size=${rowPerPage}&searchKey=${
            tableFilter?.searchKey
          }&distance=${role_type?.length === 0 ? "10000" : sliderValue}`;

    const fetchJobs = async (api) => {
      return apiCall(api + commonParams, "GET", data, TOKEN);
    };

    try {
      setIsLoading(true);
      let result;

      if (currentUrl.includes("/applied-job")) {
        const api1 = `jobs/applied2?jobEntity.jobStatus=eOpen`;
        const api2 = `jobs/applied2?jobEntity.jobStatus=eAccepted&hired=true`;
        // const api3 = `jobs/applied2?jobEntity.jobStatus=eAccepted&hired=false`;

        // const [result1, result2, result3] = await Promise.all([
          const [result1, result2] = await Promise.all([
            fetchJobs(api1),
            fetchJobs(api2),
          // fetchJobs(api3),
        ]);

        console.log("result1------------",result1,result2)

        // const mergedContent = [
        //   ...result1.data.content,
        //   ...result2.data.content,
        //   ...result3.data.content,
        // ];

        // const totalElements = result1.data.totalElements + result2.data.totalElements + result3.data.totalElements;
        // const totalPages = Math.ceil(totalElements / rowPerPage);

        result = {
          data: {
            content: [...result1.data.content, ...result2.data.content],
            totalElements:
              result1.data.totalElements + result2.data.totalElements,
            totalPages: Math.max(
              result1.data.totalPages,
              result2.data.totalPages
            ),
          },
        };
        
      } else {
        result = await fetchJobs(apiBase);
      }
      if (result?.data?.content?.length > 0) {
        setIsLoading(false);
        setSessionExpired(false);
        setJobListData(result.data.content);
        setPaginationData({
          totalElements: result.data.totalElements,
          totalPages: result.data.totalPages,
        });
      } else if (result?.data?.content?.length === 0) {
        setSessionExpired(false);
        // displayErrMsg("No results found.!");
        setIsLoading(false);
        setJobListData([]);
        setPaginationData({
          totalElements: 0,
          totalPages: 0,
        });
      } else if (result?.response?.status == 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!");
      }else if(result?.response?.data?.message?.includes("fromIndex")){
        setSessionExpired(false);
        setIsLoading(false);
      } else {
        setSessionExpired(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(result?.response?.data?.message)
      }
    } catch (error) {
      setSessionExpired(false);
      displayErrMsg("Something went wrong!");
      console.error("Error fetching user data:", error);
    }
  };

  const getApplicantList = async () => {
    const applicantResponse = await apiCall(
      "users?offset=&pageNumber=&size=500",
      "GET",
      null,
      TOKEN
    );
    if (applicantResponse?.data?.content?.length > 0) {
      setApplicantList(applicantResponse.data.content);
    }
  };
  const getTotalAmount = async () => {
    let totalAmount = 0
    if(role_type.includes('ROLE_USER') || role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")){
      totalAmount = await apiCall("totalAmount", "GET", null, TOKEN);
    }else if(role_type.includes("ROLE_INSTITUTION_ADMIN")){
      totalAmount = await apiCall("posterTotalAmount", "GET", null, TOKEN);
    }
    if (totalAmount?.data > 0) {
      setSeekerTotalAmount(totalAmount.data);
    }else{
      setSeekerTotalAmount(0);
    }
  };
  const getCustomersList = async () => {
    const customerResponse = await apiCall(
      "openstaff/institutions?offset=&pageNumber=&size=500",
      "GET",
      null,
      TOKEN
    );
    if (customerResponse?.data?.content?.length > 0) {
      setCustomerList(customerResponse.data.content);
    }
  };

  useEffect(() => {
    loadScript("js/custom.js");
    document.querySelector(".modal-backdrop")?.remove();
  });

  useEffect(() => {
    dispatch(navigateVisitedPage(null));
    if (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")) {
      getApplicantList();
      getCustomersList();
    }
  }, []);

  useEffect(() => {
    dispatch(navigateVisitedPage(null));
    if ((role_type.includes('ROLE_INSTITUTION_ADMIN') || role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_USER")) && (location?.pathname?.includes("/history-job") || location?.pathname?.includes("/history-job/")) ){
      getTotalAmount();
    }
  }, [location?.pathname]);
  useEffect(() => {
    console.log("Initialized sliderValue:", sliderValue);
  }, [sliderValue]);

  useEffect(() => {
    fetchjobList();
  }, [currentPage, tableFilter, rowPerPage, isAddressSelected, currentUrl]);

  useEffect(() => {
    if (prevUrlRef.current && prevUrlRef.current !== currentUrl) {
      setCurrentPage(1); // Reset to first page when URL changes
    }
    prevUrlRef.current = currentUrl; // Update previous URL reference
  }, [currentUrl]);

  useEffect(() => {
    const handleStorageChange = () => {
      const savedValue = localStorage.getItem("sliderValue");
      if (savedValue !== null) {
        setSliderValue(JSON.parse(savedValue));
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);


  return (
    <>
      <div className="section-full p-t120  p-b90 site-bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 rightSidebar">
              <SectionJobsSidebar1
                setTableFilter={setTableFilter}
                tableFilter={tableFilter}
                setCurrentPage={setCurrentPage}
                applicantList={applicantList}
                customerList={customerList}
              />
            </div>
            <div className="col-lg-8 col-md-12">
              {/*Filter Short By*/}
              <div class="col-lg-12 col-md-12 col-sm-12 panel-heading wt-panel-heading p-a20 d-flex">
                <div className="col-lg-8 col-md-8 col-sm-8">
                  <h3 className="wt-title">
                    {currentUrl === '/search-job' ? role_type.includes("ROLE_INSTITUTION_ADMIN") ? 'Open Jobs' : 'Search Jobs' : currentUrl === '/applied-job' ? 'Applied Jobs' : currentUrl === '/ongoing-job' ? 'Ongoing Jobs' : currentUrl === '/schedule-job' ? 'Scheduled Jobs' : currentUrl === '/history-job' ? 'History Jobs' : ''}
                  </h3>
                </div>
                <ExportCSV jobListData={jobListData} />
              </div>
              <JobViewTabs />
              <SectionRecordsFilter
                _config={_filterConfig}
                rowPerPage={rowPerPage}
                handleRecordsPerPageChange={handleRecordsPerPageChange}
                setTableFilter={setTableFilter}
                tableFilter={tableFilter}
                setCurrentPage={setCurrentPage}
              />
              <SectionJobsList
                isLoading={isLoading}
                jobListData={jobListData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={paginationData.totalPages}
                fetchjobList={fetchjobList}
                seekerTotalAmount={seekerTotalAmount}
              />
            </div>
          </div>
        </div>
      </div>
      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default JobsListPageApp;
