import { Route, Routes } from "react-router-dom";
import { publicUser, candidate } from "../globals/route-names";
import Home1Page from "../app/pannels/public-user/components/home/index";
import Home2Page from "../app/pannels/public-user/components/home/index2";
import Home3Page from "../app/pannels/public-user/components/home/index3";
import Home4Page from "../app/pannels/public-user/components/home/index4";
import Home5Page from "../app/pannels/public-user/components/home/index5";
import Home6Page from "../app/pannels/public-user/components/home/index6";
import Home7Page from "../app/pannels/public-user/components/home/index7";
import Home8Page from "../app/pannels/public-user/components/home/index8";
import Home9Page from "../app/pannels/public-user/components/home/index9";
import Home10Page from "../app/pannels/public-user/components/home/index10";
import Home11Page from "../app/pannels/public-user/components/home/index11";
import Home12Page from "../app/pannels/public-user/components/home/index12";
import Home13Page from "../app/pannels/public-user/components/home/index13";
import Home14Page from "../app/pannels/public-user/components/home/index14";
import Home15Page from "../app/pannels/public-user/components/home/index15";
import Home16Page from "../app/pannels/public-user/components/home/index16";
import Home17Page from "../app/pannels/public-user/components/home/index17";
import Home18Page from "../app/pannels/public-user/components/home/index18";
import JobsListCalenderPage from "../app/pannels/public-user/components/jobs/jobs-calender-view";
import JobsGridPage from "../app/pannels/public-user/components/jobs/jobs-grid";
import JobsGridMapPage from "../app/pannels/public-user/components/jobs/jobs-grid-map";
import JobsListPage from "../app/pannels/public-user/components/jobs/jobs-list";
import JobDetail1Page from "../app/pannels/public-user/components/jobs/job-detail1";
import JobDetail2Page from "../app/pannels/public-user/components/jobs/job-detail2";
import ApplyJobPage from "../app/pannels/public-user/components/jobs/apply-job";
import PostJobPage from "../app/pannels/public-user/components/jobs/post-job";
import JobNotFound from "../app/pannels/public-user/components/jobs/jobs-not-tound";
import DeleteAccount from "../app/pannels/public-user/sections/common/delete-account";
import SearchJob from "../app/pannels/public-user/components/jobs/search-jobs";
import AppliedJobs from "../app/pannels/public-user/components/jobs/applied-jobs";
import OnGoingJobs from "../app/pannels/public-user/components/jobs/ongoing-jobs";
import ScheduleJobs from "../app/pannels/public-user/components/jobs/schedule-jobs";
import HistoryJobs from "../app/pannels/public-user/components/jobs/history-jobs";
import EmployersGridPage from "../app/pannels/public-user/components/employers/emp-grid";
import EmployersListPage from "../app/pannels/public-user/components/employers/emp-list";
import EmployersDetail1Page from "../app/pannels/public-user/components/employers/emp-detail1";
import EmployersDetail2Page from "../app/pannels/public-user/components/employers/emp-detail2";

import AboutUsPage from "../app/pannels/public-user/components/pages/about-us";
import PricingPage from "../app/pannels/public-user/components/pages/pricing";
import Error404Page from "../app/pannels/public-user/components/pages/error404";
import FaqPage from "../app/pannels/public-user/components/pages/faq";
import ContactUsPage from "../app/pannels/public-user/components/pages/contact-us";
import UnderMaintenancePage from "../app/pannels/public-user/components/pages/under-maintenance";
import ComingSoonPage from "../app/pannels/public-user/components/pages/coming-soon";
import LoginPage from "../app/pannels/public-user/components/pages/login";
import AfterLoginPage from "../app/pannels/public-user/components/pages/after-login";
import IconsPage from "../app/pannels/public-user/components/pages/icons";

import CandidateGridPage from "../app/pannels/public-user/components/candidates/can-grid";
import CandidateListPage from "../app/pannels/public-user/components/candidates/can-list";
import CandidateDetail1Page from "../app/pannels/public-user/components/candidates/can-detail1";
import CandidateDetail2Page from "../app/pannels/public-user/components/candidates/can-detail2";

import BlogGrid1Page from "../app/pannels/public-user/components/blogs/blogs-grid1";
import BlogGrid2Page from "../app/pannels/public-user/components/blogs/blogs-grid2";
import BlogGrid3Page from "../app/pannels/public-user/components/blogs/blogs-grid3";
import BlogListPage from "../app/pannels/public-user/components/blogs/blogs-list";
import BlogDetailPage from "../app/pannels/public-user/components/blogs/blog-detail";
import AdminUsersList from "../app/pannels/public-user/components/admin/users/usersList";
import AdminUsersGrid from "../app/pannels/public-user/components/admin/users/usersGrid";
import ProtectedRoute from "./protectedRoute";
import JobSeekerDocuments from "../app/pannels/public-user/components/candidates/documents/jobseekerDocument";
import { useSelector } from "react-redux";
import SessionExpire from "../app/common/session_expired";
import ActivateUser from "../app/pannels/public-user/components/pages/activateUser";
import ResetPassword from "../app/pannels/public-user/components/pages/resetPassword";
import SignUpPage from "../app/pannels/public-user/components/pages/SignUpPage";
import JobsListPageApp from "../app/pannels/public-user/components/jobs/jobs-lists-app";
import CanChatPage from "../app/pannels/candidate/components/can-chat";
import CanPaymentDetails from "../app/pannels/candidate/components/can-payment-details";
import PrivacyPolicy from "../app/pannels/public-user/sections/common/privacy-policy";
import ContactUs from "../app/pannels/public-user/sections/common/contact-us";
import CanCompanyLogo from "../app/pannels/candidate/sections/profile/section-can-company-logo";
import ManageTeam from "../app/pannels/candidate/sections/applicants/manage-team";
import CanAddLocation from "../app/pannels/candidate/components/can-location";
import EmployeesPage from "../app/pannels/candidate/sections/applicants/employees/employees-page";
import TrustedWorkersPage from "../app/pannels/candidate/sections/applicants/trusted-workers/trusted-workers-page";
function PublicUserRoutes() {
  const { isAddressSelected, userInfo } = useSelector((state) => state.menu);
  const ROLE_INSTITUTION_ADMIN = ["ROLE_INSTITUTION_ADMIN"];
  const ROLE_USER = ["ROLE_USER"];
  const ROLE_OPENSTAFF_ADMIN = ["ROLE_OPENSTAFF_ADMIN"];
  const ROLE_SEEKER_USER_ADMIN = ["ROLE_SEEKER_USER_ADMIN"];
  const ROLE_OPENSTAFF_FINANCIAL_ADMIN = ["ROLE_OPENSTAFF_FINANCIAL_ADMIN"];
  const ROLE_INSTITUTION_POSTER_ADMIN = ["ROLE_INSTITUTION_POSTER_ADMIN"];
  const ROLE_ALL = [
    "ROLE_OPENSTAFF_ADMIN",
    "ROLE_USER",
    "ROLE_INSTITUTION_ADMIN",
  ];

  let updatedRoles = [...ROLE_ALL];

  console.log("isAddressSelected---", isAddressSelected);

  if (
    isAddressSelected?.locationName === "" ||
    isAddressSelected?.locationName
  ) {
    updatedRoles = [...ROLE_ALL, "IS_ADDRESS_SELECTED"];
  }

  return (
    <Routes>
      <Route path={publicUser.INITIAL} element={<Home1Page />} />
      <Route path={publicUser.HOME1} element={<Home1Page />} />
      {/* <Route path={publicUser.HOME2} element={<Home2Page />} />
      <Route path={publicUser.HOME3} element={<Home3Page />} />
      <Route path={publicUser.HOME4} element={<Home4Page />} />
      <Route path={publicUser.HOME5} element={<Home5Page />} />
      <Route path={publicUser.HOME6} element={<Home6Page />} />
      <Route path={publicUser.HOME7} element={<Home7Page />} />
      <Route path={publicUser.HOME8} element={<Home8Page />} />
      <Route path={publicUser.HOME9} element={<Home9Page />} />
      <Route path={publicUser.HOME10} element={<Home10Page />} />
      <Route path={publicUser.HOME11} element={<Home11Page />} />
      <Route path={publicUser.HOME12} element={<Home12Page />} />
      <Route path={publicUser.HOME13} element={<Home13Page />} />
      <Route path={publicUser.HOME14} element={<Home14Page />} />
      <Route path={publicUser.HOME15} element={<Home15Page />} />
      <Route path={publicUser.HOME16} element={<Home16Page />} />
      <Route path={publicUser.HOME17} element={<Home17Page />} />
      <Route path={publicUser.HOME18} element={<Home18Page />} /> */}

      {/* <Route path={publicUser.jobs.GRID} element={<JobsGridPage />} /> */}
      <Route
        path={publicUser.jobs.SEARCH_JOB}
        element={
          <ProtectedRoute allowedRoles={[
            ...ROLE_USER,
            ...ROLE_INSTITUTION_ADMIN
          ]}>
            {/* <SearchJob /> */}
            <JobsListPageApp />
          </ProtectedRoute>
        }
      />
      <Route
        path={publicUser.jobs.APPLIED_JOBS}
        element={
          <ProtectedRoute allowedRoles={ROLE_USER}>
            {/* <AppliedJobs /> */}
            <JobsListPageApp />
          </ProtectedRoute>
        }
      />
      <Route
        path={publicUser.jobs.ONGOING_JOB}
        element={
          <ProtectedRoute allowedRoles={ROLE_USER}>
            {/* <OnGoingJobs /> */}
            <JobsListPageApp />
          </ProtectedRoute>
        }
      />

      <Route
        path={publicUser.jobs.SCHEDULE_JOB}
        element={
          <ProtectedRoute allowedRoles={[
            ...ROLE_USER,
            ...ROLE_INSTITUTION_ADMIN
          ]}>
            {/* <ScheduleJobs /> */}
            <JobsListPageApp />
          </ProtectedRoute>
        }
      />
      <Route
        path={publicUser.jobs.HISTORY_JOBS}
        element={
          <ProtectedRoute allowedRoles={[
            ...ROLE_USER,
            ...ROLE_INSTITUTION_ADMIN
          ]}>
            {/* <HistoryJobs /> */}
            <JobsListPageApp />
          </ProtectedRoute>
        }
      />
      <Route
        path={publicUser.jobs.GRID}
        element={
          <ProtectedRoute allowedRoles={[
            "IS_ADDRESS_SELECTED",
            ...ROLE_ALL,
            ...ROLE_INSTITUTION_POSTER_ADMIN
            ]}>
            <JobsGridPage />
          </ProtectedRoute>
        }
      />
      {/* <Route path={publicUser.jobs.GRID_MAP} element={<JobsGridMapPage />} /> */}
      <Route
        path={publicUser.jobs.GRID_MAP}
        element={
          <ProtectedRoute allowedRoles={[
            "IS_ADDRESS_SELECTED",
            ...ROLE_ALL,
            ...ROLE_INSTITUTION_POSTER_ADMIN
            ]}>
            <JobsGridMapPage />
          </ProtectedRoute>
        }
      />
      {/* <Route path={publicUser.jobs.LIST} element={<JobsListPage />} /> */}
      <Route
        path={publicUser.jobs.LIST}
        element={
          <ProtectedRoute allowedRoles={[
            "IS_ADDRESS_SELECTED",
            ...ROLE_ALL,
            ...ROLE_INSTITUTION_POSTER_ADMIN
            ]}>
            <JobsListPage />
          </ProtectedRoute>
        }
      />
        <Route
        path={publicUser.jobs.CALENDER_VIEW}
        element={
          <ProtectedRoute allowedRoles={[
            "IS_ADDRESS_SELECTED",
            ...ROLE_ALL,
            ...ROLE_INSTITUTION_POSTER_ADMIN
            ]}>
            <JobsListCalenderPage />
          </ProtectedRoute>
        }
      />
      {/* <Route path={publicUser.jobs.DETAIL1} element={<JobDetail1Page />} /> */}
      <Route
        path={publicUser.jobs.DETAIL1 + "/:id"}
        element={<JobDetail1Page />}
      />
      {/* <Route path={publicUser.jobs.DETAIL2} element={<JobDetail2Page />} />
      <Route path={publicUser.jobs.APPLY} element={<ApplyJobPage />} /> */}
      <Route
        path={publicUser.jobs.POST}
        element={
          <ProtectedRoute allowedRoles={ROLE_INSTITUTION_ADMIN}>
            <PostJobPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={publicUser.employer.GRID}
        element={
          <ProtectedRoute allowedRoles={[
            ...ROLE_OPENSTAFF_ADMIN,
            ...ROLE_OPENSTAFF_FINANCIAL_ADMIN,
            ...ROLE_INSTITUTION_POSTER_ADMIN
          ]}>
            <EmployersGridPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={publicUser.employer.LIST}
        element={
          <ProtectedRoute allowedRoles={[
            ...ROLE_OPENSTAFF_ADMIN,
            ...ROLE_OPENSTAFF_FINANCIAL_ADMIN,
            ...ROLE_INSTITUTION_POSTER_ADMIN
          ]}>
            <EmployersListPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={publicUser.employer.DETAIL1}
        element={
          <ProtectedRoute allowedRoles={[
            ...ROLE_OPENSTAFF_ADMIN,
            ...ROLE_OPENSTAFF_FINANCIAL_ADMIN,
            ...ROLE_INSTITUTION_POSTER_ADMIN,
          ]}>
            <EmployersDetail1Page />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path={publicUser.employer.DETAIL2}
        element={<EmployersDetail2Page />}
      />
      <Route path={publicUser.pages.ABOUT} element={<AboutUsPage />} />
      <Route path={publicUser.pages.PRICING} element={<PricingPage />} />*/}
      <Route path={publicUser.pages.ERROR404} element={<Error404Page />} />
      {/* <Route path={publicUser.pages.FAQ} element={<FaqPage />} />  
      <Route path={publicUser.pages.CONTACT} element={<ContactUsPage />} />
      <Route
        path={publicUser.pages.MAINTENANCE}
        element={<UnderMaintenancePage />}
      />
      <Route path={publicUser.pages.ACTIVATE_USER} element={<ActivateUser />} />
      <Route
        path={publicUser.pages.RESET_PASSWORD}
        element={<ResetPassword />}
      />
      <Route path={publicUser.pages.COMING} element={<ComingSoonPage />} />
      <Route path={publicUser.pages.LOGIN} element={<LoginPage />} />
      <Route path={publicUser.pages.AFTER_LOGIN} element={<AfterLoginPage />} />
      <Route path={publicUser.pages.ICONS} element={<IconsPage />} /> */}
      <Route
        path={publicUser.candidate.GRID}
        element={
          <ProtectedRoute allowedRoles={[
            ...ROLE_OPENSTAFF_ADMIN,
            ...ROLE_SEEKER_USER_ADMIN
          ]}>
            <CandidateGridPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={publicUser.candidate.LIST}
        element={
          <ProtectedRoute allowedRoles={[
            ...ROLE_OPENSTAFF_ADMIN,
            ...ROLE_SEEKER_USER_ADMIN
          ]}>
            <CandidateListPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={publicUser.candidate.DETAIL1 + "/:id"}
        element={
          <ProtectedRoute allowedRoles={[
            ...ROLE_OPENSTAFF_ADMIN,
            ...ROLE_SEEKER_USER_ADMIN
          ]}>
            <CandidateDetail1Page />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path={publicUser.candidate.DETAIL2}
        element={<CandidateDetail2Page />}
      />
      <Route path={publicUser.blog.GRID1} element={<BlogGrid1Page />} />
      <Route path={publicUser.blog.GRID2} element={<BlogGrid2Page />} />
      <Route path={publicUser.blog.GRID3} element={<BlogGrid3Page />} /> 
      <Route path={publicUser.blog.LIST} element={<BlogListPage />} />
      <Route path={publicUser.blog.DETAIL} element={<BlogDetailPage />} /> */}
      <Route
        path={publicUser.users.LIST}
        element={
          <ProtectedRoute allowedRoles={ROLE_OPENSTAFF_ADMIN}>
            <AdminUsersList />
          </ProtectedRoute>
        }
      />
      <Route
        path={publicUser.users.GRID}
        element={
          <ProtectedRoute allowedRoles={ROLE_OPENSTAFF_ADMIN}>
            <AdminUsersGrid />
          </ProtectedRoute>
        }
      />
       <Route
        path={publicUser.PAYMENT.PAYMENT_HISTORY}
        element={
          <ProtectedRoute allowedRoles={[
            ...ROLE_INSTITUTION_ADMIN,
            ...ROLE_OPENSTAFF_ADMIN,
            ...ROLE_OPENSTAFF_FINANCIAL_ADMIN
            ]}>
            <CanPaymentDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path={candidate.DOCUMENTS}
        element={
          <ProtectedRoute allowedRoles={ROLE_USER} path={candidate.DOCUMENTS}>
            <JobSeekerDocuments />
          </ProtectedRoute>
        }
      />
      <Route 
        path={candidate.CHATS} 
        element={
          <ProtectedRoute allowedRoles={[
            ...ROLE_OPENSTAFF_ADMIN,
            ...ROLE_USER,
            ...ROLE_INSTITUTION_ADMIN
          ]}>
            <CanChatPage />
          </ProtectedRoute>
        } 
      />
      <Route 
        path={candidate.CHATS + "/:id"} 
        element={
          <ProtectedRoute allowedRoles={[
            ...ROLE_OPENSTAFF_ADMIN,
            ...ROLE_USER,
            ...ROLE_INSTITUTION_ADMIN
          ]}>
            <CanChatPage />
          </ProtectedRoute>
        } 
      />
      {/* <Route path={candidate.CHATS + "/:id"} element={<CanChatPage />} /> */}
      <Route path="*" element={<Error404Page />} />
      <Route path="sign-up" element={<SignUpPage />} />

      <Route
        path={publicUser.sessionExpired.SESSION_EXPIRED}
        element={<SessionExpire />}
      />

      <Route
        path={publicUser.jobs.JOB_NOT_FOUND}
        element={
          <ProtectedRoute allowedRoles={ROLE_ALL}>
            {/* <SearchJob /> */}
            <JobNotFound />
          </ProtectedRoute>
        }
      />

      <Route 
        path={publicUser.deleteAccount.DELETE_ACCOUNT}
          element= { <DeleteAccount />}
      />
      <Route 
        path={publicUser.privacyPolicy.PRIVACY_POLICY}
          element= { <PrivacyPolicy />}
      />
      <Route 
        path={publicUser.contactUs.CONTACT_US}
          element= { <ContactUs />}
      />
      {/* <Route path={publicUser.COMPANY_DETAILS} element={<CanCompanyLogo />} /> */}
      <Route
        path={publicUser.COMPANY_DETAILS}
        element={
          <ProtectedRoute allowedRoles={ROLE_INSTITUTION_ADMIN}>
            <CanCompanyLogo />
          </ProtectedRoute>
        }
      />
      {userInfo?.manageTeam && (
      <>
      <Route 
        path={publicUser.MANAGE_TEAM_EMPLOYEE} 
        element={
          <ProtectedRoute allowedRoles={ROLE_INSTITUTION_ADMIN}>
            <EmployeesPage />
          </ProtectedRoute>
        } 
      />

      <Route 
        path={publicUser.MANAGE_TEAM_TRUSTED_WORKER} 
        element={
          <ProtectedRoute allowedRoles={ROLE_INSTITUTION_ADMIN}>
            <TrustedWorkersPage />
          </ProtectedRoute>
        } 
      />

    </>
      )}
      <Route
        path={publicUser.LOCATIONS}
        element={
          <ProtectedRoute allowedRoles={ROLE_INSTITUTION_ADMIN}>
            <CanAddLocation />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default PublicUserRoutes;
