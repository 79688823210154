import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SessionExpired from "../../../../../common/sessionExpired";
import apiCall from "../../../../../../apicall";
import Toast from "../../../../../common/toast";
import TableLoader from "../../../../../common/tableLoader";
import requiedDocumentData from "./document.required.json";
import { NavLink } from "react-router-dom";
import moment from "moment";
import RejectDocConfirmationPopup from "./rejectDocumentPopup";
import ApprovedDocumentPopUp from "./approvedDocumentPopup";
import ViewDocumentPopup from "./viewDocumentPopup";

function ApplicantDocumentsTab({ applicantDetailsId, tabType }) {
  const { role_type , TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [documentType, setDocumentType] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [certificateList, setCertificateList] = useState([]);
  const [backgroundForm, setBackgroundForm] = useState({});
  const [requiredDocumentList, setRequiredDocumentList] = useState([]
    // requiedDocumentData?.data
  );
  const [isCommonDocListLoading, setIsCommonDocListLoading] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [rejectedDocId, setRejectedDocId] = useState("");
  const [approvedDocId, setApprovedDocId] = useState("");
  const [viewDocId, setViewDocId] = useState("");
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const displayErrSuccessMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setDocumentList([]);
  };

  const openRejectPopup = (id, docType) => {
    //which type of document reject
    if (docType) {
      setDocumentType(docType);
      setRejectedDocId(id);
    } else {
      setRejectedDocId(id);
      setDocumentType(false);
    }
  };

  const openApprovedPopup = (id, docType) => {
    //which type of document approve
    if (docType) {
      setDocumentType(docType);
      setApprovedDocId(id);
    } else {
      setApprovedDocId(id);
      setDocumentType(false);
    }
  };

  const openDocumentCertificateViewPopup = (id, docType) => {
    //which type of document view
    if (docType) {
      setDocumentType(docType);
      setViewDocId(id);
    } else {
      setViewDocId(id);
      setDocumentType(false);
    }
  };

  const handleDocumentRejectSuccess = () => {
    if (tabType === "document") {
      getDocumentsList();
    } else if (tabType === "certificate") {
      getCertificateList();
    }
  };

  const handleDocApprovedSuccess = () => {
    getDocumentsList();
    getCertificateList();
  };

  const getBackgroundCheckForm = async () => {
    try {
      const api = `openstaff/users/${applicantDetailsId}/background`;
      const backgroundResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("backgroundResponse---", backgroundResponse);
      if (backgroundResponse?.data?.id) {
        setSessionExpired(false);
        setIsLoading(false);
        return backgroundResponse?.data;
      } else if (backgroundResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayErrSuccessMsg("Your session has been expired!!");
        return {};
      } else if (backgroundResponse?.data === "") {
        setSessionExpired(false);
        // displayErrSuccessMsg("Your session has been expired!!");
        return {};
      } else {
        setSessionExpired(false);
        // displayErrSuccessMsg("Something went wrong!");
        displayErrSuccessMsg(backgroundResponse?.response?.data?.message)
        return {};
      }
    } catch (err) {
      setSessionExpired(false);
      displayErrSuccessMsg("Something went wrong!");
      return {};
    }
  };

  const getDocumentsList = async () => {
    try {
      setIsLoading(true);
      const api = `${
        (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_SEEKER_USER_ADMIN"))
          && `openstaff/users/${applicantDetailsId}/documents`
      }`;
      const applicantDocResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("applicantDocResponse---", applicantDocResponse);
      if (applicantDocResponse?.data?.content?.length > 0) {
        setSessionExpired(false);
        setIsLoading(false);
        const applicantDocList = applicantDocResponse?.data?.content;
        const backgroundRes = await getBackgroundCheckForm();
        if (backgroundRes?.id) {
          setBackgroundForm(backgroundRes);
          applicantDocList.push({
            ...backgroundRes,
            backgroundCheckForm: true,
            documentType: "BackgroundCheckForm",
          });
        }
        console.log("backgroundRes----", applicantDocList);

        const combinedList = [...applicantDocList];

        requiredDocumentList?.forEach((item2) => {
          const isDuplicate = combinedList.some(
            (item1) => item1.documentType === item2.document
          );
          if (!isDuplicate) {
            // Change document key to documentType
            const newItem = { ...item2, documentType: item2.document };
            delete newItem.document;
            combinedList.push(newItem);
          }
        });

        console.log("combinedList-----", combinedList);


        // Update the state with the unique list
        setDocumentList(combinedList);
      } else if (applicantDocResponse?.data?.content?.length === 0) {
        // displayErrSuccessMsg("No results found.!");
        setIsLoading(false);
        // setDocumentList([]);
        // if (requiedDocumentData?.data?.length > 0) {
        //   setRequiredDocumentList(requiedDocumentData?.data);
        //   setSessionExpired(false);
        // }
        const applicantDocList = [];
        const backgroundRes = await getBackgroundCheckForm();
        console.log("backgroundRes:::::", backgroundRes)
        if (backgroundRes?.id) {
          setBackgroundForm(backgroundRes);
          applicantDocList.push({
            ...backgroundRes,
            backgroundCheckForm: true,
            documentType: "BackgroundCheckForm",
          });
        }
        const combinedList = [...applicantDocList];

        requiredDocumentList?.forEach((item2) => {
          const isDuplicate = combinedList.some(
            (item1) => item1.documentType === item2.document
          );
          if (!isDuplicate) {
            // Change document key to documentType
            const newItem = { ...item2, documentType: item2.document };
            delete newItem.document;
            combinedList.push(newItem);
          }
        });

        // Update the state with the unique list
        setDocumentList(combinedList);
      } else if (applicantDocResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayErrSuccessMsg("Your session has been expired!!");
      } else {
        setSessionExpired(false);
        // displayErrSuccessMsg("Something went wrong!");
        displayErrSuccessMsg(applicantDocResponse?.response?.data?.message)
      }
    } catch (err) {
      setSessionExpired(false);
      displayErrSuccessMsg("Something went wrong!");
    }
  };

  const getDocumentListAPI = async () => {
    setIsCommonDocListLoading(true)
    try {
    const getApiKeysResponse = await apiCall(
      `openstaff/configKey/commonDocs`,
      "GET",
      null,
      TOKEN
    );
    console.log("getApiKeysResponse", getApiKeysResponse?.data?.data);
    if (getApiKeysResponse?.status === 200) {
      setRequiredDocumentList(getApiKeysResponse?.data?.data);
    } else if (getApiKeysResponse?.response?.status == 401) {
      setSessionExpired(true);
      displayErrSuccessMsg("Your session has been expired!");
    } else {
      displayErrSuccessMsg(getApiKeysResponse?.response?.data?.message || "Network Error!")
    }
  } catch (err) {
    console.log("setSessionExpired--", err);
    displayErrSuccessMsg("Network Error!");
  }finally{
    setIsCommonDocListLoading(false);
  }
  };

  const getCertificateList = async () => {
    try {
      setIsLoading(true);
      const api = `${
        (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_SEEKER_USER_ADMIN"))
          && `openstaff/users/${applicantDetailsId}/certificates`
      }`;
      const applicantCertificateResponse = await apiCall(
        api,
        "GET",
        null,
        TOKEN
      );

      if (applicantCertificateResponse?.data?.content?.length > 0) {
        setSessionExpired(false);
        setIsLoading(false);
        setCertificateList(applicantCertificateResponse?.data?.content);
      } else if (applicantCertificateResponse?.data?.content?.length === 0) {
        // displayErrSuccessMsg("No Reacord Found!");
        setSessionExpired(false);
      } else if (applicantCertificateResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayErrSuccessMsg("Your session has been expired!!");
      } else {
        setSessionExpired(false);
        // displayErrSuccessMsg("Something went wrong!");
        displayErrSuccessMsg(applicantCertificateResponse?.response?.data?.message)
      }
    } catch (err) {
      setSessionExpired(false);
      displayErrSuccessMsg("Something went wrong!");
    }finally{
      setIsLoading(false);
    }
  };

  const ViewComponent = ({ item }) => {
    return (
      <p>
        <a
          className="twm-jobs-browse site-text-primary job-details custom-toltip"
          data-bs-toggle="modal"
          href="#view_applicant_doc"
          role="button"
          onClick={() =>
            openDocumentCertificateViewPopup(
              item?.id,
              item?.backgroundCheckForm
            )
          }
        >
          <span style={{ fontSize: "20px" }} className="fas fa-eye"></span>
          <span className="custom-toltip-block">View Doc</span>
        </a>
      </p>
    );
  };

  const ApprovedComponent = ({ item }) => {
    return (
      <p>
        <a
          className="twm-jobs-browse site-text-primary job-details custom-toltip"
          data-bs-toggle="modal"
          href="#approved_doc_confirmation"
          role="button"
          onClick={() => openApprovedPopup(item?.id, item?.backgroundCheckForm)}
        >
          <span
            style={{ fontSize: "20px", color: "green" }}
            className="fas fa-thumbs-up"
          ></span>
          <span className="custom-toltip-block">Approve Doc</span>
        </a>
      </p>
    );
  };

  const RejectComponent = ({ item }) => {
    return (
      <p>
        <a
          className="twm-jobs-browse site-text-primary job-details custom-toltip"
          data-bs-toggle="modal"
          href="#reject_doc_confirmation"
          role="button"
          onClick={() => openRejectPopup(item?.id, item?.backgroundCheckForm)}
        >
          <span
            style={{ fontSize: "20px", color: "red" }}
            className="fas fa-thumbs-down"
          ></span>
          <span className="custom-toltip-block">Reject Doc</span>
        </a>
      </p>
    );
  };



  useEffect(() => {
     getDocumentListAPI();
   
  }, []);

  useEffect(()=>{
    if (tabType === "document") {
      getDocumentsList();
    } else if (tabType === "certificate") {
      getCertificateList();
    }
  },[requiredDocumentList])

  return (
    <>
      <h4 className="twm-s-title">Documents List</h4>
      <div className="twm-employer-list-wrap">
        <ul>
          {console.log("documentList----", documentList)}
          {!isLoading ? (
            documentList?.length > 0 && tabType === "document" ? (
              documentList?.map((item) => (
                item?.documentType !== "TrainingCertificates" &&
                (<li>
                  {console.log("item----------",item)}
                  <div className="twm-candidates-list-style1 mb-5">
                    <div className="twm-mid-content">
                      <NavLink className="twm-job-title">
                        <h4>{`${
                          !item?.backgroundCheckForm
                            ? item?.title ? item?.title : item?.documentType
                            : "BackgroundCheckForm"
                        }`}</h4>
                      </NavLink>

                      <div className="twm-fot-content">
                        <div className="twm-left-info">
                          <div className="twm-jobs-vacancies">
                            {item?.createdDate ? (
                              <>
                                <p>
                                  Approval Date:
                                  {item?.statusTime
                                    ? moment(item?.statusTime).format(
                                        "MM/DD/YYYY"
                                      )
                                    : "No Date"}
                                </p>
                                <p>
                                  Expiration Date:
                                  {item?.expiredTime
                                    ? moment(item?.expiredTime).format(
                                        "MM/DD/YYYY"
                                      )
                                    : "No Date"}
                                </p>
                              </>
                            ) : (
                              <p>Not Uploaded</p>
                            )}

                            <p
                              style={{
                                color:
                                  item?.documentStatus === "rejected" ||
                                  item?.applicationDocumentStatus === "rejected"
                                    ? "red"
                                    : item?.documentStatus === "approved" ||
                                      item?.applicationDocumentStatus ===
                                        "approved"
                                    ? "green"
                                    : item?.documentStatus ===
                                        "waitingForReview" ||
                                      item?.applicationDocumentStatus ===
                                        "waitingForReview"
                                    ? "#ffc107"
                                    : "black",
                              }}
                            >
                              {!item?.backgroundCheckForm
                                ? item?.documentStatus === "rejected"
                                  ? "Requires Upload"
                                  : item?.documentStatus?.toUpperCase()
                                : item?.applicationDocumentStatus?.toUpperCase()}
                            </p>
                          </div>
                        </div>
                        <div className="twm-right-btn">
                          {!item?.backgroundCheckForm
                            ? (item?.documentStatus === "approved" ||
                                item?.documentStatus === "rejected" ||
                                item?.documentStatus ===
                                  "waitingForReview") && (
                                <ViewComponent item={item} />
                              )
                            : (item?.applicationDocumentStatus === "approved" ||
                                item?.applicationDocumentStatus ===
                                  "rejected" ||
                                item?.applicationDocumentStatus ===
                                  "waitingForReview") && (
                                <ViewComponent item={item} />
                              )}
                          {!item?.backgroundCheckForm
                            ? item?.documentStatus === "waitingForReview" && (
                                <ApprovedComponent item={item} />
                              )
                            : item?.applicationDocumentStatus ===
                                "waitingForReview" && (
                                <ApprovedComponent item={item} />
                              )}
                          {!item?.backgroundCheckForm
                            ? (item?.documentStatus === "approved" ||
                                item?.documentStatus ===
                                  "waitingForReview") && (
                                <RejectComponent item={item} />
                              )
                            : (item?.applicationDocumentStatus === "approved" ||
                                item?.applicationDocumentStatus ===
                                  "waitingForReview") && (
                                <RejectComponent item={item} />
                              )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )))
            ) : documentList?.length === 0 && tabType === "document" ? (
              requiredDocumentList?.map((item) => (
                <li>
                  <div className="twm-candidates-list-style1 mb-5">
                    <div className="twm-mid-content">
                      <NavLink className="twm-job-title">
                        <h4>{`${item?.title}`}</h4>
                      </NavLink>
                      <div className="twm-right-btn">
                        <span className="twm-view-prifile site-text-primary">
                          Not Uploaded
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              ))
            ) : !isLoading ? (
              certificateList?.length > 0 && tabType === "certificate" ? (
                certificateList?.map((item) => (
                  <>
                    <li>
                      <div className="twm-candidates-list-style1 mb-5">
                        <div className="twm-mid-content">
                          <NavLink className="twm-job-title">
                            <h4>{`${item?.description}`}</h4>
                          </NavLink>
                          {/* <div>
                            <p className="twm-candidate-address">
                              Approved on:
                              {moment(item?.statusTime).format("MM/DD/YYYY")}
                            </p>
                            <p className="twm-candidate-address">
                              Expird on:
                              {moment(item?.expiredTime).format("MM/DD/YYYY")}
                            </p>
                            <p className="twm-candidate-address">
                              Hours:
                              {item?.numberOfHours
                                ? `${item.numberOfHours?.toFixed()} hr`
                                : "-"}
                            </p>
                          </div> */}

                          <div className="twm-fot-content">
                            <div className="twm-left-info">
                              <div className="twm-jobs-vacancies">
                                <p className="twm-candidate-address">
                                  Approval Date:
                                  {moment(item?.statusTime).format(
                                    "MM/DD/YYYY"
                                  )}
                                </p>
                                <p className="twm-candidate-address">
                                  Expiration Date:
                                  {moment(item?.expiredTime).format(
                                    "MM/DD/YYYY"
                                  )}
                                </p>
                                <p className="twm-candidate-address">
                                  Hours:
                                  {item?.numberOfHours
                                    ? `${item.numberOfHours?.toFixed()} hr`
                                    : "-"}
                                </p>
                                <p
                                  style={{
                                    color:
                                      item?.documentStatus === "rejected"
                                        ? "red"
                                        : item?.documentStatus === "approved"
                                        ? "green"
                                        : item?.documentStatus ===
                                          "waitingForReview"
                                        ? "#ffc107"
                                        : "black",
                                  }}
                                >
                                  {item?.documentStatus === "rejected"
                                    ? "Requires Upload"
                                    : item?.documentStatus?.toUpperCase()}
                                </p>
                              </div>
                            </div>
                            <div className="twm-right-btn">
                              {(item?.documentStatus === "approved" ||
                                item?.documentStatus === "rejected" ||
                                item?.documentStatus ===
                                  "waitingForReview") && (
                                <p>
                                  <a
                                    className="twm-jobs-browse site-text-primary job-details custom-toltip"
                                    data-bs-toggle="modal"
                                    href={`${
                                      tabType === "document"
                                        ? "#view_applicant_doc"
                                        : tabType === "certificate"
                                        ? "#view_applicant_certificate"
                                        : ""
                                    }`}
                                    role="button"
                                    onClick={() =>
                                      openDocumentCertificateViewPopup(item?.id)
                                    }
                                  >
                                    <span
                                      style={{ fontSize: "20px" }}
                                      className="fas fa-eye"
                                    ></span>
                                    <span className="custom-toltip-block">
                                      View Doc
                                    </span>
                                  </a>
                                </p>
                              )}
                              {item?.documentStatus === "waitingForReview" && (
                                <p>
                                  <a
                                    className="twm-jobs-browse site-text-primary job-details custom-toltip"
                                    data-bs-toggle="modal"
                                    //   href="#approved_doc_confirmation"
                                    href={`${
                                      tabType === "document"
                                        ? "#approved_doc_confirmation"
                                        : tabType === "certificate"
                                        ? "#approved_certificate_confirmation"
                                        : ""
                                    }`}
                                    role="button"
                                    onClick={() => openApprovedPopup(item?.id)}
                                  >
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "green",
                                      }}
                                      className="fas fa-thumbs-up"
                                    ></span>
                                    <span className="custom-toltip-block">
                                      Approve Doc
                                    </span>
                                  </a>
                                </p>
                              )}
                              {(item?.documentStatus === "approved" ||
                                item?.documentStatus ===
                                  "waitingForReview") && (
                                <p>
                                  <a
                                    className="twm-jobs-browse site-text-primary job-details custom-toltip"
                                    data-bs-toggle="modal"
                                    href={`${
                                      tabType === "document"
                                        ? "#reject_doc_confirmation"
                                        : tabType === "certificate"
                                        ? "#reject_certificate_confirmation"
                                        : ""
                                    }`}
                                    role="button"
                                    onClick={() => openRejectPopup(item?.id)}
                                  >
                                    <span
                                      style={{ fontSize: "20px", color: "red" }}
                                      className="fas fa-thumbs-down"
                                    ></span>
                                    <span className="custom-toltip-block">
                                      Reject Doc
                                    </span>
                                  </a>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </>
                ))
              ) : (
                <p style={{ color: "red" }}>No certificates found!</p>
              )
            ) : (
              <TableLoader />
            )
          ) : (
            <TableLoader />
          )}
        </ul>
      </div>

      <ViewDocumentPopup
        viewDocId={viewDocId}
        tabType={tabType}
        applicantDetailsId={applicantDetailsId}
        documentType={documentType}
        handleDocApprovedSuccess={handleDocApprovedSuccess}
        backgroundForm={backgroundForm}
      />

      <RejectDocConfirmationPopup
        rejectedDocId={rejectedDocId}
        handleDocumentRejectSuccess={handleDocumentRejectSuccess}
        tabType={tabType}
        documentType={documentType}
        applicantDetailsId={applicantDetailsId}
      />
      <ApprovedDocumentPopUp
        approvedDocId={approvedDocId}
        handleDocApprovedSuccess={handleDocApprovedSuccess}
        tabType={tabType}
        documentType={documentType}
      />

      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default ApplicantDocumentsTab;
