import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, setLoginTimeStamp, logout, roleType } from './store/reducers/menu';
import Loader from './app/common/loader';
import ScrollToTop from './globals/scroll-to-top';
import RootLayout from './layouts/root-layout';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { auth } from './app/common/firebase/firebase';


// Function to check session expiration
export const checkSession = (loginTimeStamp, dispatch) => {
  if (loginTimeStamp) {
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - parseInt(loginTimeStamp, 10);
    console.log("elapsedTime----",elapsedTime >= 86400000,elapsedTime,elapsedTime/ (1000 * 60 * 60),currentTime,parseInt(loginTimeStamp, 10))

    // Check if 24 hours have passed (24 hours = 86400000 milliseconds)
    if (elapsedTime >= 86400000) { 
       signOut(auth);
      // dispatch(login(null));
      dispatch(logout(null));
      dispatch(setLoginTimeStamp(null));
      console.log("Session expired. User logged out.");
      return true;
    } else {
      console.log("Session active. Token refreshed.");
      return false;
    }
  }
};


const App = () => {
  const { isLoggedIn, userInfo } = useSelector(state => state.menu);
  const [isLoading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUrl = location.pathname;

  // Simulate loading state
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    if (location.pathname !== '/' && location.pathname.endsWith('/')) {
      navigate(location.pathname.slice(0, -1), { replace: true });
    }
  }, [location, navigate]);

  // Periodically refresh token every 55 minutes
  useEffect(() => {
    const refreshTokenPeriodically = async () => {
      const user = auth.currentUser;
      console.log("userr---=",user,userInfo)
      console.log("userrRole---=",userInfo?.roleEntities.map((item)=>item?.roleType))
      if (user) {
        try {
          // Force token refresh
          const idToken = await user.getIdToken(true);
          dispatch(login(idToken));
          // dispatch(roleType(userInfo?.roleEntities.map((item)=>item?.roleType)));
          // dispatch(setLoginTimeStamp(new Date().getTime().toString()));
        } catch (error) {
          console.error('Error refreshing token', error);
        }
      }
      // else{
      //   console.log("app.js-----")
      //   dispatch(logout(null));
      //   dispatch(roleType([]));
      //   signOut(auth);
      //   if (!currentUrl?.includes("/sign-up")) {
      //     console.log("signupp")
      //     navigate("/")
      //   }else{
      //     console.log("signupelse1")
      //   }
      // }
    };

    // Check every 55 minutes if the token needs refreshing
    const intervalId = setInterval(refreshTokenPeriodically, 55 * 60 * 1000); // 55 minutes

    return () => clearInterval(intervalId);
  }, [auth]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log("userr---=",auth, user,userInfo)

      if (user && userInfo) {
        const idToken = await user.getIdToken();
        dispatch(login(idToken));
      } 
      // else {
        // dispatch(login(null));
        // dispatch(logout(null));
        // dispatch(roleType([]))
        // if (!currentUrl?.includes("/sign-up")) {
        //   console.log("signup")
        //    navigate("/")
        // }else{
        //   console.log("signupelse2")
        // }
      // }
    });

    return () => unsubscribe();
  }, [auth]);
// }, []);

  return (
    <>
      {isLoading && <Loader />}
      <ScrollToTop />
      <RootLayout />
    </>
  );
};

export default App;
