import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TrustedWorkersList from "./trusted-workers-list";

const TrustedWorkersTabs = ( { fetchTrustedWorkersList } ) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUrl = location.pathname;
  const [activeTab, setActiveTab] = useState("pastHires");
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  console.log("activeTab", activeTab)

  return (
    <>
    <div className="twm-tabs-style-2">
    <ul className="nav nav-tabs" id="myTab" role="tablist">
      <li className="nav-item" role="presentation">
        <button
          className={`nav-link ${activeTab === "pastHires" ? "active" : ""}`}
          data-bs-toggle="tab"
          data-bs-target="#sign-employees"
          type="button"
          onClick={() => handleTabChange("pastHires")}
        >
          <i className="fa fa-user-friends" /> Past Hires
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className={`nav-link ${activeTab === "allWorkers" ? "active" : ""}`}
          data-bs-toggle="tab"
          data-bs-target="#sign-trusted-workers"
          type="button"
          onClick={() => handleTabChange("allWorkers")}
        >
          <i className="fa fa-users" /> All Workers
        </button>
      </li>
    </ul>
    <div className="tab-content" id="myTab2Content">
        <TrustedWorkersList fetchTrustedWorkersList={fetchTrustedWorkersList} trustedWorker={activeTab === "pastHires" ? "pastHires" : "allWorkers"} />
    </div>
    </div>
    </>
  );
};

export default TrustedWorkersTabs;
