import { useEffect, useRef, useState } from "react";
import { loadScript } from "../../../../../globals/constants";
import JobZImage from "../../../../common/jobz-img";
import ApplyJobPopup from "../../../../common/popups/popup-apply-job";
import SectionJobLocation from "../../sections/jobs/detail/section-job-location";
import SectionOfficePhotos1 from "../../sections/common/section-office-photos1";
import SectionOfficeVideo1 from "../../sections/common/section-office-video1";
import SectionShareProfile from "../../sections/common/section-share-profile";
import SectionJobsSidebar2 from "../../sections/jobs/sidebar/section-jobs-sidebar2";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import apiCall from "../../../../../apicall";
import GetProfileImage from "../../../../common/getProfileImage";
import Toast from "../../../../common/toast";
import SessionExpired from "../../../../common/sessionExpired";
import AppliedApplicantsTable from "./appliedApplicantsTable";
import NotificationTable from "./notificationTable";
import Spinner from "../../../../common/spinner";
import InvitationPopup from "./invitationPopup";
import ClockInClockOutAdmin from "./clockin-clockout-admin";
import ClockInClockOut from "./clockin-clockout-seeker";
import moment from "moment";
import ConfirmationWithdrawJobSeekerPopUp from "./confirmationWithdrawJobSeeker";
import AddToFeatureJob from "./featureJobs";
import SectionCanChat from "../../../candidate/sections/chat/section-can-chat";
import { navigateVisitedPage } from "../../../../../store/reducers/menu";
import ChatJobPopup from "./popup-chat";
import AdminPaymentTransfer from "./admin-payment-transfer";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../globals/fomat-comapny-name";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";
function JobDetail1Page() {
  const location = useLocation();
  const prevUrlRef = useRef();
  const currentUrl = location.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const sliderValue = localStorage.getItem("sliderValue");
  console.log("sliderValue", sliderValue)
  console.log("location daat fro job list", location.state);
  const { role_type , TOKEN, isAddressSelected, userInfo, isLoggedIn, navigate_visited_page, jobDetailsId, jobDetailsData } = useSelector((state) => state.menu);
  const jobStatus = location?.state?.jobStatus;
  console.log("jobStatus#####",jobStatus)
  console.log("jobDetails:::::", jobDetailsData)
  const [jobId, setJobId] = useState();
  const { id } = useParams();
  const [jobDetailId, setJobDetailId] = useState(location?.state?.id ? location?.state?.id : id);
  const [jobDetailsInfo, setJobDetailsInfo] = useState({});
  const [appliedApplicantList, setAppliedApplicantList] = useState([]);
  const [jobEntity, setJobEntity] = useState([]);
  const [appliedApplicantLoader, setAppliedApplicantLoader] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [invitationList, setInvitationList] = useState([]);
  // const [clockinClockoutList, setClockInClockOutList] = useState([]);
  const [notificationLoader, setNotificationLoader] = useState(false);
  const [invitationLoader, setInvitationLoader] = useState(false);
  const [clockinClockoutLoader, setClockinClockoutLoader] = useState(false);
  const [isLoadingApplyJob, setIsLoadingApplyJob] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [chatShow, setChatShow]=useState(false)
  const [appliedApplicantCurrentPage, setappliedApplicantCurrentPage] =
    useState(1);
  const [notificationListCurrentPage, setNotificationListCurrentPage] =
    useState(1);
  const [invitationCurrentPage, setInvitationCurrentPage] = useState(1);
  const [appliedApplicantRowPerPage, setappliedApplicantRowPerPage] =
    useState(10);
  const [notificationListRowPerPage, setNotificationListRowPerPage] =
    useState(10);
  const [invitationListRowPerPage, setInvitationListRowPerPage] = useState(10);
  const [
    appliedApplicantListPaginationData,
    setAppliedApplicantListPaginationData,
  ] = useState({
    totalElements: 0,
    totalPages: 0,
  });
  const [notificationListPaginationData, setNotificationListPaginationData] =
    useState({
      totalElements: 0,
      totalPages: 0,
    });
  const [invitationListPaginationData, setInvitationListPaginationData] =
    useState({
      totalElements: 0,
      totalPages: 0,
    });
  const [appliedApplicantSearchKey, setAppliedApplicantSearchKey] =
    useState("");
  const [notificationApplicantSearchKey, setNotificationApplicantSearchKey] =
    useState("");
  const [invitationSearchKey, setInvitationSearchKey] = useState("");
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [posterRating, setPosterRating] = useState(
    location?.state?.ratings ? location?.state?.ratings : null
  );
  const [posterReview, setPosterReview] = useState(
    location?.state?.review ? location.state.review : null
  );
  const [seekerRating, setSeekerRating] = useState(
    location?.state?.institutionRating ? location.state.institutionRating : null
  );
  const [seekerReview, setSeekerReview] = useState(
    location?.state?.institutionReview ? location.state.institutionReview : null
  );
  const [ansValues, setAnsValues] = useState({});
  const [isLoadingPositionList, setIsLoadingPositionList] = useState(false);
  const [positionDropdownList, setPositionDropdownList] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    locationName: isAddressSelected?.locationName
      ? isAddressSelected?.locationName
      : "",
    lat: isAddressSelected?.latlng ? isAddressSelected?.latlng?.lat : "",
    lng: isAddressSelected?.latlng ? isAddressSelected?.latlng?.lng : "",
  });

  const sidebarConfig = {
    showJobInfo: true,
  };
  const calculateTotalWorkingHour = (jobDetails_Data) => {
    const startDate = moment.utc(jobDetails_Data?.startTime); // Assuming start date is April 11, 2024, at 12:00 PM
    const endDate = moment.utc(jobDetails_Data?.endTime); // Assuming end date is April 12, 2024, at 2:30 PM
    const diffDuration = moment.duration(endDate.diff(startDate));
    const hours = diffDuration.hours();
    const minutes = diffDuration.minutes();
    return hours + " hours " + minutes + " minutes";
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setJobDetailsInfo({});
  };

  const displayErrMsgApplyJob = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoadingApplyJob(false);
  };

  const displayAppliedApplicantErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setAppliedApplicantList([]);
    setAppliedApplicantListPaginationData({
      totalElements: 0,
      totalPages: 0,
    });
    setAppliedApplicantLoader(false);
  };

  const displayNotificationErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setNotificationList([]);
    setNotificationListPaginationData({
      totalElements: 0,
      totalPages: 0,
    });
    setNotificationLoader(false);
  };

  const displayInvitationErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setInvitationList([]);
    setInvitationListPaginationData({
      totalElements: 0,
      totalPages: 0,
    });
    setInvitationLoader(false);
  };

  // const displayClockInOutErrMsg = (errMsg) => {
  //   setErrSuccessMsg({
  //     message: errMsg,
  //     status: false,
  //   });
  //   setClockInClockOutList([]);
  //   setClockinClockoutLoader(false);
  // };

  const handleAppliedApplicantRecordsPerPageChange = (event) => {
    setappliedApplicantCurrentPage(1);
    setappliedApplicantRowPerPage(event.target.value);
  };

  const handleNotificationListRecordsPerPageChange = (event) => {
    setNotificationListCurrentPage(1);
    setNotificationListRowPerPage(event.target.value);
  };

  const handleInvitationListRecordsPerPageChange = (event) => {
    setInvitationCurrentPage(1);
    setInvitationListRowPerPage(event.target.value);
  };

  const getJobDetailsData = async () => {
    {
      try {
        const api = `${
          role_type.includes("ROLE_OPENSTAFF_ADMIN")
            ? `openstaff/jobs/${jobDetailId}/jobDetails`
            : role_type.includes("ROLE_USER")
            ?`${jobDetailId}/jobDetails`
            // ? `jobs/${jobDetailId}`
            : role_type.includes("ROLE_INSTITUTION_ADMIN")
            ? `institutions/${jobDetailId}/jobDetails`
            : `config/${jobDetailId}/jobDetails?latitude=${tableFilter?.lat}&longitude=${tableFilter?.lng}`

        }`;
        const jobDetailsResponse = await apiCall(api, "GET", null, TOKEN);
        console.log("jobDetailsResponse---", jobDetailsResponse);

        if (jobDetailsResponse?.data?.institutionEntityId) {
          setSessionExpired(false);
          setJobDetailsInfo(jobDetailsResponse?.data);
          await logEvent_Analytics('job_detail_web', {
            param1 : jobDetailsResponse?.data?.jobTitle, 
            param2 : jobDetailId, 
            param3 : userInfo?.id
          });
          if(jobDetailsResponse?.data?.jobStatus === 'eOpen'){
            getPositionDropdownList(jobDetailsResponse?.data);
          }
        } else if (jobDetailsResponse?.response?.status == 401) {
          setSessionExpired(true);
          displayErrMsg("Your session has been expired!!");
        } else {
          setSessionExpired(false);
          // displayErrMsg("Something went wrong!");
          displayErrMsg(jobDetailsResponse?.response?.data?.message)
        }
      } catch (err) {
        setSessionExpired(false);
        displayErrMsg("Something went wrong!");
      }
    }
  };

  const getAppliedApplicantsList = async () => {
    try {
      setAppliedApplicantLoader(true);
      const api = `${
        role_type.includes("ROLE_OPENSTAFF_ADMIN") ||
        role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") ||
        role_type.includes("ROLE_INSTITUTION_ADMIN")
          ? `openstaff/jobs/${jobDetailId}/jobApplicants?offset=0&page=${
              appliedApplicantCurrentPage - 1
            }&size=${appliedApplicantRowPerPage}&searchkey=${appliedApplicantSearchKey}`
          : ""
      }`;
      const appliedApplicantResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("appliedApplicantResponse---", appliedApplicantResponse);

      if (appliedApplicantResponse?.data?.content?.length > 0) {
        setSessionExpired(false);
        setAppliedApplicantList(appliedApplicantResponse.data.content);
        setJobEntity(appliedApplicantResponse.data.content[0].jobEntity);
        setAppliedApplicantListPaginationData({
          totalElements: appliedApplicantResponse.data.totalElements,
          totalPages: appliedApplicantResponse.data.totalPages,
        });
        setAppliedApplicantLoader(false);
      } else if (appliedApplicantResponse?.data?.content?.length === 0) {
        setSessionExpired(false);
        // displayAppliedApplicantErrMsg("No results found.!");
        setAppliedApplicantList([]);
        setAppliedApplicantListPaginationData({
          totalElements: 0,
          totalPages: 0,
        });
        setAppliedApplicantLoader(false);
      } else if (appliedApplicantResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayAppliedApplicantErrMsg("Your session has been expired!!");
      } else {
        setSessionExpired(false);
        // displayAppliedApplicantErrMsg("Something went wrong!");
        displayAppliedApplicantErrMsg(appliedApplicantResponse?.response?.data?.message)
      }
    } catch (err) {
      setSessionExpired(false);
      displayAppliedApplicantErrMsg("Something went wrong!");
    }
  };
    const approveBtnRef = useRef(null);
  
    const handleApproveAndFinish = (e) => {
      e.preventDefault();
  
      // Check the clockInStatus
      if (jobEntity.clockInStatus === "Clocked In") {
        displayAppliedApplicantErrMsg("Job seeker must clock out before finishing the job.");
        return;
      }
      if (approveBtnRef.current) {
        approveBtnRef.current.setAttribute("data-bs-toggle", "modal");
        approveBtnRef.current.click();
      }
    };

  const getJobNotificationList = async () => {
    try {
      setNotificationLoader(true);
      const api = `${
        role_type.includes("ROLE_OPENSTAFF_ADMIN") &&
        `jobNotifications/${jobDetailId}/jobApplicants?offset=0&page=${
          notificationListCurrentPage - 1
        }&size=${notificationListRowPerPage}&searchkey=${notificationApplicantSearchKey}`
        // : ""
      }`;
      const notificationResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("notificationResponse---", notificationResponse);

      if (notificationResponse?.data?.content?.length > 0) {
        setSessionExpired(false);
        setNotificationList(notificationResponse.data.content);
        setNotificationListPaginationData({
          totalElements: notificationResponse.data.totalElements,
          totalPages: notificationResponse.data.totalPages,
        });
        setNotificationLoader(false);
      } else if (notificationResponse?.data?.content?.length === 0) {
        setSessionExpired(false);
        // displayNotificationErrMsg("No Notification Found!");
        setNotificationList([]);
        setNotificationListPaginationData({
          totalElements: 0,
          totalPages: 0,
        });
        setNotificationLoader(false);
      } else if (notificationResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayNotificationErrMsg("Your session has been expired!!");
      } else {
        setSessionExpired(false);
        // displayNotificationErrMsg("Something went wrong!");
        displayNotificationErrMsg(notificationResponse?.response?.data?.message)
      }
    } catch (err) {
      setSessionExpired(false);
      displayNotificationErrMsg("Something went wrong!");
    }
  };

  const getInvitationList = async () => {
    try {
      setInvitationLoader(true);
      const api = `${
        role_type.includes("ROLE_OPENSTAFF_ADMIN") ||
        role_type.includes("ROLE_INSTITUTION_ADMIN")
          ? `users?offset=0&page=${
              invitationCurrentPage - 1
            }&size=${invitationListRowPerPage}&searchkey=${invitationSearchKey}`
          : ""
      }`;
      const invitationResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("invitationResponse---", invitationResponse);

      if (invitationResponse?.data?.content?.length > 0) {
        setSessionExpired(false);
        setInvitationList(invitationResponse.data.content);
        setInvitationListPaginationData({
          totalElements: invitationResponse.data.totalElements,
          totalPages: invitationResponse.data.totalPages,
        });
        setInvitationLoader(false);
      } else if (invitationResponse?.data?.content?.length === 0) {
        setSessionExpired(true);
        // displayInvitationErrMsg("No results found.!");
        setInvitationList([]);
        setInvitationListPaginationData({
          totalElements: 0,
          totalPages: 0,
        });
        setInvitationLoader(false);
      } else if (invitationResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayInvitationErrMsg("Your session has been expired!!");
      } else {
        setSessionExpired(false);
        // displayInvitationErrMsg("Something went wrong!");
        displayInvitationErrMsg(invitationResponse?.response?.data?.message)
      }
    } catch (err) {
      setSessionExpired(false);
      displayInvitationErrMsg("Something went wrong!");
    }
  };

  // const getClockInClockOutList = async () => {
  //   try {
  //     setClockinClockoutLoader(true);

  //     const api = `${
  //       role_type === "ROLE_OPENSTAFF_ADMIN"
  //         ? `openstaff/jobs/${jobDetailId}/applicantClockinClockouts`
  //         : role_type === "ROLE_INSTITUTION_ADMIN"
  //         ? `institutions/jobs/${jobDetailId}/applicantClockinClockouts`
  //         : ""
  //     }`;
  //     const clockInOutResponse = await apiCall(api, "GET", null, TOKEN);
  //     console.log("clockInOutResponse---", clockInOutResponse);

  //     if (clockInOutResponse?.data?.content?.length > 0) {
  //       setSessionExpired(false);
  //       setClockInClockOutList(clockInOutResponse.data.content);
  //       setClockinClockoutLoader(false);
  //     } else if (clockInOutResponse?.data?.content?.length === 0) {
  //       setSessionExpired(false);
  //       setClockInClockOutList([]);
  //       setClockinClockoutLoader(false);
  //     } else if (clockInOutResponse?.response?.status == 401) {
  //       setSessionExpired(true);
  //       displayClockInOutErrMsg("Your session has been expired!!");
  //     } else if (clockInOutResponse?.response?.status == 404) {
  //       setSessionExpired(false);
  //       setClockInClockOutList([]);
  //       setClockinClockoutLoader(false);
  //     } else {
  //       setSessionExpired(false);
  //       // displayClockInOutErrMsg("Something went wrong!");
  //       displayClockInOutErrMsg(clockInOutResponse?.response?.data?.message)
  //     }
  //   } catch (err) {
  //     setSessionExpired(false);
  //     displayClockInOutErrMsg("Something went wrong!");
  //   }
  // };

  function getTimeDifference(postTime) {
    const currentTime = new Date();
    const postDate = new Date(postTime);
    const timeDifference = currentTime.getTime() - postDate.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
      return `${seconds} sec ago`;
    } else if (minutes < 60) {
      return `${minutes} min ago`;
    } else if (hours < 24) {
      return `${hours} hour ago`;
    } else if (days < 30) {
      return `${days} day ago`;
    } else if (months < 12) {
      return `${months} month ago`;
    } else {
      return `${years} year ago`;
    }
  }

  const handleApplyJob = async () => {
    if (userInfo?.id) {
      try {
        setIsLoadingApplyJob(true);
        const applyJobResponse = await apiCall(
          `jobs/${jobDetailsInfo?.id}/apply`,
          "POST",
          null,
          TOKEN
        );
        console.log("applyJobResponse-----", applyJobResponse);
        if (applyJobResponse?.status === 200) {
          setIsLoadingApplyJob(false);
          setErrSuccessMsg({
            message: "Job Applied Successfully!",
            status: true,
          });
          setTimeout(() => {
            navigate("/job-list");
          }, 1000);
        } else if (applyJobResponse?.response?.data?.status === 302) {
          displayErrMsgApplyJob(applyJobResponse?.response?.data?.errorMessage);
        } else if (applyJobResponse?.response?.status == 401) {
          setSessionExpired(true);
          displayErrMsgApplyJob("Your session has been expired!!");
        } else if (applyJobResponse?.response?.status == 403) {
          setSessionExpired(false);
          displayErrMsgApplyJob("Your documents are not verified!!");
        } else {
          // displayErrMsgApplyJob("Something went wrong!");
          displayErrMsgApplyJob(applyJobResponse?.response?.data?.message)
        }
      } catch (err) {
        displayErrMsgApplyJob("Something went wrong!");
      }
    }
  };
  const openDeletionConfirmation = (id) => {
    console.log("openDeletionConfirmation---", id);
    setJobId(id);
  };
  const getPositionDropdownList = async (jobDetails) => {
    try {
      setIsLoadingPositionList(true);
      const positionResponse = await apiCall("config/allJobTypes", "GET", null);
      console.log("positionResponse", positionResponse);
      if (
        positionResponse?.status === 200 &&
        positionResponse?.data?.length > 0
      ) {
        setIsLoadingPositionList(false);
        setPositionDropdownList(positionResponse?.data);
        setCurrentProfession(positionResponse?.data, jobDetails);

      } else {
        setIsLoadingPositionList(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(positionResponse?.response?.data?.message)
      }
    } catch (err) {
      console.log("all job type error", err)
      setIsLoadingPositionList(false);
      displayErrMsg("Something went wrong!");
    }
  };
  const setCurrentProfession = (positiondropdownList, jobDetails) => {
    console.log("userInfo?.profession", jobDetails, positiondropdownList)
    const selectedPosition = positiondropdownList.find(
      (pos) => pos.typeName === jobDetails?.jobPosition
    );
    console.log("selectedPosition~~~", selectedPosition)
    const fileds = JSON.parse(selectedPosition?.relatedJobFields);

    setSelectedFields(selectedPosition ? fileds.fields : []);
    console.log("fileds.fields~~~", fileds.fields)
    // Set initial values for dynamic fields
    const newFormValues = {};
    fileds.fields.forEach((field) => {
      newFormValues[field.mapField] = jobDetails[field.mapField] || "";
    });
    setAnsValues((prevValues) => ({
      ...prevValues,
      ...newFormValues,
    }));
  };

  const getLocation = async () => {
    let responseData;

    try {
      let apiEndpoint;
      if (role_type?.includes("ROLE_INSTITUTION_ADMIN")) {
        apiEndpoint = "PosterLocation";
      } else {
        apiEndpoint = "userLocation";
      }
      const locationResponse = await apiCall(apiEndpoint, "GET", null, TOKEN, null, null, true);

      console.log("locationResponse", locationResponse);

      if (locationResponse?.status === 200) {
        // Extract the data from the response
        responseData = locationResponse.data;

        // Use a regex to ensure keys are properly quoted
        // const fixedResponseText = responseData
        //   .replace(/([{,]\s*)([a-zA-Z_][a-zA-Z0-9_]*)(\s*:)/g, '$1"$2"$3') // Wrap keys with double quotes
        //   .replace(/'/g, '"') // Replace single quotes with double quotes (if needed)
        //   .replace(
        //     /("address"\s*:\s*)([^,]+, [^,]+, [^,]+, [^,]+)(,)/g,
        //     '$1"$2"$3'
        //   ) // Wrap address value with double quotes
        //   .replace(/("locationName"\s*:\s*)([^,]+)(,|\s*})/g, '$1"$2"$3'); // Wrap locationName value with double quotes
        const fixedResponseText = responseData
          // Wrap keys with double quotes
          .replace(/([{,]\s*)([a-zA-Z_][a-zA-Z0-9_]*)(\s*:)/g, '$1"$2"$3')
          // Replace single quotes with double quotes (if needed)
          .replace(/'/g, '"')
          // Wrap address value with double quotes (handling various formats)
          .replace(
            /("address"\s*:\s*)([^,{]+, [^,]+, [^,]+, [^,]+)(,|\s*})/g,
            (match, p1, p2, p3) => {
              // Wrap the address with double quotes
              return `${p1}"${p2.trim()}"${p3}`;
            }
          )
          // Wrap locationName value with double quotes
          .replace(/("locationName"\s*:\s*)([^,]+)(,|\s*})/g, '$1"$2"$3')
          // Ensure suitNumber is quoted properly or set to ""
          .replace(
            /("suitNumber"\s*:\s*)([^,{]+)(,|\s*})/g,
            (match, p1, p2, p3) => {
              // If suitNumber value is missing or empty, replace with ""
              if (!p2.trim()) {
                p2 = '""';
              } else if (!(p2.startsWith('"') && p2.endsWith('"'))) {
                p2 = `"${p2}"`;
              }
              return `${p1}${p2}${p3}`;
            }
          )
          // Ensure address is double-quoted if not already
          .replace(
            /("address"\s*:\s*)([^"{]+)(,|\s*})/g,
            (match, p1, p2, p3) => {
              // Wrap the address with double quotes if not already
              if (!(p2.startsWith('"') && p2.endsWith('"'))) {
                p2 = `"${p2.trim()}"`;
              }
              return `${p1}${p2}${p3}`;
            }
          );

        console.log("locationObject-------", fixedResponseText);

        // Parse the fixed JSON string to an object
        const locationObject = JSON.parse(fixedResponseText);
        console.log("locationObject*****", locationObject)

        // Update state or variables
        return locationObject?.location;
      } else if (locationResponse?.response?.status === 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!!");
        return null;
      } else {
        setSessionExpired(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(locationResponse?.response?.data?.message)
        return null;
      }
    } catch (err) {

      const fixedResponseText = responseData
        .replace(/([{,]\s*)([a-zA-Z_][a-zA-Z0-9_]*)(\s*:)/g, '$1"$2"$3')
        .replace(/'/g, '"')
        .replace(
          /("address"\s*:\s*)([^,]+, [^,]+, [^,]+, [^,]+)(,)/g,
          '$1"$2"$3'
        )
        .replace(/("locationName"\s*:\s*)([^,]+)(,|\s*})/g, '$1"$2"$3')
        .replace(/("city"\s*:\s*)([^,]+)(,|\s*})/g, '$1"$2"$3')
        .replace(/("state"\s*:\s*)([^,]+)(,|\s*})/g, '$1"$2"$3')
        .replace(/("zipCode"\s*:\s*)([^,]+)(,|\s*})/g, '$1"$2"$3')
        .replace(/("country"\s*:\s*)([^,]+)(,|\s*})/g, '$1"$2"$3')
        .replace(/("suitNumber"\s*:\s*)([^,]+)(,|\s*})/g, '$1"$2"$3');

      console.log(
        "fixedResponseText::::userlocation::::::::",
        fixedResponseText
      );
      // parsedObject = JSON.parse(fixedResponseText);
      const updatedJsonString = fixedResponseText.replace(
        /(}\s*)(")/g,
        "$2$1"
      );
      const locationObject = JSON.parse(updatedJsonString);
      console.log("locationObject======", locationObject)

      if (locationObject?.location) {
        return locationObject?.location
      } else {
        setSessionExpired(false);
        return null;
      }
      // console.log("errorrr", err);
      // setSessionExpired(false);
      // displayErrMsg("Something went wrong!");
      // return null;
    }
  };

  const haversineDistance = (coords1, coords2) => {
    const toRad = (value) => (value * Math.PI) / 180;
  
    const R = 3958.8; // Radius of the Earth in miles
    const dLat = toRad(coords2.latitude - coords1.latitude);
    const dLon = toRad(coords2.longitude - coords1.longitude);
    const lat1 = toRad(coords1.latitude);
    const lat2 = toRad(coords2.latitude);
  
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    console.log("distancedistancedistance", distance)
  
    return distance;
  };

  const findingTheJobNearTo100Mies =async () =>{
    const locationInfo = await getLocation();

    if(locationInfo){
      const distance = haversineDistance(locationInfo,location?.state?.location)
      if(distance > sliderValue){
          navigate('/job-not-found')
      }else{
          //just display the job details to user
      }
    }
  }

  useEffect(() => {
    if (
      role_type.includes("ROLE_USER") &&
      (jobDetailsInfo?.jobStatus === "eAccepted" ||
        jobDetailsInfo?.jobStatus === "eInProgress")
    ) {
    }
  }, []);

  useEffect(() => {
    getJobDetailsData();
    //protect job-details
    // if (!jobDetailId) {
    //   navigate("/job-list");
    // }
  }, []);

  useEffect(() => {
    if (
      role_type.includes("ROLE_OPENSTAFF_ADMIN") ||
      role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") ||
      role_type.includes("ROLE_INSTITUTION_ADMIN")
    ) {
      getAppliedApplicantsList();
    }
  }, [
    appliedApplicantCurrentPage,
    appliedApplicantRowPerPage,
    appliedApplicantSearchKey,
  ]);

  useEffect(() => {
    if (role_type.includes("ROLE_OPENSTAFF_ADMIN")) {
      getJobNotificationList();
    }
  }, [notificationListCurrentPage, notificationListRowPerPage]);

  useEffect(() => {
    if (
      role_type.includes("ROLE_OPENSTAFF_ADMIN") ||
      role_type.includes("ROLE_INSTITUTION_ADMIN")
    ) {
      getInvitationList();
    }
  }, [invitationCurrentPage, invitationListRowPerPage]);

  // useEffect(() => {
  //   if (
  //     (role_type === "ROLE_OPENSTAFF_ADMIN" ||
  //       role_type === "ROLE_INSTITUTION_ADMIN") &&
  //     location?.state?.jobStatus !== "eOpen" &&
  //     location?.state?.jobStatus !== "eCancelled"
  //   ) {
  //     getClockInClockOutList();
  //   }
  // }, []);

  useEffect(()=>{
    if(!isLoggedIn){
      dispatch(navigateVisitedPage({
        currentPage: `/job-detail/${jobDetailId}`,
        jobDetails : location?.state
      }))
    }

    if(isLoggedIn && role_type?.includes('ROLE_USER') && navigate_visited_page){
      findingTheJobNearTo100Mies();
    }else if(isLoggedIn && role_type?.includes('ROLE_INSTITUTION_ADMIN') && navigate_visited_page){
        if(!(userInfo?.institutionEntityId === location?.state?.institutionEntityId)){
          navigate("/search-job")
        }
    }
  },[location?.state])

  // useEffect(() => {
  //   loadScript("js/custom.js");
  // });

  useEffect(()=>{
    let modalBackdrop = document.querySelector(".modal-backdrop");
    if (modalBackdrop) {
      modalBackdrop.remove();
    }
  })
  const handleAppliedChat = (row) => {
    setJobDetailsInfo(prevState => {
      const updatedState = { ...prevState, hirePerson: row };
      console.log('jobDetailsInfo:::::::::::::::::hirePerson', updatedState);
      return updatedState;
    });
    setChatShow(true);
  };
  useEffect(() => {
    if (prevUrlRef.current && prevUrlRef.current !== currentUrl) {
      if (prevUrlRef.current === '/') {
        // Refresh the page if the previous URL was '/index'
        window.location.reload();
      }
    }
    prevUrlRef.current = currentUrl; // Update previous URL reference
  }, [currentUrl]);
  
  const formatAddress = (address, city, state, zipCode) => {
    const parts = [address, city, state, zipCode].filter(Boolean);
    return parts.join(", ");
  };
  useEffect(() => {
    loadScript("js/custom.js");
  });


  return (
    <>
      <div>
        <Helmet>
          <title>{`${jobDetailsInfo?.jobTitle ? jobDetailsInfo?.jobTitle : ""} | Job Details Overview | ${formatCompanyName()}`}</title>
          <meta name="description" content="Find essential information about job roles, qualifications, and application steps to help you land your dream job." />
          <meta name="keywords" content="career growth, job requirement, job details"/>
        </Helmet>
      </div>
      <div className="section-full  p-t120 p-b90 bg-white">
        <div className="container">
          {/* BLOG SECTION START */}
          <div className="section-content">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8 col-md-12">
                {/* Candidate detail START */}
                <div className="cabdidate-de-info">
                  <div className="twm-job-self-wrap">
                    <div className="twm-job-self-info">
                      <div className="twm-job-self-top">
                        <div className="twm-media-bg">
                          <SectionJobLocation jobDetailsInfo={jobDetailsInfo} />

                          {/* <img
                            src={
                              jobDetailsInfo?.jobPosition === "Electrician"
                                ? "/assets/images/electrician.jpg"
                                : jobDetailsInfo?.jobPosition === "Heavy Truck Driver"
                                  ? "/assets/images/truck-driver.jpg"
                                : jobDetailsInfo?.jobPosition === "Teacher"
                                  ? "/assets/images/teacher.jpg"
                                : jobDetailsInfo?.jobPosition === "Forklift Driver"
                                  ? "/assets/images/forklift-driver.jpg"
                                : jobDetailsInfo?.jobPosition === "Dispatch Coordinator"
                                  ? "/assets/images/dispatch-coordinator.jpg"
                                  : ""
                            }
                            alt={jobDetailsInfo?.jobPosition || "Job Image"} style={{"width": "100%"}}
                          /> */}
                          {/* <div className="twm-jobs-category green">
                            <span className="twm-bg-green">New</span>
                          </div> */}
                        </div>
                        <div className="twm-mid-content">
                          <div className="twm-media">
                            {console.log("jobDetails?.institutionEntityId ", jobDetailsId?.institutionEntityId )}
                            {jobDetailsInfo?.institutionEntityId && (
                              <GetProfileImage
                                id={jobDetailsInfo?.institutionEntityId}
                                type={"jobList"}
                              />
                            )}
                            {/* <JobZImage src="images/jobs-company/pic1.jpg" alt="#" /> */}
                          </div>
                          {jobDetailsInfo?.isPrivateJob && (<><span className="job_tag twm-job-title" style={{ "backgroundColor": "#E64C4E" }} ><i className="fas fa-lock" style={{ "marginRight": "10px" }} />
                            {jobDetailsInfo?.isPrivateJob ? "Private" : ""}
                          </span>
                            <p></p>
                          </>
                          )}
                          <h4 className="twm-job-title">
                            {jobDetailsInfo?.jobTitle}
                            <span className="twm-job-post-duration">
                              / {getTimeDifference(jobDetailsInfo?.createdDate)}
                            </span>
                          </h4>
                          <p className="twm-job-address">
                            <i className="feather-map-pin" />
                            {/* {jobDetailsInfo?.address || ""} */}
                            {formatAddress(
                            jobDetailsInfo?.address,
                            jobDetailsInfo?.city,
                            jobDetailsInfo?.state,
                            jobDetailsInfo?.zipCode
                          )}
                          </p>
                          <div className="twm-job-self-mid">
                            {/* <div className="twm-job-self-mid-left">
                              <div className="twm-jobs-amount">
                              <span>Hourly Wage &nbsp;</span>

                                ${(jobDetailsInfo?.salary / 100).toFixed(2)}
                                <span>/ hr</span>
                              </div>
                            </div> */}
                            {/* {role_type === "ROLE_OPENSTAFF_ADMIN" && (
                              <>
                                <div className="twm-job-self-mid-left">
                                  <div className="twm-jobs-amount">
                                    <span>Service Fee &nbsp;</span>$
                                    {(jobDetailsInfo?.serviceFee / 100).toFixed(
                                      2
                                    )}
                                  </div>
                                </div>
                                <div className="twm-job-self-mid-left">
                                  <div className="twm-jobs-amount">
                                    <span>Booking Fee &nbsp;</span>$
                                    {(
                                      jobDetailsInfo?.bookingAndProcessingFee /
                                      100
                                    ).toFixed(2)}
                                  </div>
                                </div>
                                <div className="twm-job-self-mid-left">
                                  <div className="twm-jobs-amount">
                                    <span>Total Charges &nbsp;</span>$
                                    {(
                                      jobDetailsInfo?.totalCharges /
                                      100
                                    ).toFixed(2)}
                                  </div>
                                </div>
                              </>
                            )} */}
                            {/* <div className="twm-job-apllication-area">
                              Application ends :
                              <span className="twm-job-apllication-date">
                                October 1, 2025
                              </span>
                            </div> */}
                          </div>
                          {role_type.includes("ROLE_USER") &&
                            jobStatus === "eOpen" && (
                              <div className="twm-job-self-bottom">
                                
                                {userInfo?.id ? (
                                  <button
                                    className="site-button"
                                    role="button"
                                    onClick={handleApplyJob}
                                  >
                                    {isLoadingApplyJob ? (
                                      <Spinner />
                                    ) : (
                                      " Apply Now"
                                    )}
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          {role_type?.length === 0 && (
                            <div className="twm-job-self-bottom">
                              <a
                                className="site-button"
                                data-bs-toggle="modal"
                                href={"#sign_up_popup2"}
                                role="button"
                              >
                                Apply Now
                              </a>
                            </div>
                          )}
                          {role_type.includes("ROLE_USER") &&
                            jobStatus == "eApplied" && (
                              <div className="twm-job-self-bottom">
                                {console.log(
                                  "jobDetailsInfoId==========",
                                  jobDetailsInfo?.id
                                )}
                                <a
                                  className="site-button"
                                  data-bs-toggle="modal"
                                  href={
                                    "#withdraw_job_seeker_confirmation_popup"
                                  }
                                  role="button"
                                  onClick={() =>
                                    openDeletionConfirmation(jobDetailsInfo?.id)
                                  }
                                >
                                  Withdraw
                                </a>
                              </div>
                            )}
                          {role_type.includes("ROLE_INSTITUTION_ADMIN") &&
                            jobStatus == "eInProgress" && (
                              <div className="twm-job-self-bottom">
                              <a
                                className="site-button"
                                href={"#job-review-popup"}
                                role="button"
                                ref={approveBtnRef} // Reference to the button
                                onClick={(e) => handleApproveAndFinish(e)} // Handle the click event
                              >
                                  Approve and Finish
                                </a>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {role_type.includes("ROLE_USER") &&
                    (jobDetailsInfo?.jobStatus === "eAccepted" ||
                      jobDetailsInfo?.jobStatus === "eInProgress" ||
                      jobDetailsInfo?.jobStatus === "eCompleted") && (
                      <ClockInClockOut jobDetailsInfo={jobDetailsInfo} getJobDetailsData={getJobDetailsData}/>
                      //   <ClockInClockOutAdmin
                      //   clockinClockoutLoader={clockinClockoutLoader}
                      //   clockinClockoutList={clockinClockoutList}
                      // />
                    )}
                  {(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")) && (
                    <div className="tab-content" id="myTab2Content">
                      <div
                        className="tab-pane fade show active"
                        id="applied-applicant"
                      >
                        <h4 className="twm-s-title">Applicants:</h4>
                        {/* {appliedApplicantList?.length > 0 ? ( */}
                        <AppliedApplicantsTable
                          jobDetailId={jobDetailId}
                          appliedApplicantList={appliedApplicantList}
                          appliedApplicantLoader={appliedApplicantLoader}
                          appliedApplicantRowPerPage={
                            appliedApplicantRowPerPage
                          }
                          handleAppliedApplicantRecordsPerPageChange={
                            handleAppliedApplicantRecordsPerPageChange
                          }
                          appliedApplicantListPaginationData={
                            appliedApplicantListPaginationData
                          }
                          appliedApplicantCurrentPage={
                            appliedApplicantCurrentPage
                          }
                          setappliedApplicantCurrentPage={
                            setappliedApplicantCurrentPage
                          }
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                          setAppliedApplicantSearchKey={
                            setAppliedApplicantSearchKey
                          }
                          handleAppliedChat={handleAppliedChat}
                          jobDetailsInfo={jobDetailsInfo}
                        />
                        {/* ) : (
                          <div className="tw-faq-section mt-5">
                            <div
                              className="accordion tw-faq"
                              id="sf-faq-accordion-2"
                            >
                              <h3 style={{ color: "red" }}>
                                No applied applicant list available!
                              </h3>
                            </div>
                          </div>
                        )} */}
                      </div>
                      <div className="tab-pane fade" id="invite-applicant">
                        {/* <h4 className="twm-s-title">Invite:</h4> */}
                        <a
                          className="site-button m-b10"
                          data-bs-toggle="modal"
                          href="#invitation_popup"
                          role="button"
                        >
                          Invitation
                        </a>
                        <InvitationPopup
                          invitationList={invitationList}
                          invitationLoader={invitationLoader}
                          invitationListRowPerPage={invitationListRowPerPage}
                          handleInvitationListRecordsPerPageChange={
                            handleInvitationListRecordsPerPageChange
                          }
                          invitationListPaginationData={
                            invitationListPaginationData
                          }
                          invitationCurrentPage={invitationCurrentPage}
                          setInvitationCurrentPage={setInvitationCurrentPage}
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                          jobDetailId={jobDetailId}
                        />
                        {notificationList?.length > 0 && (
                          <NotificationTable
                            notificationList={notificationList}
                            notificationLoader={notificationLoader}
                            appliedApplicantRowPerPage={
                              appliedApplicantRowPerPage
                            }
                            handleNotificationListRecordsPerPageChange={
                              handleNotificationListRecordsPerPageChange
                            }
                            notificationListPaginationData={
                              notificationListPaginationData
                            }
                            notificationListCurrentPage={
                              notificationListCurrentPage
                            }
                            setNotificationListCurrentPage={
                              setNotificationListCurrentPage
                            }
                            tableFilter={tableFilter}
                            setTableFilter={setTableFilter}
                          />
                        )}
                      </div>

                      <div className="tab-pane fade" id="clockin-clockout">
                        <ClockInClockOut jobDetailsInfo={jobDetailsInfo} getJobDetailsData={getJobDetailsData}/>

                       {/* <h4 className="twm-s-title">Clock In/Clock Out:</h4>
                         {jobDetailsInfo?.multipleDayJob ? (
                          <div className="row m-b10">
                            <div className="col-md-6 col-12">
                              <b>Start Date: </b>
                              {moment
                                (
                                  jobDetailsInfo?.startTime,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                                .local()
                                .format("MM-DD-YYYY")}
                            </div>
                            <div className="col-md-6 col-12">
                              <b>End Date: </b>
                              {moment
                                (
                                  jobDetailsInfo?.endTime,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                                .local()
                                .format("MM-DD-YYYY")}
                            </div>
                            <div className="col-md-6 col-12">
                              <b>Work hours per day: </b>{" "}
                              {jobDetailsInfo?.hoursPerDay}
                            </div>
                            <div className="col-md-6 col-12">
                              <b>Logged Hours: </b>{" "}
                              {jobDetailsInfo?.totalWorkedHours}
                            </div>
                          </div>
                        ) : (
                          <div className="row m-b10">
                            <div className="col-md-6 col-12">
                              <b>Date: </b>
                              {moment
                                .utc(
                                  jobDetailsInfo?.startTime,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                                .local()
                                .format("MM-DD-YYYY")}
                            </div>
                            <div className="col-md-6 col-12">
                              <b>Total working hours: </b>
                              {calculateTotalWorkingHour(jobDetailsInfo)}
                            </div>
                            <div className="col-md-6 col-12">
                              <b>Job Start: </b>
                              {moment
                                .utc(
                                  jobDetailsInfo?.startTime,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                                .local()
                                .format("hh:mm A")}
                            </div>
                            <div className="col-md-6 col-12">
                              <b>Job End: </b>
                              {moment
                                .utc(
                                  jobDetailsInfo?.endTime,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                                .local()
                                .format("hh:mm A")}
                            </div>
                          </div>
                        )}
                        {clockinClockoutList?.length > 0 ? (
                          <ClockInClockOutAdmin
                            clockinClockoutLoader={clockinClockoutLoader}
                            clockinClockoutList={clockinClockoutList}
                          />
                        ) : (
                          <div className="tw-faq-section mt-5">
                            <div
                              className="accordion tw-faq"
                              id="sf-faq-accordion-2"
                            >
                              <h3 style={{ color: "red" }}>
                                No Job History Found!
                              </h3>
                            </div>
                          </div>
                        )} */}
                      </div>
                      {/* {jobDetailsInfo?.featuredJob !== undefined && ( */}
                      <div className="tab-pane fade" id="add-to-feature">
                        <AddToFeatureJob
                          jobDetailId={jobDetailId}
                          featuredJob={jobDetailsInfo?.featuredJob}
                        />
                      </div>

                      <div className="tab-pane fade" id="payment-status">
                        {/* <AdminPaymentTransfer
                          // jobDetailId={jobDetailId}
                          // featuredJob={jobDetailsInfo?.featuredJob}
                        /> */}
                      </div>
                      {/* )} */}
                    </div>
                  )}
                  {role_type.includes("ROLE_INSTITUTION_ADMIN") && (
                    <div className="tab-content" id="myTab2Content">
                      {jobDetailsInfo.jobStatus != "eInProgress" && jobDetailsInfo.jobStatus != "eAccepted" && jobDetailsInfo.jobStatus != "eCompleted"  && (
                        <div
                          className="tab-pane fade show active"
                          id="applied-applicant"
                        >
                          <h4 className="twm-s-title">Applicants:</h4>
                          {/* {appliedApplicantList?.length > 0 ? ( */}
                          <AppliedApplicantsTable
                            jobDetailId={jobDetailId}
                            appliedApplicantList={appliedApplicantList}
                            appliedApplicantLoader={appliedApplicantLoader}
                            appliedApplicantRowPerPage={
                              appliedApplicantRowPerPage
                            }
                            handleAppliedApplicantRecordsPerPageChange={
                              handleAppliedApplicantRecordsPerPageChange
                            }
                            appliedApplicantListPaginationData={
                              appliedApplicantListPaginationData
                            }
                            appliedApplicantCurrentPage={
                              appliedApplicantCurrentPage
                            }
                            setappliedApplicantCurrentPage={
                              setappliedApplicantCurrentPage
                            }
                            tableFilter={tableFilter}
                            setTableFilter={setTableFilter}
                            setAppliedApplicantSearchKey={
                              setAppliedApplicantSearchKey
                            }
                            handleAppliedChat={handleAppliedChat}
                            jobDetailsInfo={jobDetailsInfo}
                          />
                        </div>
                      )}
                      {
                        (jobDetailsInfo.jobStatus !== 'eAccepted' && jobDetailsInfo.jobStatus !== 'eOpen') && 
                        <div 
                        // className="tab-pane fade"
                        className={`tab-pane fade ${role_type.includes('ROLE_INSTITUTION_ADMIN') ? 'active show' : ''}`}
                        // className={`tab-pane fade ${
                        //   role_type === 'ROLE_INSTITUTION_ADMIN' && (jobDetailsInfo.jobStatus !== 'eAccepted' && jobDetailsInfo.jobStatus !== 'eOpen')
                        //     ? 'active show'
                        //     : ''
                        // }`}
                         id="clockin-clockout">
                        <ClockInClockOut jobDetailsInfo={jobDetailsInfo} getJobDetailsData={getJobDetailsData}/>
                        {/* <h4 className="twm-s-title">Clock In/Clock Out:</h4>
                        {jobDetailsInfo?.multipleDayJob ? (
                          <div className="row m-b10">
                            <div className="col-md-6 col-12">
                              <b>Start Date: </b>
                              {moment
                                (
                                  jobDetailsInfo?.startTime,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                                .local()
                                .format("MM-DD-YYYY")}
                            </div>
                            <div className="col-md-6 col-12">
                              <b>End Date: </b>
                              {moment
                                (
                                  jobDetailsInfo?.endTime,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                                .local()
                                .format("MM-DD-YYYY")}
                            </div>
                            <div className="col-md-6 col-12">
                              <b>Work hours per day: </b>{" "}
                              {jobDetailsInfo?.hoursPerDay}
                            </div>
                            <div className="col-md-6 col-12">
                              <b>Logged Hours: </b>{" "}
                              {jobDetailsInfo?.totalWorkedHours}
                            </div>
                          </div>
                        ) : (
                          <div className="row m-b10">
                            <div className="col-md-6 col-12">
                              <b>Date: </b>
                              {moment
                                .utc(
                                  jobDetailsInfo?.startTime,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                                .local()
                                .format("MM-DD-YYYY")}
                            </div>
                            <div className="col-md-6 col-12">
                              <b>Total working hours: </b>
                              {calculateTotalWorkingHour(jobDetailsInfo)}
                            </div>
                            <div className="col-md-6 col-12">
                              <b>Job Start: </b>
                              {moment
                                .utc(
                                  jobDetailsInfo?.startTime,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                                .local()
                                .format("hh:mm A")}
                            </div>
                            <div className="col-md-6 col-12">
                              <b>Job End: </b>
                              {moment
                                .utc(
                                  jobDetailsInfo?.endTime,
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                                .local()
                                .format("hh:mm A")}
                            </div>
                          </div>
                        )}
                        {clockinClockoutList?.length > 0 ? (
                          <ClockInClockOutAdmin
                            clockinClockoutLoader={clockinClockoutLoader}
                            clockinClockoutList={clockinClockoutList}
                            jobDetailsInfo={jobDetailsInfo}
                          />
                        ) : (
                          <div className="tw-faq-section mt-5">
                            <div
                              className="accordion tw-faq"
                              id="sf-faq-accordion-2"
                            >
                              <h3 style={{ color: "red" }}>
                                No Job History Found!
                              </h3>
                            </div>
                          </div>
                        )} */}
                      </div>
                      }
                     
                    </div>
                  )}

                  {/* <ul className="description-list-2">
                    <li>
                      <i className="feather-check" />
                      Must be able to communicate with others to convey
                      information effectively.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Personally passionate and up to date with current trends
                      and technologies, committed to quality and comfortable
                      working with adult media.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Rachelor or Master degree level educational background.
                    </li>
                    <li>
                      <i className="feather-check" />4 years relevant PHP dev
                      experience.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Troubleshooting, testing and maintaining the core product
                      software and databases.
                    </li>
                  </ul> */}
                  {/* <ul className="description-list-2">
                    <li>
                      <i className="feather-check" />
                      Establish and promote design guidelines, best practices
                      and standards.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Accurately estimate design tickets during planning
                      sessions.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Partnering with product and engineering to translate
                      business and user goals into elegant and practical
                      designs. that can deliver on key business and user
                      metrics.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Create wireframes, storyboards, user flows, process flows
                      and site maps to communicate interaction and design.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Present and defend designs and key deliverables to peers
                      and executive level stakeholders.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Execute all visual design stages from concept to final
                      hand-off to engineering.
                    </li>
                  </ul> */}

                  <SectionShareProfile jobUrl={`https://www.openstaffamp.digitalglyde.com/job-detail/${jobDetailsInfo?.id}`} jobTitle={jobDetailsInfo?.jobTitle}/>
                  
                  {/* <SectionJobLocation jobDetailsInfo={jobDetailsInfo} /> */}

                  {/* <div className="twm-two-part-section">
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <SectionOfficePhotos1 />
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <SectionOfficeVideo1 />
                      </div>
                    </div>
                  </div> */}
                </div>
                {((jobDetailsInfo?.jobStatus == "eAccepted" || jobDetailsInfo?.jobStatus == "eInProgress"||jobStatus=="eApplied") && (!role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")))&&(<>
                  <h4 className="twm-s-title">Chat:</h4>
                <div className="twm-right-section-panel site-bg-gray">
                <div className="wt-admin-dashboard-msg-2  twm-dashboard-style-2">
                  {jobDetailsInfo?.id&&
                    <SectionCanChat jobId={jobDetailsInfo?.id} jobItem={jobDetailsInfo} forApplied={jobStatus=="eApplied"?true:appliedApplicantList?.length>0?true:false}/>
                  }
                </div>
            </div>
            </>)
}
              </div>
              <div className="col-lg-4 col-md-12 rightSidebar">
                <SectionJobsSidebar2
                  _config={sidebarConfig}
                  jobDetailsInfo={jobDetailsInfo}
                  seekerRating={seekerRating}
                  seekerReview={seekerReview}
                  posterRating={posterRating}
                  posterReview={posterReview}
                  jobDetailId={jobDetailId}
                  jobRating={role_type.includes("ROLE_USER") ? posterRating : 0}
                  selectedFields={selectedFields}
                  ansValues={ansValues}
                  setErrSuccessMsg={setErrSuccessMsg}
                  getJobDetailsData={getJobDetailsData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ApplyJobPopup />
      
      <ConfirmationWithdrawJobSeekerPopUp jobId={jobId} />
      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default JobDetail1Page;
