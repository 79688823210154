import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SectionPagination from "../../../public-user/sections/common/section-pagination";
import moment from "moment";
import GetProfileImage from "../../../../common/getProfileImage";
import TableLoader from "../../../../common/tableLoader";
import apiCall from "../../../../../apicall";
import Toast from "../../../../common/toast";
import Spinner from "../../../../common/spinner";

function SectionApplicantsList({
  isLoading,
  applicantsListData,
  currentPage,
  setCurrentPage,
  totalPages,
  isHiredSeekerList,
  fetchTrustedWorkersList,
  fetchHiredJobSeeker,
  trustedWorker
}) {
  console.log("applicantsListData", applicantsListData);
  const { TOKEN } = useSelector((state) => state.menu);
  const [isAddTrustedWorkerLoading, setIsAddTrustedWorkerLoading] = useState(null);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const handleAddTrustedWorker = async (applicantId, item) => {
    try {
      setIsAddTrustedWorkerLoading(applicantId);
      const api = `institutions/addTrustedWorkers`;
      const addTrustedWorkerResponse = await apiCall(
        api,
        "PUT",
        applicantId,
        TOKEN,
        false,
        false,
        true
      );

      console.log("addTrustedWorkerResponse---", addTrustedWorkerResponse);
      if (addTrustedWorkerResponse?.status === 200) {
        setErrSuccessMsg({
          message: `${item?.firstName} ${item?.lastName} has been added.`,
          status: true,
        });
        fetchHiredJobSeeker();
        fetchTrustedWorkersList();
      } else if (addTrustedWorkerResponse?.response?.status == 401) {
        setErrSuccessMsg({
          message: "Your session has been expired!!",
          status: false,
        });
      } else {
        setErrSuccessMsg({
          message: addTrustedWorkerResponse?.response?.data?.errorMessage,
          status: false,
        });
      }
    } catch (err) {
      console.log("errrrr",err)
      setErrSuccessMsg({
        message: "Network Error",
        status: false,
      });
    } finally {
      setIsAddTrustedWorkerLoading(null);
    }
  };

  return (
    <>
      <div className="twm-jobs-list-wrap">
        <ul>
          {!isLoading ? (
            applicantsListData?.length > 0 ? (
              applicantsListData?.map((item) => (
                <li key={item.id}>
                  <div className="twm-jobs-list-style1 mb-5">
                    <div className="twm-media">
                      <GetProfileImage id={isHiredSeekerList ? item?.userEntityId : item?.id} type={"userAvatar"} />
                    </div>
                    <div className="twm-mid-content">
                      <NavLink to="#" className="twm-job-title">
                        <h4>
                          {`${item?.firstName} ${item?.lastName}`}
                          {/* <span className="twm-job-post-duration">
                              / {item?.userName}
                            </span> */}
                        </h4>
                      </NavLink>
                      {!isHiredSeekerList ? (
                        <>
                          <span className="twm-job-address">{item?.email}</span>
                          <p className="twm-job-address">
                            Created On:{" "}
                            {moment(item?.roleEntities[0]?.createdDate).format(
                              "MM/DD/YYYY"
                            )}
                          </p>
                          {item?.institutionName != "" ? (
                            <span className="twm-job-address">
                              Institution Name: {item.institutionName}
                            </span>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <div style={{ marginTop: "60px"}}></div>
                      )}
                    </div>

                    <div className="twm-right-content">
                      {!isHiredSeekerList ? (
                        <>
                          <div className="twm-jobs-category green">
                            <span
                              className={
                                item?.accountStatus === "VERIFIED"
                                  ? "twm-bg-green"
                                  : "twm-bg-brown"
                              }
                            >
                              {item?.accountStatus}
                            </span>
                          </div>

                          <div className="twm-jobs-amount">
                            {item?.roleEntities[0]?.roleType}
                          </div>
                        </>
                      ) : (
                        <div className="twm-jobs-category green">
                          <a
                            className="site-button button-sm"
                            style={{display: 'inline-block', width: '3rem'}}
                            role="button"
                            onClick={() =>
                              handleAddTrustedWorker(item?.userEntityId, item)
                            }
                          >
                           {isAddTrustedWorkerLoading === item?.userEntityId ? (
                              <Spinner />
                            ) : (
                              "Add"
                            )}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              ))
            ) : (
              "No results found."
            )
          ) : (
            <TableLoader />
          )}
        </ul>
      </div>
      <SectionPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default SectionApplicantsList;
