// import { Navigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { publicUser } from "../globals/route-names";

// const ProtectedRoute = ({ children, allowedRoles }) => {
//   const { isLoggedIn, role_type } = useSelector((state) => state.menu);
//   const hasAddressSelectedRole = allowedRoles?.includes('IS_ADDRESS_SELECTED');


//   console.log("hasAddressSelectedRole",hasAddressSelectedRole)
//   if (!isLoggedIn && !hasAddressSelectedRole) {
//     return <Navigate to={publicUser.INITIAL} />;
//   }

//   if (allowedRoles&& !allowedRoles.includes(role_type) && !hasAddressSelectedRole) {
//     return <Navigate to={publicUser.pages.ERROR404} />;
//   }

//   return children;
// };

// export default ProtectedRoute;

import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { publicUser, candidate } from "../globals/route-names";

const ProtectedRoute = ({ children, allowedRoles, path }) => {
  const { isLoggedIn, role_type, userInfo } = useSelector((state) => state.menu);
  const hasAddressSelectedRole = allowedRoles?.includes('IS_ADDRESS_SELECTED');

  console.log("hasAddressSelectedRole", hasAddressSelectedRole);
  // Check if role_type array contains any of the allowedRoles
  const hasAllowedRole = role_type?.some((role) => allowedRoles?.includes(role));

    // Special condition for candidate.DOCUMENTS
    if ((path === candidate.DOCUMENTS || path === candidate.BANK_DETAILS) && role_type?.includes("ROLE_USER") && userInfo?.isPrivateEmployee) {
      return <Navigate to={publicUser.INITIAL} />; // Redirect to a different page if the condition is met
    }

  if (!isLoggedIn && !hasAddressSelectedRole) {
    return <Navigate to={publicUser.INITIAL} />;
  }

  console.log("hasAddressSelectedRole", hasAllowedRole,role_type,!hasAddressSelectedRole,allowedRoles,!hasAllowedRole);

  if (allowedRoles && !hasAllowedRole && !hasAddressSelectedRole) {
    return <Navigate to={publicUser.pages.ERROR404} />;
  }

  return children;
};

export default ProtectedRoute;

