import { useEffect, useState } from "react";
import JobZImage from "../../../../common/jobz-img";
import SectionEmployersCandidateSidebar from "../../sections/common/section-emp-can-sidebar";
import SectionShareProfile from "../../sections/common/section-share-profile";
import SectionOfficePhotos1 from "../../sections/common/section-office-photos1";
import SectionOfficeVideo1 from "../../sections/common/section-office-video1";
import SectionAvailableJobsList from "../../sections/employers/detail/section-available-jobs-list";
import SectionJobsSidebar2 from "../../sections/jobs/sidebar/section-jobs-sidebar2";
import apiCall from "../../../../../apicall";
import { useLocation } from "react-router-dom";
import { loadScript } from "../../../../../globals/constants";
import { useSelector } from "react-redux";
import GetProfileImage from "../../../../common/getProfileImage";
import TableLoader from "../../../../common/tableLoader";
import AppliedApplicantsTable from "../jobs/appliedApplicantsTable";
import SectionJobLocation from "../../sections/jobs/detail/section-job-location";
import EmpPaymentList from "./empPaymentList";
import EmpLocationList from "./empLocationList";
import EmpUsersList from "./empUsersList";
import EmpJobList from "./empJobList";
import Spinner from "../../../../common/spinner";
import BASE_URL from "../../../../../config";
import axios from "axios";
import SessionExpired from "../../../../common/sessionExpired";
import Toast from "../../../../common/toast";
import AddLocation from "./addLocation";
import CanPaymentDetailsPage from "../../../candidate/components/can-payment-details-jobs";
import SectionJobsGridMap from "../../sections/jobs/section-jobs-grid-map";
import SectionJobsList from "../../sections/jobs/section-jobs-list";
import AddUser from "./adminAddUser";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../globals/fomat-comapny-name";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

function EmployersDetail1Page() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { role_type , TOKEN, userInfo, employerDetails } = useSelector((state) => state.menu);
  // const [currentPage, setCurrentPage] = useState(1);
  const [paymentCurrentPage, setPaymentCurrentPage] = useState(1);
  const [locationCurrentPage, setLocationCurrentPage] = useState(1);
  const [usersCurrentPage, setUsersCurrentPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [rowPaymentPerPage, setPaymentRowPerPage] = useState(10);
  const [rowLocationPerPage, setLocationRowPerPage] = useState(10);
  const [rowJobsPerPage, setJobsRowPerPage] = useState(10);
  const [rowUsersPerPage, setUsersRowPerPage] = useState(10);
  const [empDetailId, setEmpDetailId] = useState(
    employerDetails?.id ? employerDetails?.id : location?.state?.id ? location?.state?.id : ""
  );
  const [sessionExpired, setSessionExpired] = useState(false);
  const [empDetailsInfo, setEmpDetailsInfo] = useState({});
  const [empPaymentInfo, setEmpPaymentInfo] = useState({});
  const [empLocationInfo, setEmpLocationInfo] = useState({});
  const [empUsersInfo, setEmpUsersInfo] = useState({});
  const [empJobsInfo, setEmpJobsInfo] = useState({});
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [locationLoader, setLocationLoader] = useState(false);
  const [usersLoader, setUsersLoader] = useState(false);
  const [jobsLoader, setJobsLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(
    (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN")) 
      ? '#location-employer'
      : role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") ? '#payment-employer' 
      : ''
  );
  const [paginationData, setPaginationData] = useState({
    totalElements: 0,
    totalPages: 0,
  });
  const [paginationPaymentData, setPaginationPaymentData] = useState({
    totalElements: 0,
    totalPages: 0,
  });
  const [paginationLocationData, setPaginationLocationData] = useState({
    totalElements: 0,
    totalPages: 0,
  });
  const [paginationUsersData, setPaginationUsersData] = useState({
    totalElements: 0,
    totalPages: 0,
  });
  const [paginationJobsData, setPaginationJobsData] = useState({
    totalElements: 0,
    totalPages: 0,
  });

  const [tableFilter, setTableFilter] = useState({
    sortType: "createdDate",
    sortDirection: "desc",
    status: "",
    searchKey: "",
    // jobType: "",
    // applicant: "",
    // customer: "",
    // categoryType: "All",
  });

  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const sidebarConfig = {
    showEmpInfo: true,
    empTitle: true,
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };
  const handleUploadButtonClick = () => {
    // Trigger file input click when the "Upload Photo" button is clicked
    document.getElementById("fileInput").click();
  };
  const handleUpload = async () => {
    if (selectedFile) {
      //   const updateAvatarResponse = await apiCall("avatar", "PUT", selectedFile, TOKEN);
      //     // You can add code here to upload the selectedFile to your server or storage
      //     console.log("Uploading image:", updateAvatarResponse);

      try {
        setIsLoading(true);
        const url = `openstaff/institutions/${empDetailsInfo?.id}/logo`;

        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("fileType", "ePng");
        formData.append("customerId", empDetailsInfo?.id);
        const config = {
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ${TOKEN}`,
          },
        };

        // const response = await axios.post(url, formData, config);
        const response = await apiCall(url, "POST", formData, TOKEN, true);
        console.log("Upload successful:", response);

        if (response?.status == 200) {
          setIsLoading(false);
          setSessionExpired(false);
          setErrSuccessMsg({
            message: "Profile image updated successfully!",
            status: true,
          });
          getEmpDetailsData();
        } else if (response?.response?.status == 401) {
          setSessionExpired(true);
          displayErrMsg("Your session has been expired!!");
        } else {
          // displayErrMsg("Something went wrong!");
          displayErrMsg(response?.response?.data?.message)
        }
      } catch (error) {
        displayErrMsg("Something went wrong!");
        console.error("Upload failed:", error);
        // Handle failure response if needed
      }
    } else {
      console.log("No file selected.");
    }
  };
  const displayEmpDetailsErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setEmpDetailsInfo({});
  };
  const displayEmpPaymentErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setEmpPaymentInfo({});
    console.log("error pagination");
    setPaginationPaymentData({
      totalElements: 0,
      totalPages: 0,
    });
    setPaymentLoader(false);
  };
  const displayEmpLocationErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setEmpLocationInfo({});
    setPaginationLocationData({
      totalElements: 0,
      totalPages: 0,
    });
    setLocationLoader(false);
  };
  const displayEmpUsersErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setEmpUsersInfo({});
    setPaginationUsersData({
      totalElements: 0,
      totalPages: 0,
    });
    setUsersLoader(false);
  };

  const handleRecordsPerPageChange = (event) => {
    setCurrentPage(1);
    setRowPerPage(event.target.value);
  };
  const handleRecordsPaymentPerPageChange = (event) => {
    setPaymentCurrentPage(1);
    setPaymentRowPerPage(event.target.value);
  };
  const handleRecordsLocationPerPageChange = (event) => {
    setLocationCurrentPage(1);
    setLocationRowPerPage(event.target.value);
  };
  const handleRecordsUsersPerPageChange = (event) => {
    setUsersCurrentPage(1);
    setUsersRowPerPage(event.target.value);
  };
  const handleRecordsJobsPerPageChange = (event) => {
    setUsersCurrentPage(1);
    setUsersRowPerPage(event.target.value);
  };

  const getEmpDetailsData = async () => {
    try {
      const api = `${
        (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN"))
          && `openstaff/institutions/${empDetailId}/institutionDetails`
      }`;
      const empDetailsResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("empDetailsResponse---", empDetailsResponse);
      console.log("empDetailsInfo=====", empDetailsInfo);
      console.log("empDetailId", empDetailId);
      if (empDetailsResponse?.data?.id) {
        setSessionExpired(false);
        setEmpDetailsInfo(empDetailsResponse.data);
        await logEvent_Analytics('emp_detail_web', {
          param1 : `${empDetailsResponse?.data?.firstName} ${empDetailsResponse?.data?.lastName}`, 
          param2 : empDetailId, 
          param3 : userInfo?.id
        });
      } else if (empDetailsResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayEmpDetailsErrMsg("Your session has been expired!!");
      } else {
        setSessionExpired(false);
        // displayEmpDetailsErrMsg("Something went wrong!");
        displayEmpDetailsErrMsg(empDetailsResponse?.response?.data?.message)
      }
    } catch (err) {
      setSessionExpired(false);
      displayEmpDetailsErrMsg("Something went wrong!");
    }
  };
  const getEmpPaymentData = async () => {
    try {
      setPaymentLoader(true);
      const api = `${
        (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN"))
          && `openstaff/institutions/${empDetailId}/paymentDetails?status=All&customerId=${empDetailId}&offset=0&page=${
              paymentCurrentPage - 1
            }&size=${rowPaymentPerPage}&searchkey=${tableFilter?.searchKey}`
      }`;
      const empPaymentResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("empPaymentResponse---", empPaymentResponse);

      if (empPaymentResponse?.data.content?.length > 0) {
        setSessionExpired(false);
        setEmpPaymentInfo(empPaymentResponse.data.content);
        setPaginationPaymentData({
          totalElements: empPaymentResponse.data.totalElements,
          totalPages: empPaymentResponse.data.totalPages,
        });
        setPaymentLoader(false);
      } else if (empPaymentResponse?.data.content?.length == 0) {
        setEmpPaymentInfo({});
        setPaginationPaymentData({
          totalElements: 0,
          totalPages: 0,
        });
        setPaymentLoader(false);
      } else if (empPaymentResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayEmpPaymentErrMsg("Your session has been expired!!");
      } else {
        setSessionExpired(false);
        // displayEmpPaymentErrMsg("Something went wrong!");
        displayEmpPaymentErrMsg(empPaymentResponse?.response?.data?.message)
      }
    } catch (err) {
      setSessionExpired(false);
      displayEmpPaymentErrMsg("Something went wrong!");
    }
  };
  const getEmpLocationData = async () => {
    try {
      setLocationLoader(true);
      const api = `${
        (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN"))
          && `openstaff/institutions/${empDetailId}/locations?offset=0&sort=${
              tableFilter?.sortType
            },${tableFilter?.sortDirection}&page=${
              locationCurrentPage - 1
            }&size=${rowLocationPerPage}`
      }`;
      const empLocationResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("empLocationResponse---", empLocationResponse);

      if (empLocationResponse?.data.content?.length > 0) {
        setSessionExpired(false);
        setEmpLocationInfo(empLocationResponse.data.content);
        setPaginationLocationData({
          totalElements: empLocationResponse.data.totalElements,
          totalPages: empLocationResponse.data.totalPages,
        });
        setLocationLoader(false);
      } else if (empLocationResponse?.data.content?.length == 0) {
        setEmpLocationInfo({});
        setPaginationLocationData({
          totalElements: 0,
          totalPages: 0,
        });
        setLocationLoader(false);
      } else if (empLocationResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayEmpLocationErrMsg("Your session has been expired!!");
      } else {
        setSessionExpired(false);
        // displayEmpLocationErrMsg("Something went wrong!");
        displayEmpLocationErrMsg(empLocationResponse?.response?.data?.message)
      }
    } catch (err) {
      setSessionExpired(false);
      displayEmpLocationErrMsg("Something went wrong!");
    }
  };

  const getJobsData = async () => {
    try {
      setJobsLoader(true);
      const api = `${
        (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN"))
          && `openstaff/jobs/${empDetailId}/institutionsJobs?offset=0&sortBy=${
            tableFilter?.sortType
          }&sortDirection=${tableFilter?.sortDirection}&customerId=${empDetailId}&page=${
              currentPage - 1
            }&size=${rowJobsPerPage}&searchKey=${tableFilter?.searchKey}`
      }`;
      const empJobsResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("empJobsResponse---", empJobsResponse);

      if (empJobsResponse?.data.content?.length > 0) {
        setSessionExpired(false);
        setEmpJobsInfo(empJobsResponse.data.content);
        setPaginationJobsData({
          totalElements: empJobsResponse.data.totalElements,
          totalPages: empJobsResponse.data.totalPages,
        });
        setJobsLoader(false);
      } else if (empJobsResponse?.data.content?.length == 0) {
        setEmpJobsInfo({});
        setPaginationJobsData({
          totalElements: 0,
          totalPages: 0,
        });
        setJobsLoader(false);
      } else if (empJobsResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayEmpLocationErrMsg("Your session has been expired!!");
      } else {
        setSessionExpired(false);
        displayEmpLocationErrMsg("Something went wrong!");
      }
    } catch (err) {
      setSessionExpired(false);
      displayEmpLocationErrMsg("Something went wrong!");
    }
  };
  const formatAddress = (address, city, state, zipCode) => {
    const parts = [address, city, state, zipCode].filter(Boolean);
    return parts.join(", ");
  };
  const getEmpUsersData = async () => {
    try {
      setUsersLoader(true);
      const api = `${
        role_type.includes("ROLE_OPENSTAFF_ADMIN")
          && `openstaff/institutions/${empDetailId}/users?offset=0&page=${
              locationCurrentPage - 1
            }&size=${rowUsersPerPage}`
      }`;
      const empUsersResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("empUsersResponse---", empUsersResponse);

      if (empUsersResponse?.data.content?.length > 0) {
        setSessionExpired(false);
        setEmpUsersInfo(empUsersResponse.data.content);
        setPaginationUsersData({
          totalElements: empUsersResponse.data.totalElements,
          totalPages: empUsersResponse.data.totalPages,
        });
        setUsersLoader(false);
      } else if (empUsersResponse?.data.content?.length == 0) {
        setEmpUsersInfo({});
        setPaginationUsersData({
          totalElements: 0,
          totalPages: 0,
        });
        setUsersLoader(false);
      } else if (empUsersResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayEmpUsersErrMsg("Your session has been expired!!");
      } else {
        setSessionExpired(false);
        // displayEmpUsersErrMsg("Something went wrong!");
        displayEmpUsersErrMsg(empUsersResponse?.response?.data?.message)
      }
    } catch (err) {
      setSessionExpired(false);
      displayEmpUsersErrMsg("Something went wrong!");
    }
  };

  const refreshedLocationList = () =>{
    getEmpLocationData();
  }

  useEffect(() => {
    if(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN")){
      getEmpDetailsData();
    }
  }, []);
  useEffect(() => {
    if(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")){
      getEmpPaymentData();
    }
  }, [paymentCurrentPage, rowPaymentPerPage]);
  useEffect(() => {
    if(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN")){
      getEmpLocationData();
    }
  }, [locationCurrentPage, rowLocationPerPage]);
  useEffect(() => {
    if(role_type.includes("ROLE_OPENSTAFF_ADMIN")){
     getEmpUsersData();
      }
  }, [usersCurrentPage, rowUsersPerPage]);
  useEffect(() => {
    if(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")){
      getJobsData();
    }
  }, [currentPage, rowJobsPerPage, tableFilter]);

  useEffect(() => {
    loadScript("js/custom.js");
  });

  // useEffect(()=>{
  //     const button = document.querySelector('.employee-details .nav-link');
  //     console.log('Data-bs-target value:', button);

  //       if (button.classList.contains('active')) {
  //         const dataBsTarget = button.getAttribute('data-bs-target');
  //         setActiveTab(dataBsTarget)
  //         console.log('Data-bs-target value:', dataBsTarget);
  //       } else {
  //         console.log('The button does not have the active class.');
  //       }
     
  // },[activeTab])

  return (
    <>
      <div>
        <Helmet>
          <title>{`Employer Details | Employer Profile | Company Insights | ${formatCompanyName()}`}</title>
          <meta name="description" content="Welcome to the Employer Detail page, where you can find essential information about a hiring company, including their values and available job opportunities" />
          <meta name="keywords" content="employer details, hiring companies, current openings"/>
        </Helmet>
      </div>
      {/* <div className="section-full  p-t120 p-b90 bg-white">
                <div className="container">
                    <div className="section-content">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 col-md-12">
                                <div className="cabdidate-de-info">
                                    <div className="twm-employer-self-wrap">
                                        <div className="twm-employer-self-info">
                                            <div className="twm-employer-self-top">
                                                <div className="twm-media-bg">
                                                    <JobZImage src="images/employer-bg.jpg" alt="#" />
                                                </div>
                                                <div className="twm-mid-content">
                                                    <div className="twm-media">
                                                        <JobZImage src="images/jobs-company/pic1.jpg" alt="#" />
                                                    </div>
                                                    <h4 className="twm-job-title">Galaxy Software Development</h4>
                                                    <p className="twm-employer-address"><i className="feather-map-pin" />1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                                    <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-employer-websites site-text-primary">https://thewebmax.com</a>
                                                    <div className="twm-employer-self-bottom">
                                                        <a href="#" className="site-button outline-primary">Add Review</a>
                                                        <a href="#" className="site-button">Follow Us</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h4 className="twm-s-title">About Company</h4>
                                    <p>UUt enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae
                                        consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? </p>
                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui  officia deserunt mollitia animi.</p>
                                    <p>Opossum but dramatically despite expeditiously that jeepers loosely yikes that as or eel underneath kept and slept compactly far purred sure abidingly up above fitting to strident wiped set waywardly far the and pangolin horse approving paid chuckled cassowary oh above a much opposite far much hypnotically more therefore wasp less that  hey apart well like while superbly orca and far hence one.Far much that one rank beheld bluebird after outside ignobly  allegedly more when oh arrogantly vehement irresistibly fussy.? </p>
                                    <h4 className="twm-s-title">Responsabilities</h4>
                                    <ul className="description-list-2">
                                        <li>
                                            <i className="feather-check" />
                                            Establish and promote design guidelines, best practices and standards.
                                        </li>
                                        <li>
                                            <i className="feather-check" />
                                            Accurately estimate design tickets during planning sessions.
                                        </li>
                                        <li>
                                            <i className="feather-check" />
                                            Partnering with product and engineering to translate business and user goals.
                                        </li>
                                    </ul>

                                    <SectionShareProfile />

                                    <div className="twm-two-part-section">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <SectionOfficePhotos1 />
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <SectionOfficeVideo1 />
                                            </div>
                                        </div>
                                    </div>

                                    <SectionAvailableJobsList />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 rightSidebar">
                                <SectionJobsSidebar2
                                    _config={sidebarConfig}
                                    jobDetailsInfo={jobDetailsInfo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      <div className="section-full  p-t120 p-b90 bg-white">
        <div className="container">
          <div className="section-content">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8 col-md-12">
                <div className="cabdidate-de-info">
                  <div className="twm-job-self-wrap">
                    <div className="twm-job-self-info">
                      <div className="twm-job-self-top">
                        <div className="twm-media-bg">
                          {/* <JobZImage src="images/job-detail-bg.jpg" alt="#" /> */}
                          {/* <img
                            src="https://thewebmax.org/react/jobzilla/assets/images/job-detail-bg.jpg"
                            alt="#"
                          /> */}
                           {/* <SectionJobLocation jobDetailsInfo={empDetailsInfo} /> */}{console.log("empPaymentInfo-----",empPaymentInfo)}
                          {
                            activeTab.includes("#location-employer") && empLocationInfo?.length>0&&
                            <SectionJobsGridMap
                            isLoading={locationLoader}
                            jobListData={empLocationInfo}
                            height={30}
                            />
                          }
                           {
                            activeTab.includes("#payment-employer") &&
                            <SectionJobsGridMap
                            isLoading={paymentLoader}
                            jobListData={empPaymentInfo}
                            height={30}
                            />
                          }
                           {
                            activeTab.includes("#users-employer") &&
                            <SectionJobsGridMap
                            isLoading={usersLoader}
                            jobListData={empUsersInfo}
                            height={30}
                            />
                          }
                           {
                            activeTab.includes("#jobs-employer") &&
                            <SectionJobsGridMap
                            isLoading={jobsLoader}
                            jobListData={empJobsInfo}
                            height={30}
                            />
                          }
                          {/* <div className="twm-jobs-category green">
                                                        <span className="twm-bg-green">New</span>
                                                    </div> */}
                        </div>
                        <div className="twm-mid-content">
                          <div className="twm-media">
                            {/* <GetProfileImage
                                                            id={empDetailsInfo?.id}
                                                            type={"jobList"}
                                                        /> */}
                            {selectedFile ? (
                              <img
                                src={URL.createObjectURL(selectedFile)}
                                alt="Selected File"
                              />
                            ) : (
                              <GetProfileImage
                                id={empDetailsInfo?.id}
                                type={"jobList"}
                              />
                            )}
                            <i
                              className="fas fa-pencil-alt site-button button-sm"
                              style={{
                                position: "absolute",
                                bottom: "0px",
                                left: "30px",
                              }}
                              onClick={handleUploadButtonClick}
                            ></i>
                            {selectedFile ? (
                              <i
                                className="fas fa-check site-button button-sm"
                                style={{
                                  position: "absolute",
                                  bottom: "0px",
                                  left: "30px",
                                  zIndex: "999",
                                }}
                                onClick={handleUpload}
                                disabled={!selectedFile}
                              >
                                {/* {isLoading ? <Spinner /> : "Save"} */}
                              </i>
                            ) : (
                              ""
                            )}
                            {/* <GetProfileImage  id={null} type={'applicant'}/>     */}
                            <div className="upload-btn-wrapper">
                              {/* Hidden file input element */}
                              <div id="upload-image-grid" />
                              <input
                                type="file"
                                id="fileInput"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                              />
                              {/* Button to trigger file input click */}
                              {/* <button
                                                                className="site-button button-sm"
                                                                onClick={handleUploadButtonClick}
                                                            >
                                                                Upload Image
                                                            </button> */}
                            </div>
                            {/* <button
                                                            className="site-button button-sm"
                                                            style={{ top: "30px", left: "40%" }}
                                                            onClick={handleUpload}
                                                            disabled={!selectedFile}
                                                        >
                                                            {isLoading ? <Spinner /> : "Save"}
                                                        </button> */}
                          </div>
                          <div className="twm-job-self-bottom">
                            {/* <i className="fas fa-pencil-alt site-button button-sm" onClick={handleUploadButtonClick}></i> */}
                            {/* {selectedFile ? (<button
                                                            className="site-button"
                                                            onClick={handleUpload}
                                                            disabled={!selectedFile}
                                                        >
                                                            {isLoading ? <Spinner /> : "Save"}
                                                        </button>
                                                        ) : (
                                                            ""
                                                        )} */}
                            {/* <button
                                                                className="site-button"
                                                                role="button"
                                                                onClick={handleApplyJob}
                                                            >
                                                                {isLoadingApplyJob ? <Spinner /> : " Apply Now"}
                                                            </button>
                                                        ) : (
                                                            <a
                                                                className="site-button"
                                                                data-bs-toggle="modal"
                                                                href={"#sign_up_popup2"}
                                                                role="button"
                                                            >
                                                                Apply Now
                                                            </a> */}
                          </div>

                          <h4 className="twm-job-title">
                            {empDetailsInfo?.name}
                            <span className="twm-job-post-duration">
                              {/* / {getTimeDifference(jobDetailsInfo?.createdDate)} */}
                            </span>
                          </h4>
                          <p className="twm-job-address">
                            <i className="feather-map-pin" />
                            {formatAddress(
                            empDetailsInfo?.address,
                            empDetailsInfo?.city,
                            empDetailsInfo?.state,
                            empDetailsInfo?.zipCode
                          )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN")) && (
                    <div className="tab-content" id="myTab2Content">
                      {(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN"))  && (
                      <div
                        className="tab-pane fade  show active"
                        id="location-employer"
                      >
                        <div className="product-filter-wrap d-flex justify-content-between align-items-center">
                          <h4 className="twm-s-title">Location:</h4>
                          
                            <a
                            className="site-button"
                            data-bs-toggle="modal"
                            href={"#add_location_popup"}
                            role="button"
                          >
                            Add Location
                          </a>
                        </div>
                        {/* {empLocationInfo?.length > 0 && (
                                                    <> */}
                        <EmpLocationList
                          empLocationList={empLocationInfo}
                          empLocationLoader={locationLoader}
                          rowLocationPerPage={rowLocationPerPage}
                          handleRecordsLocationPerPageChange={
                            handleRecordsLocationPerPageChange
                          }
                          paginationLocationData={paginationLocationData}
                          locationCurrentPage={locationCurrentPage}
                          setLocationCurrentPage={setLocationCurrentPage}
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                          empDetailId={empDetailId}
                          empDetailsInfo={empDetailsInfo}
                        />
                        {/* </>
                                                )} */}
                      </div>
                      )}
                      <div className={`tab-pane fade ${role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") ? "show active" : ""}`} id="payment-employer">
                        <h4 className="twm-s-title">Payment:</h4>
                        {/* {empPaymentInfo?.length > 0 && ( */}
                        {/* <> */}
                        {/* <EmpPaymentList
                          empPaymentList={empPaymentInfo}
                          empPaymentLoader={paymentLoader}
                          rowPaymentPerPage={rowPaymentPerPage}
                          handleRecordsPaymentPerPageChange={
                            handleRecordsPaymentPerPageChange
                          }
                          paginationPaymentData={paginationPaymentData}
                          paymentCurrentPage={paymentCurrentPage}
                          setPaymentCurrentPage={setPaymentCurrentPage}
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                        /> */}
                        {empDetailsInfo?.id&&
                        <CanPaymentDetailsPage customerDetail={empDetailsInfo}/>
}
                        {/* {console.log("number of elements pagination", paginationPaymentData)}
                                                        {console.log("empPaymentList==========", empPaymentInfo)} */}
                        {/* </> */}
                        {/* )} */}
                      </div>
                      <div className="tab-pane fade" id="users-employer">
                        <div className="product-filter-wrap d-flex justify-content-between align-items-center">
                        <h4 className="twm-s-title">Users:</h4>
                        <a
                            className="site-button"
                            data-bs-toggle="modal"
                            href={"#add_user_popup"}
                            role="button"
                          >
                            Add User
                          </a>
                        </div>
                        {/* {empUsersInfo?.length > 0 && (
                                                    <> */}
                        <EmpUsersList
                          empUsersList={empUsersInfo}
                          empUsersLoader={usersLoader}
                          rowUsersPerPage={rowUsersPerPage}
                          handleRecordsUsersPerPageChange={
                            handleRecordsUsersPerPageChange
                          }
                          paginationUsersData={paginationUsersData}
                          usersCurrentPage={usersCurrentPage}
                          setUsersCurrentPage={setUsersCurrentPage}
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                        />
                        {/* </>
                                                )} */}
                      </div>

                      <div className="tab-pane fade" id="jobs-employer">
                        <h4 className="twm-s-title">Jobs:</h4>
                        {/* <EmpJobList
                          empUsersList={empUsersInfo}
                          empUsersLoader={usersLoader}
                          rowUsersPerPage={rowUsersPerPage}
                          handleRecordsUsersPerPageChange={
                            handleRecordsUsersPerPageChange
                          }
                          paginationUsersData={paginationUsersData}
                          usersCurrentPage={usersCurrentPage}
                          setUsersCurrentPage={setUsersCurrentPage}
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                        /> */}
                        {/* {empUsersInfo?.length > 0 && (
                        //                             <> */}
                      
                         <EmpJobList
                          empJobsList={empJobsInfo}
                          empJobsLoader={jobsLoader}
                          rowJobsPerPage={rowJobsPerPage}
                          handleRecordsJobsPerPageChange={
                            handleRecordsJobsPerPageChange
                          }
                          paginationJobsData={paginationJobsData}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                        />
                      </div>
                    </div>
                  )}
                  <SectionShareProfile />
                  {/* <SectionJobLocation jobDetailsInfo={empDetailsInfo} /> */}
                  {/* <div className="twm-two-part-section">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12">
                                                <SectionOfficePhotos1 />
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <SectionOfficeVideo1 />
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
              {!usersLoader&&
              <div className="col-lg-4 col-md-12 rightSidebar">
                <SectionJobsSidebar2
                  _config={sidebarConfig}
                  jobDetailsInfo={empDetailsInfo}
                  setErrSuccessMsg={setErrSuccessMsg}
                  setActiveTab={setActiveTab}
                  empUsersInfo={empUsersInfo}
                  usersLoader={usersLoader}
                  empDetailsInfo={empDetailsInfo}
                  getEmpDetailsData={getEmpDetailsData}
                />
              </div>
             }
            </div>
          </div>
        </div>
      </div>
      <AddLocation
        empDetails={empDetailsInfo}
        setLocationCurrentPage={setLocationCurrentPage}
        refreshedLocationList={refreshedLocationList}
      />
      <AddUser institutionId={empDetailsInfo?.id} />
      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default EmployersDetail1Page;
