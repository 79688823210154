import React, { useEffect, useState } from "react";
import TableLoader from "../../../../common/tableLoader";
import SectionPagination from "../../sections/common/section-pagination";
import SectionRecordsFilter from "../../sections/common/section-records-filter";
import SendNotification from "./sendNotificationPopup";

const NotificationTable = ({
  notificationList,
  notificationLoader,
  appliedApplicantRowPerPage,
  handleNotificationListRecordsPerPageChange,
  notificationListPaginationData,
  notificationListCurrentPage,
  setNotificationListCurrentPage,
  tableFilter,
  setTableFilter,
}) => {
  const [currentPage, setCurrentPage] = useState(notificationListCurrentPage);
  const [applicantId, setApplicantId] = useState("");
  useEffect(() => {
    setNotificationListCurrentPage(currentPage);
  }, [currentPage]);
  const _filterConfig = {
    prefix: "Showing",
    type: "results",
    isSorting: false,
    total: notificationListPaginationData?.totalElements,
    showRange: false,
    showingUpto: "",
  };
  return (
    <div className="twm-right-section-panel candidate-save-job site-bg-gray">
      <SectionRecordsFilter
        _config={_filterConfig}
        rowPerPage={appliedApplicantRowPerPage}
        handleRecordsPerPageChange={handleNotificationListRecordsPerPageChange}
        setTableFilter={setTableFilter}
        tableFilter={tableFilter}
      />
      <div className="twm-D_table table-responsive">
        <table
          // id="jobs_bookmark_table"
          className="table table-bordered twm-candidate-save-job-list-wrap"
        >
          <thead>
            <tr>
              <th>Applicant Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!notificationLoader ? (
              notificationList?.length > 0 ? (
                notificationList?.map((val, index) => (
                  <tr key={index}>
                    <td>
                      <div className="twm-candidate-save-job-list">
                        <div className="twm-mid-content">
                          <span className="twm-job-title">
                            <h4>{`${val?.userEntity?.firstName} ${val?.userEntity?.lastName}`}</h4>
                          </span>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div>
                        <button
                          type="button"
                          data-bs-toggle="modal"
                          href="#send_notification"
                          className="site-button"
                          onClick={() => setApplicantId(val?.userEntity?.id)}
                        >
                          Send Notification
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No results found.</td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan="5">
                  <TableLoader />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SectionPagination
        currentPage={notificationListCurrentPage}
        setCurrentPage={setCurrentPage}
        totalPages={notificationListPaginationData?.totalPages}
      />
      <SendNotification applicantId={applicantId} />
    </div>
  );
};

export default NotificationTable;
