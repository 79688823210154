import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SectionPagination from "../../public-user/sections/common/section-pagination";
import GetProfileImage from "../../../common/getProfileImage";
import TableLoader from "../../../common/tableLoader";

function CanMyTeamList({
  isLoading,
  myTeamList,
  currentPage,
  setCurrentPage,
  totalPages,
}) {
  console.log("myTeamList", myTeamList);

  const StarRating = ({ rating }) => {
    const stars = [];
    console.log("rating----------", rating);
    // Iterate over 5 stars
    for (let i = 0; i < 5; i++) {
      if (rating >= i + 1) {
        // Full star if rating is greater than or equal to current index + 1
        stars.push(
          <i
            key={i}
            className="fas fa-star"
            style={{ color: '#128c5e' }}
          />
        );
      } else if (rating >= i + 0.5) {
        // Half star if rating is greater than or equal to current index + 0.5
        stars.push(
          <i
            key={i + 0.5}
            className="fas fa-star-half-alt"
            style={{ color: '#128c5e' }}
          />
        );
      } else {
        // Empty star otherwise
        stars.push(
          <i
            key={i}
            className="far fa-star"
            style={{ color: 'lightgrey' }}
          />
        );
      }
    }
  
    return <span>{stars}</span>;
  };

  return (
    <>
      <div className="twm-jobs-list-wrap">
        <ul>
          {!isLoading ? (
            myTeamList?.length > 0 ? (
              myTeamList?.map((item) => (
                <li key={item.id} style={{ "marginTop" : "7rem" }}>
                  <div className="twm-jobs-list-style1 mb-5">
                    <div className="twm-media">
                      <GetProfileImage id={item?.id} type={"jobList"} />
                    </div>
                    <div className="twm-mid-content">
                      <NavLink to="#" className="twm-job-title">
                        <h4>
                          {`${item?.name}`}
                        </h4>
                      </NavLink>
                      <div>
                          <StarRating rating={item?.averageNumStars} />{" "}
                          <span style={{ fontSize: 12 }}>
                            {(item?.numberOfReview || 0) + " Reviews"}
                          </span>
                        </div>
                    </div>

                    <div className="twm-right-content">

                    </div>
                  </div>
                </li>
              ))
            ) : (
              <p className="mt-3" >You are not currently a member of any teams. When a company adds you to their team, they will appear here.</p>
            )
          ) : (
            <TableLoader />
          )}
        </ul>
      </div>
      {myTeamList?.length > 0 && (<SectionPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
      )}
    </>
  );
}

export default CanMyTeamList;
