import { useEffect } from "react";
import JobZImage from "./jobz-img";
import { useDispatch } from "react-redux";
import { userDetails, roleType, logout, setUserLogo } from "../../store/reducers/menu";
import { signOut } from "firebase/auth";
import { auth } from "./firebase/firebase";

function SessionExpire() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout(null));
    dispatch(userDetails(null));
    dispatch(roleType([]));
    dispatch(setUserLogo(null));
    signOut(auth);
  }, []);

  return (
    <>
      <div className="section-full p-t120  p-b90 site-bg-white">
        <div className="container">
          <div className="twm-error-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="twm-error-image">
                  <JobZImage src="images/error-404.png" alt="#" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="twm-error-content">
                  <h5 className="twm-error-title" style={{ fontSize: "50px" }}>
                    Session Expired!
                  </h5>
                  <h4 className="twm-error-title2 site-text-primary">
                    We Are Sorry, your session expires.
                  </h4>
                  <p>
                    Your session is expired. Please log back in to continue
                    accessing the platform.
                  </p>

                  <a
                    className="twm-nav-post-a-job site-button"
                    data-bs-toggle="modal"
                    href="#sign_up_popup2"
                    role="button"
                  >
                    <i className="feather-log-in" /> Sign In
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SessionExpire;
