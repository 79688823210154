import React, { useState } from "react";
import apiCall from "../../../apicall";
import Toast from "../toast";
import Spinner from "../spinner";
import { formatCompanyName } from "../../../globals/fomat-comapny-name";
import { useSelector } from "react-redux";

function Footer1() {
  const { isLoggedIn } = useSelector((state) => state.menu)
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: false,
  });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubscribe = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!validateEmail(email)) {
      setErrSuccessMsg({
        message: "Please enter a valid email address!",
        status: false,
      });
      setIsLoading(false);
      return;
    }

    try {
      const subscribeResponse = await apiCall("config/subscribe", "POST", {
        email: email,
        endDate: new Date(),
        startDate: new Date(),
        subscribe: true,
      });
      if (subscribeResponse?.status === 200) {
        setIsLoading(false);
        setErrSuccessMsg({
          message: "User subscribed successfully!",
          status: true,
        });
        setEmail("")
      } else {
        setIsLoading(false);
        setErrSuccessMsg({
          message: subscribeResponse?.response?.data?.message,
          status: false,
        });
      }
    } catch (error) {
      console.error("API error:", error);
      setIsLoading(false);

      setErrSuccessMsg({
        message: "Something went wrong!",
        status: true,
      });
    }
  };
  return (
    <>
      <footer
        className="footer-light" 
        style={ isLoggedIn ? { paddingTop : '0px'} : { paddingTop : '80px'}}
      >
        <div className="container">
          {/* NEWS LETTER SECTION START */}
          {
            !isLoggedIn && 
            <div className="ftr-nw-content" style={{ marginBottom : "0px", borderBottom : "1px solid #242323", paddingBottom : "50px" }}>
            <div className="row">
              <div className="col-md-5">
                <div className="ftr-nw-title">
                  Join our email subscription now to get updates on new jobs and
                  notifications.
                </div>
              </div>
              <div className="col-md-7">
                <form>
                  <div className="ftr-nw-form">
                    <input
                      name="news-letter"
                      className="form-control"
                      placeholder="Enter Your Email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button
                      className="ftr-nw-subcribe-btn"
                      onClick={handleSubscribe}
                    >
                      {isLoading ? <Spinner /> : "Subscribe Now"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          }
          
          {/* FOOTER COPYRIGHT */}
          <div className="footer-bottom" style={{ borderTop : "none" }} >
            <div className="footer-bottom-info">
              <div className="footer-copy-right">
                <span className="copyrights-text">
                  Copyright © 2024 {formatCompanyName()}. All Rights Reserved.
                </span>
                <a
                    href={`#/privacy-policy`}
                    style={{ color: 'blue', textDecoration: 'none', fontWeight: 'bold' }}
                >privacy policy</a>
              </div>
            </div>
          </div>
        </div>
        {errSuccessMesg?.message !== "" ? (
          <Toast message={errSuccessMesg} />
        ) : (
          ""
        )}
      </footer>
    </>
  );
}

export default Footer1;
